import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  Button,
  MenuItem,
  MenuList
} from "@mui/material";
import ControlCheckboxGroup from "../../../Components/Checkbox/ControlCheckboxGroup";
// import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { ReactComponent as DetailCTA } from "../../../assets/images/job-detail-cta.svg";
import { ReactComponent as ActionIcon } from "../../../assets/images/action-cta-icon.svg";

export default function CandidateListActionButton(props) {
  let navigate = useNavigate();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [fromDropdown, setFromDropdown] = useState(false);
  const [selectedCandidateId, setSelectedCandidateId] =
    React.useState(undefined);
  const params = useParams();

  // Add candidate into multiple talent pool
  const handleOpenAddTalentPool = (candidate) => {
    const bodyElement = document.body;
    const htmlElement = document.documentElement;
    bodyElement.style.overflow = "";
    htmlElement.style.overflow = "";
    navigate(
      "/enterprise/leads-talent-pool-list/" +
        // (candidate.id ? candidate.id : selectedCandidateId) +
        (props.candidate_id ? props.candidate_id : candidate.id ? candidate.id : selectedCandidateId) +
        location.search,
      { state: { name: candidate.firstName + " " + candidate.lastName } }
    );
  };
  // CTA with shortlist and remove options
  const anchorRef = React.useRef(null);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  useEffect(() => {
    document.addEventListener("scroll", () => {
      setOpen(false);
    });
  });

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    if (fromDropdown) {
      setFromDropdown(false);
      return;
    }
    setOpen(false);
  };
  const handleDropdownClick = () => {
    setFromDropdown(true);
  };


  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  return (
    <>
      <Grid item>
        <Button
          ref={anchorRef}
          // id="composition-button"
          aria-controls={open ? "composition-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
          aria-label="Menu icon"
          disableRipple="true"
          className="cursor-hover d-flex justify-end"
        >
          {/* <DetailCTA
            className="experience-cont detail-cta-icon svg-icons"
            aria-label="Menu icon"
          /> */}
          <Typography className="secondary-bg-color border-radius-4 p-7-10 d-flex">
            <ActionIcon width="16px" height="16px" className="svg-icons"/>
          </Typography>
        </Button>

        <Popper
          style={{ zIndex: "9" }}
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-start"
          className="shortlist-modal-fix"
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom-start" ? "left top" : "left bottom",
              }}
            >
              <Paper>
                <ClickAwayListener
                  onClickAway={handleClose}
                  tabIndex={0}
                  onKeyPress={handleClose}
                >
                  <MenuList
                    autoFocusItem={open}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                    onKeyDown={handleListKeyDown}
                  >
                    {props.showEditCandidateDetails && localStorage.getItem("userType") != "S" &&
                    <MenuItem
                      onClick={(e) => {
                        setOpen(false);
                        props.goToEditCandidate(e, props.candidate);
                      }}
                      onKeyPress={(e) => {
                        setOpen(false);
                        props.goToEditCandidate(e, props.candidate);
                      }}
                    >
                      <Typography className="gray9 f-12 font-weight-400">
                        <span>Edit Candidate Details</span>
                      </Typography>
                    </MenuItem>}
                    <MenuItem className="pl-0 pr-0">
                      <Typography className="candidate-list-shortlist">
                        <ControlCheckboxGroup
                          title={"Jobs"}
                          buttonTitle={"Shortlist"}
                          submitButtonTitle={"Shortlist"}
                          // options={JobTitle}
                          // setOptions={setJobTitle}
                          // candidateId={props.candidate.id}
                          candidateId={props?.candidate_id ? props?.candidate_id : props.candidate.id}
                          className={"shortList"}
                          candidateInfo={props.candidate}
                          setOpen={setOpen}
                          setIsLoading={props.setIsLoading}
                          handleDropdownClick={handleDropdownClick}
                        />
                      </Typography>
                    </MenuItem>
                    <MenuItem onClick={() => handleOpenAddTalentPool(props.candidate)}>
                      <Grid
                        item
                        onClick={() => handleOpenAddTalentPool(props.candidate)}
                      >
                        <Typography className="gray9 f-12 font-weight-400 align-center">
                          Add to Talent Pool
                        </Typography>
                      </Grid>
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Grid>
    </>
  );
}
