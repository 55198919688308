import React, { useEffect, useState } from 'react';
import ChatWindow from './ChatWindow';
import MessageChatBox from './MessageChatBox';
import NewChatBox from './NewChatBox';
import EnterpriseCandidateDescriptionDrawer from '../Project/EnterpriseCandidateDescriptionDrawer';
import Loader from '../../Loader';

const ChatContainer = () => {
    const [chats, setChats] = useState([]);
    const [openSmsChat, setOpenSmsChat] = useState(false);
    const [openNewMessageChat, setOpenNewMessageChat] = useState(false);
    const [isNewChatMinimized, setIsNewChatMinimized] = useState(false);
    const [closeNewChat, setCloseNewChat] = useState(false);
    const [candidateProfileDrawer, setCandidateProfileDrawer] = useState(false);
    const [candidateListId, setCandidateListId] = useState();
    const [candidateInfo, setCandidateInfo] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const openCandidateDetailDrawer = (candidateData, candidateInfo) => {
        try {
            document.getElementById("messaging-window-minimize-icon")?.click();
            setCloseNewChat(!closeNewChat);
            document.getElementById("chat-window-minimize-icon-0")?.click();
            document.getElementById("chat-window-minimize-icon-1")?.click();
            document.getElementById("chat-window-minimize-icon-2")?.click();
        } catch (err) {}
        const drawerElements = document.getElementsByClassName('MuiDrawer-root');
        if (drawerElements.length > 0) {
            const drawer = drawerElements[drawerElements.length - 1];
            const closeButton = drawer.querySelector('svg[data-testid="ChevronLeftIcon"]')?.parentElement;
            const drawerCloseIcon = document.getElementById("drawer-close-icon");
            if (closeButton?.click) {
                closeButton.click();
            } else if (drawerCloseIcon?.click) {
                drawerCloseIcon.click();
            }
        }
        setCandidateListId(null);
        setCandidateInfo(candidateInfo);
        setCandidateProfileDrawer(true);
        setTimeout(()=>{
            setCandidateListId(candidateData);
        }, 50);
    };

    useEffect(()=>{
        let _chats = localStorage.getItem("SMS_CHATS");
        if (_chats) {
            setChats(JSON.parse(_chats));
        }
        const handleStorageUpdate = () => {
            let _chats = localStorage.getItem("SMS_CHATS");
            if (_chats) {
                if (openNewMessageChat && (JSON.parse(_chats)?.length === 3)) {
                    setChats(JSON.parse(_chats).slice(0, 2));
                    let sms_chats = localStorage.getItem("SMS_CHATS");
                    if (sms_chats) {
                        sms_chats = JSON.parse(sms_chats);
                        sms_chats?.splice(2, 1);
                        localStorage.setItem("SMS_CHATS", JSON.stringify(sms_chats));
                    }
                } else {
                    setChats(JSON.parse(_chats));
                }
            } else {
                setChats([]);
            }
            setOpenSmsChat(localStorage.getItem("OPEN_SMS_CHAT") ? true : false);
            if (localStorage.getItem("OPEN_SMS_CHAT")) {
                setCloseNewChat(!closeNewChat);
            }
        };
        const intervalId = setInterval(handleStorageUpdate, 1000); // Check every second
        // Clean up the interval on component unmount
        return () => clearInterval(intervalId);
    }, [openNewMessageChat, closeNewChat]);

    useEffect(()=>{
        if (openNewMessageChat && chats?.length === 3) {
            let _chats = [...chats];
            setChats(_chats.slice(0, 2));
            let sms_chats = localStorage.getItem("SMS_CHATS");
            if (sms_chats) {
                sms_chats = JSON.parse(sms_chats);
                sms_chats?.splice(2, 1);
                localStorage.setItem("SMS_CHATS", JSON.stringify(sms_chats));
            }
        }
    }, [openNewMessageChat]);

    const handleMinimizeNewChat = () => {
        setIsNewChatMinimized(!isNewChatMinimized);
    };

    return (
        <>
        {isLoading && <Loader/>}
        <div className="chat-container" onClick={(e)=>e.stopPropagation()}>
            {chats.map((candidate, index) => (
                <ChatWindow key={candidate?.id} index={index} isLastChatWindow={index === 0} openSmsChat={openSmsChat} candidate={candidate}
                    openCandidateDetailDrawer={openCandidateDetailDrawer}
                />
            ))}
            {(openNewMessageChat || localStorage.getItem("isNewMessageOpen")) &&
                <NewChatBox
                    isNewChatMinimized={isNewChatMinimized}
                    closeNewChat={closeNewChat}
                    setOpenNewMessageChat={setOpenNewMessageChat}
                />
            }
            <MessageChatBox
                setOpenNewMessageChat={setOpenNewMessageChat}
                handleMinimizeNewChat={handleMinimizeNewChat}
            />
        </div>
        {candidateListId && (
          <EnterpriseCandidateDescriptionDrawer
            candidateProfileDrawer={candidateProfileDrawer}
            setCandidateProfileDrawer={setCandidateProfileDrawer}
            candidate_id={candidateListId}
            setCandidateListId={setCandidateListId}
            setIsLoading={setIsLoading}
            candidate={candidateInfo}
            searchDate={""}
          />
        )}
        </>
    );
};

export default ChatContainer;
