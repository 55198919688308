// import PlacesAutocomplete, { geocodeByAddress, getLatLng, geocodeByPlaceId }  from "react-places-autocomplete";
import React from "react";
import { Grid, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { danger } from "../../../../helpers/message-box";
import ApiCalls from "../../../../api/customAxios";
import _ from "lodash";
const Constant = require("../../../../helpers/constants");

class LocationSearchInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = { city: props.city ? props.city : "",
    isDirty: false,
    openLocationsAutoComplete: false,
    locationsAutoFill: [],
    clear: false,
    flag: true,
  };
  this.debouncedHandler = _.debounce(this.handleDebounceInput.bind(this), Constant.DEBOUNCE_TIME);
    document.addEventListener("clearLocation", () => {
      if (this.props.city) {
        this.props.onSelectLocation({
          city: "",
          state: "",
          country: "",
        });
        this.setState({ city: "" });
      }
    });
  }
  handleDebounceInput = (e) => {
    this.getLocations(e.target.value);
  };
  componentDidUpdate(prevProps, prevState) {
    if (prevState.city !== this.props.city && this.state.flag) {
      // alert("test");
      this.setState({ city: this.props.city });
    }
  }
  handleChange = (city) => {
    if (city === "") {
      this.props.onSelectLocation({
        city: "",
        state: "",
        country: "",
      });
    }
    this.setState({ city });
    this.setState({ isDirty: true });
  };

  // handleSelect = (location) => {
  //   const locationList = location.split(",");
  //   let locationDetails;
  //   if (locationList.length > 2) {
  //     locationDetails = {
  //       city: locationList[0]?.trim(),
  //       state: locationList[1]?.trim(),
  //       country: locationList[2]?.trim(),
  //     };
  //   } else {
  //     locationDetails = {
  //       city: locationList[0]?.trim(),
  //       state: locationList[0]?.trim(),
  //       country: locationList[1]?.trim(),
  //     };
  //   }
  //   this.props.onSelectLocation(locationDetails);
  //   this.setState({ city: locationList[0].trim() });
  //   this.setState({ isDirty: false });
  // };

  setLocationCity = (location) => {
    let totalAddress = [];
    let adr = location && location["address"];
    let locationDetails = {
      city: "",
      state: "",
      country: "",
      zipCode: "",
  };
  for (let i = 0; i < adr.length; i++) {
      if (adr[i].types.includes("LOCALITY") || adr[i].types.includes("POSTAL_TOWN")) {
          locationDetails["city"] = adr[i].shortName.toString();
      } else if ( adr[i].types[0] === "ADMINISTRATIVE_AREA_LEVEL_1" ) {
          locationDetails["state"] = adr[i].shortName.toString();
      } else if ( adr[i].types[0] === "COUNTRY" ) {
          locationDetails["country"] = adr[i].shortName.toString();
      } else if ( adr[i].types[0] == "POSTAL_CODE") {
          locationDetails["zipCode"] = adr[i].shortName.toString();
      }
  }
  this.props.onSelectLocation(locationDetails);
  this.setState({ city: locationDetails.city });
  this.setState({ flag: true });
  this.setState({ isDirty: false });
  };

  getLocations = async (event) => {
    this.setState( { flag: false } );
    if ( event == "") {
      this.setState( { flag: true } );
    }
    let Url;
    if (this.props?.isAuthenticated) {
      Url = process.env.REACT_APP_API_URL.replace("api/v1/", "public/api/v1/") + "places/";
    } else {
      Url = process.env.REACT_APP_API_URL + "places/";
    }
    this.handleChange(event);
    if (!event) {
        this.setState({ locationsAutoFill: [] });
    } else if (event.length >= 1) {
        await ApiCalls.get(Url + event + (this.props.stateCheck ? "?type=R" : ""))
        .then((res) => {
          this.setState({ locationsAutoFill: res });
        }).catch(function (error) {

        });
    }
};
  handleLatLong = (data) => {
    this.props.handleLatLong(data);
  }
  getAddressDetails = (address, placeId) => {
    let Url;
    if (this.props?.isAuthenticated) {
      Url = process.env.REACT_APP_API_URL.replace("api/v1/", "public/api/v1/") + "places/";
    } else {
      Url = process.env.REACT_APP_API_URL + "places/";
    }
    let totalAddress = {};
    if (address != undefined) {
      ApiCalls.get(Url + address + "?type=D")
        .then((results) => {
            totalAddress["address"] = results[0].addressComponents;
            totalAddress["latlng"] = results[0].geometry.location;
            totalAddress["placeId"] = placeId;
            this.handleLatLong(results[0].geometry.location);
            this.setLocationCity(totalAddress);
        })
        .catch((error) => {
          if (error?.statusCode != 500) {
            danger(<div>{error?.errorMessage}</div>);
          }
          this.setState({ city: "" });
        });
    }
  }
  render() {
    return (
      <>
      <Grid item lg={12} md={12} xs={12} className="city-placeholder">
      <Autocomplete
      open={this.state.openLocationsAutoComplete}
      onClose={() =>{
        this.setState({ openLocationsAutoComplete: false });
        this.setState( { flag: true } );
        }}
      key={this.props.addLocation ? this.state.clear : ""}
      id="free-solo-demo"
      options={this.state.locationsAutoFill}
      popupIcon={""}
      className="test"
      noOptionsText={<span className="gray9 f-14 font-weight-400">Please select from the list</span>}
      getOptionLabel={(option) => option.description ? option.description : this.state.city}
      value={this.state.city ? this.state.city : null}
      isOptionEqualToValue={(option, value) => option.description[0].trim() === value}
      onInputChange={(_, value) => {
          if (value.length === 0) {
              if (this.state.openLocationsAutoComplete) this.setState({ openLocationsAutoComplete: false });
          } else {
              if (!this.state.openLocationsAutoComplete) this.setState({ openLocationsAutoComplete: true });
          }
          if (value == this.state.city) {
            this.setState({ openLocationsAutoComplete: false });
          }
      }}
      clearIcon={<div style={{ cursor: "auto" }}></div>}
      renderInput={(params) => (
          <TextField {...params}
              type="text"
              className="form-control input-group inputcontrasts position-issue inputcontrasts edit-skill-align m-t-0 mb-0 location-search-input citySection basicCityAlign basic-info-city"
              margin="normal"
              placeholder={this.props.placeholder ? this.props.placeholder : "Enter location"}
              onChange={this.debouncedHandler} />
      )}
      onChange={(event, address) => this.getAddressDetails(address?.description, address?.placeId)}
      ListboxProps={{ style: { maxHeight: '10rem' } }}
  /></Grid>
  </>
    );
  }
}

export default LocationSearchInput;
