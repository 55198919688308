import { Box, Grid, Link, Typography, useMediaQuery, useTheme } from "@mui/material";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import cellIcon from "../../../assets/images/cellIcon.svg";
import TooltipShared from "../../../Components/shared/TooltipShared";
import { PreferredCommunicationTooltip } from "../../../helpers/helper-data";
import * as Constants from "../../../helpers/constants";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const ContactInfo = ({ candidate, handleSendSmsFromMessageScreenDrawer, handleCloseDrawer }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  let navigate = useNavigate();
  const [mobileNumber, setMobileNumber] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [alternateEmailAddress, setAlternatEmailAddress] = useState("");
  const [alternateMobileNumber, setAlternateMobileNumber] = useState("");
  const [hidePhoneEmail, setHidePhoneEmail] = useState("");
  const [mobileNumberAccess, setMobileNumberAccess] = useState("P");
  const [emailAddressAccess, setEmailAddressAccess] = useState("P");
  useEffect(() => {
    const convertAccess = (value) => {
      if (value === "PC") return "P";
      if (["AC", "AE"].includes(value)) return "A";
      return value;
    };
    setMobileNumber(candidate?.mobileNumber);
    setEmailAddress(candidate.emailAddress);
    setAlternateMobileNumber(
      candidate.enterpriseAlternateMobileNumber != null
        ? candidate.enterpriseAlternateMobileNumber
        : candidate.alternateMobileNumber
    );
    setAlternatEmailAddress(
      candidate.enterpriseAlternateEmailAddress != null
        ? candidate.enterpriseAlternateEmailAddress
        : candidate.alternateEmailAddress
    );
    setHidePhoneEmail(
      candidate.enterpriseHidePhoneEmail || candidate.hidePhoneEmail
    );
    setMobileNumberAccess(
      convertAccess(candidate.enterpriseMobileNumberForAccess) ||
        candidate.mobileNumberForAccess
    );
    setEmailAddressAccess(
      convertAccess(candidate.enterpriseEmailAddressForAccess) ||
        candidate.emailAddressForAccess
    );
  }, [candidate]);
  const handleOpenSMSChat = (mobileNumberSms) => {
    if (window.location.pathname.includes("/message-list")) {
      if (handleSendSmsFromMessageScreenDrawer) {
        handleSendSmsFromMessageScreenDrawer();
      }
      handleCloseDrawer();
      return;
    } else if (isMobile) {
      navigate("/enterprise/message-list?candidateId="+_chat?.id+"&candidateDetails="+window.btoa(JSON.stringify(_chat)));
      return;
    }
    let id = candidate?.id ? candidate?.id : candidate?.candidateId;
    let _chat = {
      id: id,
      firstName: candidate?.firstName,
      lastName: candidate?.lastName,
      mediaFile: candidate?.mediaFile,
      mediaFileSigned: candidate?.mediaFileSigned,
      mobileNumber: mobileNumberSms,
      profileTitle: candidate?.profileTitle,
      isCheckSendSMS: true,
    };
    let chats = localStorage.getItem("SMS_CHATS");
    if (chats) {
      chats = JSON.parse(chats);
      chats = chats?.filter((chat) => chat.id !== id);
      if (chats?.length === 3) {
        chats = chats?.slice(0, 2);
      }
      chats = [_chat, ...chats];
      localStorage.setItem("SMS_CHATS", JSON.stringify(chats));
      localStorage.setItem("OPEN_SMS_CHAT", true);
    } else {
      localStorage.setItem("SMS_CHATS", JSON.stringify([_chat]));
      localStorage.setItem("OPEN_SMS_CHAT", true);
    }
  };
  return (
    <>
      {hidePhoneEmail === "B" ? (
        <Grid item className="bg-white p-20" xs={12} lg={12}>
          <Typography
            variant="h5"
            className="font-weight-600 sub-text-font gray9 border-bottom-1 pb-10"
          >
            Contact Details
          </Typography>
          <Grid item className="pt-10">
            <Typography className="d-flex align-center responsive-block galaxy-block">
              <Typography className="d-flex align-center mt-5 gray9 f-14">
                Candidate's contact details are hidden.
              </Typography>
            </Typography>
          </Grid>
        </Grid>
      ) : (
        <>
          {!(
            (mobileNumber === "" || mobileNumber === null) &&
            (emailAddress === "" || emailAddress === null)
          ) && (
            <Grid item className="bg-white p-20" xs={12} lg={12}>
              <Typography
                variant="h5"
                className="font-weight-600 sub-text-font gray9 border-bottom-1 pb-10"
              >
                Contact Details
              </Typography>
              <Grid
                container
                lg={12}
                md={12}
                xs={12}
                columnSpacing={1}
                rowSpacing={2}
                className="pt-10"
              >
                <Grid item lg={6} md={6} xs={12}>
                  <Typography className="responsive-block galaxy-block">
                    <Box className="gray7 f-14 font-weight-400 m-b-5">
                      Email Address
                    </Box>
                    {hidePhoneEmail !== "E" ? (
                      <>
                        <Typography className="d-flex align-center">
                          <TooltipShared
                            title={
                              candidate.firstName === "****" &&
                              candidate.lastName === "****"
                                ? Constants.GDPR_TOOLTIP
                                : ""
                            }
                            className="d-inline-flex align-center"
                          >
                            <span className="d-flex align-center">
                              {emailAddress ? (
                                <>
                                  <MailOutlineIcon
                                    className="f-14 gray7"
                                    aria-label="Email icon"
                                  />
                                  {candidate.firstName === "****" &&
                                  candidate.lastName === "****" ? (
                                    <span className="f-14 gray9 pl-10 pt-4">
                                      ****
                                    </span>
                                  ) : (
                                    <span className="f-14 gray9 pl-10">
                                      {emailAddress}
                                    </span>
                                  )}
                                </>
                              ) : (
                                <span className="f-14 gray9">-</span>
                              )}
                            </span>
                          </TooltipShared>
                          {(!emailAddressAccess ||
                            emailAddressAccess === "P") && (
                            <PreferredCommunicationTooltip>
                              <Typography className="preferred-communication-label f-14 font-weight-500 ml-10 d-inline-flex">
                                Preferred
                              </Typography>
                            </PreferredCommunicationTooltip>
                          )}
                        </Typography>
                        <Typography className="d-flex mt-2 view-response">
                          {emailAddress &&
                            (!emailAddressAccess ||
                              emailAddressAccess === "P") && (
                              <Link
                                className="link-color small-text-font pr-10 text-decoration-none"
                                onClick={(e) => {
                                  e.preventDefault();
                                  window.location.href = `mailto:${emailAddress}`;
                                }}
                              >
                                Send Email
                              </Link>
                            )}
                        </Typography>
                      </>
                    ) : (
                      <Typography className="f-14 gray9">
                        Candidate's email address is hidden
                      </Typography>
                    )}
                  </Typography>
                </Grid>
                <Grid item lg={6} md={6} xs={12}>
                  <Typography className="galaxy-block responsive-block">
                    <Box className="gray7 f-14 font-weight-400 m-b-5">
                      Phone Number
                    </Box>
                    {hidePhoneEmail !== "P" ? (
                      <>
                        <TooltipShared
                          title={
                            candidate.firstName === "****" &&
                            candidate.city !== null &&
                            candidate.city !== "" &&
                            candidate.city === "****"
                              ? Constants.GDPR_TOOLTIP
                              : ""
                          }
                        >
                          {mobileNumber ? (
                            <>
                              <img className="f-14" src={cellIcon} alt="img" />
                              <span className="f-14 gray9 pl-10">
                                {mobileNumber}
                              </span>
                            </>
                          ) : (
                            <span className="f-14 gray9">-</span>
                          )}
                        </TooltipShared>
                        {mobileNumber &&
                          (!mobileNumberAccess ||
                            mobileNumberAccess === "P") && (
                            <PreferredCommunicationTooltip>
                              <Typography className="preferred-communication-label f-14 font-weight-500 ml-10 d-inline-flex">
                                Preferred
                              </Typography>
                            </PreferredCommunicationTooltip>
                          )}
                        <Typography className="d-flex view-response galaxy-margin-left-0 mt-2">
                          {mobileNumber &&
                            (!mobileNumberAccess ||
                              mobileNumberAccess === "P") && (
                              <Link
                                className="link-color small-text-font pr-10 text-decoration-none "
                                // href={`mailto:${emailAddress}`}
                                onClick={(e) => handleOpenSMSChat(mobileNumber)}
                                onKeyPress={(e) =>
                                  handleOpenSMSChat(mobileNumber)
                                }
                              >
                                Send SMS
                              </Link>
                            )}
                        </Typography>
                      </>
                    ) : (
                      <Typography className="f-14 gray9">
                        Candidate's phone number is hidden
                      </Typography>
                    )}
                  </Typography>
                </Grid>
                <Grid item lg={6} md={6} xs={12}>
                  <Typography className="responsive-block galaxy-block">
                    <Box className="gray7 f-14 font-weight-400 m-b-5">
                      Alternate Email Address
                    </Box>
                    {hidePhoneEmail !== "E" ? (
                      <>
                        <Typography className="d-flex align-center">
                          <TooltipShared
                            title={
                              candidate.firstName === "****" &&
                              candidate.lastName === "****"
                                ? Constants.GDPR_TOOLTIP
                                : ""
                            }
                            className="d-inline-flex align-center"
                          >
                            <span className="d-flex align-center">
                              {alternateEmailAddress ? (
                                <>
                                  <MailOutlineIcon
                                    className="f-14 gray7"
                                    aria-label="Email icon"
                                  />
                                  {candidate.firstName === "****" &&
                                  candidate.lastName === "****" ? (
                                    <span className="f-14 gray9 pl-10 pt-4">
                                      ****
                                    </span>
                                  ) : (
                                    <span className="f-14 gray9 pl-10">
                                      {alternateEmailAddress}
                                    </span>
                                  )}
                                </>
                              ) : (
                                <span className="f-14 gray9">-</span>
                              )}
                            </span>
                          </TooltipShared>
                          {emailAddressAccess === "A" && (
                            <PreferredCommunicationTooltip>
                              <Typography className="preferred-communication-label f-14 font-weight-500 ml-10 d-inline-flex">
                                Preferred
                              </Typography>
                            </PreferredCommunicationTooltip>
                          )}
                        </Typography>
                        <Typography className="d-flex mt-2 view-response">
                          {alternateEmailAddress && emailAddressAccess === "A" && (
                            <Link
                              className="link-color small-text-font pr-10 text-decoration-none"
                              onClick={(e) => {
                                e.preventDefault();
                                window.location.href = `mailto:${alternateEmailAddress}`;
                              }}
                            >
                              Send Email
                            </Link>
                          )}
                        </Typography>
                      </>
                    ) : (
                      <Typography className="f-14 gray9">
                        Candidate's alternate email address is hidden
                      </Typography>
                    )}
                  </Typography>
                </Grid>
                <Grid item lg={6} md={6} xs={12}>
                  <Typography className="galaxy-block responsive-block">
                    <Box className="gray7 f-14 font-weight-400 m-b-5">
                      Alternate Phone Number
                    </Box>
                    {hidePhoneEmail !== "P" ? (
                      <>
                        <TooltipShared
                          title={
                            candidate.firstName === "****" &&
                            candidate.city !== null &&
                            candidate.city !== "" &&
                            candidate.city === "****"
                              ? Constants.GDPR_TOOLTIP
                              : ""
                          }
                        >
                          {alternateMobileNumber ? (
                            <>
                              <img className="f-14" src={cellIcon} alt="img" />
                              <span className="f-14 gray9 pl-10">
                                {alternateMobileNumber}
                              </span>
                            </>
                          ) : (
                            <span className="f-14 gray9">-</span>
                          )}
                        </TooltipShared>
                        {mobileNumberAccess === "A" && (
                          <PreferredCommunicationTooltip>
                            <Typography className="preferred-communication-label f-14 font-weight-500 ml-10 d-inline-flex">
                              Preferred
                            </Typography>
                          </PreferredCommunicationTooltip>
                        )}
                        <Typography className="d-flex view-response galaxy-margin-left-0 mt-2">
                          {alternateMobileNumber && mobileNumberAccess === "A" && (
                            <Link
                              className="link-color small-text-font pr-10 text-decoration-none "
                              // href={`mailto:${emailAddress}`}
                              onClick={(e) =>
                                handleOpenSMSChat(alternateMobileNumber)
                              }
                              onKeyPress={(e) =>
                                handleOpenSMSChat(alternateMobileNumber)
                              }
                            >
                              Send SMS
                            </Link>
                          )}
                        </Typography>
                      </>
                    ) : (
                      <Typography className="f-14 gray9">
                        Candidate's alternate phone number is hidden
                      </Typography>
                    )}
                  </Typography>
                </Grid>
                <Typography>
                  {(mobileNumber === "" || mobileNumber === null) &&
                    (emailAddress === "" || emailAddress === null) && (
                      <Typography className="f-14 gray9">
                        No contact info found.
                      </Typography>
                    )}
                </Typography>
              </Grid>
            </Grid>
          )}
        </>
      )}
    </>
  );
};

export default ContactInfo;
