import React from "react";
import { Typography, Button, Modal, Box } from "@mui/material";
function GdprRestrictDownloadModal({ openGDPRDownloadRestrictPopup, setOpenGDPRDownloadRestrictPopup }) {
    return (
        <Modal
            open={openGDPRDownloadRestrictPopup}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box className="modalStyle inputcontrasts outline-none fit-content">
                <Typography className="f-24 font-weight-600 heading-text-color align-left">
                    Download Unavailable
                </Typography>
                <Typography className="mt-15 f-14 gray9 d-flex align-start">
                    <Box className="gray9 font-weight-400 align-left">
                        You cannot download due to GDPR regulations.
                    </Box>
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 3 }}>
                    <Button
                        type="button"
                        onClick={() => setOpenGDPRDownloadRestrictPopup(false)}
                        className="btn primary-button w-150"
                        variant="contained"
                        disableRipple="true"
                    >
                        OK
                    </Button>
                </Typography>
            </Box>
        </Modal>
    );
}
export default GdprRestrictDownloadModal;
