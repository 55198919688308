// import PlacesAutocomplete, { geocodeByAddress, getLatLng, geocodeByPlaceId }  from "react-places-autocomplete";
import React from "react";
import { Grid, TextField, Box } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { success, danger } from "../helpers/message-box";
import ApiCalls from "../api/customAxios";

class ZipcodeSearchInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            zipCode: this.props.zipCode ? this.props.zipCode : "",
            isDirty: false,
            openLocationsAutoComplete: false,
            locationsAutoFill: [],
            clear: false,
            flag: true,
        };
        document.addEventListener("clearLocation", () => {
            if (this.props.zipCode) {
                this.props.handleZipCodeSelect({               //here
                    zipCode: "",
                    // city: "",
                    // state: "",
                    // country: "",
                });
                this.setState({ zipCode: "" });
            }
        });
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevState.zipCode !== this.props.zipCode && this.state.flag) {
            // alert("test");
            this.setState({ zipCode: this.props.zipCode });
        }
    }
    handleChange = (zipCode) => {
        if (zipCode === "") {
            this.props.handleZipCodeSelect({               //here
                zipCode: "",
                // city: "",
                // state: "",
                // country: "",
            });
        }
        this.setState({ zipCode });
        this.setState({ isDirty: true });
    };
    //-------------------------------------------------------chnage
    // handleSelect = (location) => {
    //     // console.log(location);
    //     const locationList = location.split(",");
    //     let locationDetails = {
    //         zipCode: "",
    //         city: "",
    //         state: "",
    //         country: "",
    //     };
    //     let holder = "";
    //     if (locationList.length) {
    //         locationDetails["country"] = locationList[locationList.length - 1];
    //         locationList.pop();
    //     }
    //     if (locationList.length) {
    //         locationDetails["state"] = locationList[locationList.length - 1];
    //         locationList.pop();
    //     }
    //     if (locationList.length) {
    //         locationDetails["city"] = locationList[locationList.length - 1];
    //         locationList.pop();
    //     }
    //     if (locationList.length) {
    //         locationList.forEach((i) => {
    //             holder += i + ",";
    //         });
    //         locationDetails["address"] = holder;
    //     }
    //     // console.log(locationDetails);
    //     if (locationDetails["country"]) {
    //         this.props.handleZipCodeSelect(locationDetails);
    //     };
    //     this.setState({ address: holder }); //make change add cond
    //     this.setState({ isDirty: false });
    // };

    setLocationAddress = (location) => {
        let adr = location && location["address"];
        let locationDetails = {
            zipCode: "",
            city: "",
            state: "",
            country: "",
        };
        for (let i = 0; i < adr.length; i++) {
            if (adr[i].types[0] === "LOCALITY" || adr[i].types[0] === "POSTAL_TOWN") {
                locationDetails["city"] = adr[i].shortName.toString();
            } else if (adr[i].types[0] === "ADMINISTRATIVE_AREA_LEVEL_1") {
                locationDetails["state"] = adr[i].shortName.toString();
            } else if (adr[i].types[0] === "COUNTRY") {
                locationDetails["country"] = adr[i].shortName.toString();
            } else if (adr[i].types[0] == "POSTAL_CODE") {
                locationDetails["zipCode"] = adr[i].shortName.toString();
            }
        }
        locationDetails["zipCode"] = locationDetails.zipCode && locationDetails.zipCode.length ? locationDetails.zipCode : this.state.zipCode;
        this.setState({ flag: true });
        this.setState({ zipCode: locationDetails.zipCode }); //make change add cond
        this.setState({ isDirty: false });
        this.props.handleZipCodeSelect(locationDetails);
    };

    nonAssisted = (event) => {
        event.target.value = event.target.value.trim();
        // this.setState( { flag: false });
        this.setState({ zipCode: event.target.value }); //make change add cond
        this.setState({ isDirty: true });
        const temp = {
            zipCode: event.target.value
        };
        this.props.handleZipCodeSelect(temp);
        if (event.target.value) {
            this.getLocations(event);
        } else {
            this.setState({ openLocationsAutoComplete: false });
        }
    }
    // nonAssisted = (event) => {
    //     //pattern here
    //     this.setState({ locationsAutoFill: [] });
    //     this.setState( { flag: false });
    //     this.setState({ zipCode: event.target.value }); //make change add cond
    //     this.setState({ isDirty: true });
    //     if (/^(\d{1}\d{2}|\d{3}|\d{4}|\d{5}|\d{6})$/.test( event.target.value )) {
    //         const temp = {
    //             zipCode: event.target.value
    //         };
    //         this.props.handleZipCodeSelect(temp);
    //         this.getLocations(event);
    //     } else {
    //         console.log(1);
    //         this.setState({ openLocationsAutoComplete: false })};
    //     }
    // }
    // handleSelectCity = (address, placeId) => {
    //   let totalAddress = {};
    //   geocodeByAddress(address)
    //       .then((results) => {
    //           totalAddress["address"] = results[0].address_components;
    //           return getLatLng(results[0]);
    //       })
    //       .then((latLng) => {
    //           totalAddress["latlng"] = latLng;
    //           if (this.props.isLng) {
    //             this.props.onSelectlatLng(latLng);
    //           }
    //       })
    //       .then(() => {
    //           totalAddress["placeId"] = placeId;
    //           this.setLocationCity(totalAddress);
    //           return geocodeByPlaceId(placeId);
    //       })
    //       .catch((error) => console.error("Error", error));
    // };
    getLocations = async (event) => {
        let Url;
        if (this.props?.isAuthenticated) {
            Url = process.env.REACT_APP_API_URL.replace("api/v1/", "public/api/v1/") + "places/";
        } else {
            Url = process.env.REACT_APP_API_URL + "places/";
        }
        this.handleChange(event.target.value);
        if (!event.target.value) {
            this.setState({ locationsAutoFill: [] });
        } else if (event.target.value.length >= 1) {
            await ApiCalls.get(Url + event.target.value + "?type=A")
                .then((res) => {
                    if (!res.length) {
                        this.setState({ openLocationsAutoComplete: false });
                    } else {
                        this.setState({ locationsAutoFill: res });
                    }
                }).catch(function (error) {

                });
        }
    };
    handleLatLong = (data) => {
        this.props.handleLatLong(data);
    }
    getAddressDetails = (address, placeId) => {
        this.setState({ zipCode: " " });
        let Url;
        if (this.props?.isAuthenticated) {
            Url = process.env.REACT_APP_API_URL.replace("api/v1/", "public/api/v1/") + "places/";
        } else {
            Url = process.env.REACT_APP_API_URL + "places/";
        }
        let totalAddress = {};
        if (address != undefined) {
            ApiCalls.get(Url + address + "?type=D")
                .then((results) => {
                    // console.log(results);
                    totalAddress["address"] = results[0].addressComponents;
                    totalAddress["latlng"] = results[0].geometry.location;
                    totalAddress["placeId"] = placeId;
                    this.handleLatLong(results[0].geometry.location);
                    this.setLocationAddress(totalAddress);
                })
                .catch((error) => {
                    if (error?.statusCode != 500) {
                        danger(<div>{error?.errorMessage}</div>);
                    }
                    this.setState({ zipCode: "" });
                });
        }
    }

    render() {
        return (
            <>
                <Grid item lg={12} md={12} xs={12} className="city-placeholder">
                    <Autocomplete
                        open={this.state.openLocationsAutoComplete}
                        onClose={() => this.setState({ openLocationsAutoComplete: false })}
                        key={this.props.addLocation ? this.state.clear : ""}
                        id="free-solo-demo"
                        options={this.state.locationsAutoFill}
                        popupIcon={""}
                        className="test addree-profile"
                        noOptionsText={<span className="gray9 f-14 font-weight-400">Please select from the list</span>}
                        getOptionLabel={(option) => option.description ? option.description : this.state.zipCode}
                        value={this.state.zipCode ? this.state.zipCode : null}
                        isOptionEqualToValue={(option, value) => option.description[0].trim() === value}
                        onInputChange={(_, value) => {
                            if (value.length === 0) {
                                if (this.state.openLocationsAutoComplete) this.setState({ openLocationsAutoComplete: false });
                            } else {
                                if (!this.state.openLocationsAutoComplete) this.setState({ openLocationsAutoComplete: true });
                            }
                            if (value == this.state.zipCode) {
                                this.setState({ openLocationsAutoComplete: false });
                            }
                        }}
                        clearIcon={<div style={{ cursor: "auto" }}></div>}
                        renderInput={(params) => (
                            <TextField {...params}
                                type="text"
                                className="form-control input-group inputcontrasts position-issue inputcontrasts edit-skill-align m-b-0 location-search-input citySection basicCityAlign"
                                style={{ marginTop: '7px' }}
                                margin="normal"
                                placeholder={this.props.placeholder ? this.props.placeholder : "Enter zip code"}
                                onChange={this.nonAssisted} />
                        )}
                        onChange={(event, address) => this.getAddressDetails(address?.description, address?.placeId)}
                        ListboxProps={{ style: { maxHeight: '10rem' } }}
                    /></Grid>
            </>
        );
    }
}

export default ZipcodeSearchInput;
