import * as React from "react";
import {
  Grid,
  Breadcrumbs,
  Button,
  Typography,
  TextField,
  Select,
  Box,
  Switch,
  MenuItem,
  FormControlLabel,
  Radio,
  RadioGroup,
  Checkbox,
  IconButton
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Link, NavLink } from "react-router-dom";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import closeIcon from "../../../../assets/images/close-icon.svg";
import calendarIcon from "../../../../assets/images/assessment-calendar.svg";
import CheckIcon from "@mui/icons-material/Check";
import availableIcon from "../../../../assets/images/availability-icon.svg";
import Question from "./Question";
import AccessService from "../../../../Services/Enterprise/Access.service";
import { toast } from "react-toastify";
import Loader from "../../../Loader";
import ScrollToTop from "../../../../Components/shared/ScrollToTop";
import InfoIcon from "@mui/icons-material/Info";
import { success, danger } from "../../../../helpers/message-box";
import { Helmet } from "react-helmet";
import { Tooltip, TooltipTrigger, TooltipContent } from "react-tippy";
import { useSelector } from "react-redux";
import { ReactComponent as DeleteIcon } from "../../../../assets/images/delete-new-icon.svg";
import constants from "../../../../helpers/constants";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

export default function AssessmentQuestions(props) {
  const appOrigin = useSelector((state) => state.location.origin);
  let navigate = useNavigate();
  let location = useLocation();
  const [isLoading, setIsLoading] = React.useState(false);
  const [questions, setQuestions] = React.useState([]);
  const [payload, setPayload] = React.useState("");
  const [editJobId, setEditJobId] = React.useState("");
  const [defaultQuestionsLength, setDefaultQuestionsLength] = React.useState(0);

  const [askAvailability, setAskAvailability] = React.useState(
    editJobId ? true : false
  );

  const [requiredAvailabilityQues, setRequiredAvailabilityQues] =
    React.useState({ q1: false, q2: false, q3: false, q4: false });

  const isNotExternalJobDefaultQuestion = (question) => {
    //let defaultQues = ["What days of the week you are available?", "For how many hours are you available per day?", "Are you looking for full-time or part-time?", "From when are you available for this job?"];
    let defaultQues = [];
    return defaultQues.includes(question);
  };

  React.useEffect(() => {
    let defaultQuestions = questions?.filter(
      (question, index) => question.default === true && !isNotExternalJobDefaultQuestion(question.Question)
    );
    let defaultQues = 0;
    if (payload?.jobType?.includes("FT")) {
      if (payload?.jobType?.includes("FL")) {
        defaultQues = 2;
      } else {
        defaultQues = 1;
      }
    } else if (payload?.jobType?.includes("FL")) {
      defaultQues = 4;
    } else {
      defaultQues = 3;
    }
    if (defaultQuestions?.length) {
      setDefaultQuestionsLength(defaultQuestions?.length + (askAvailability ? defaultQues : 0));
    } else {
      setDefaultQuestionsLength(askAvailability ? defaultQues : 0);
    }
  }, [questions]);

  React.useEffect(() => {
    if (localStorage.getItem("reloadScreeningQsns")) {
      localStorage.removeItem("reloadScreeningQsns");
      window.location.reload();
    }
  }, [localStorage.getItem("reloadScreeningQsns")]);

  React.useEffect(async () => {
    var ques = [];
    if (location.state && location.state.id) {
      setIsLoading(true);
      AccessService.getJobDetails(location.state.id)
        .then((res) => {
          setPayload(res);
          try {
            if (res.questions) {
              ques = JSON.parse(res.questions);
            }
          } catch (err) {
            ques = [];
          }
          var _requiredAvailabilityQues = {
            q1: false,
            q2: false,
            q3: false,
            q4: false,
          };
          for (var i = 0; i < ques.length; i++) {
            ques[i]["id"] = Math.random();
            // if (ques[i].default) {
            //   if (
            //     ques[i].Question === "From when are you available for this job?"
            //   ) {
            //     _requiredAvailabilityQues.q1 = ques[i].required ? true : false;
            //   } else if (
            //     ques[i].Question ===
            //     "Are you looking for full-time or part-time?"
            //   ) {
            //     _requiredAvailabilityQues.q2 = ques[i].required ? true : false;
            //   } else if (
            //     ques[i].Question ===
            //     "For how many hours are you available per day?"
            //   ) {
            //     _requiredAvailabilityQues.q3 = ques[i].required ? true : false;
            //   } else if (
            //     ques[i].Question === "What days of the week you are available?"
            //   ) {
            //     _requiredAvailabilityQues.q4 = ques[i].required ? true : false;
            //   }
            // }
          }
          setRequiredAvailabilityQues(_requiredAvailabilityQues);
          if (ques[0].default && isNotExternalJobDefaultQuestion(ques[0].Question)) setAskAvailability(true);
          setQuestions(ques ? ques : []);
          setIsLoading(false);
        })
        .catch((e) => {
          console.log(e);
          setIsLoading(false);
        });
    } else if (location.state.preview) {
      setPayload(location.state.payload);
      try {
        if (location.state.questions) {
        // ques =
        //   typeof location.state.questions === "string"
        //     ? JSON.parse(location.state.questions)
        //     : location.state.questions;
        }
      } catch (err) {
        ques = [];
      }
      var _requiredAvailabilityQues = {
        q1: false,
        q2: false,
        q3: false,
        q4: false,
      };
      for (var i = 0; i < ques.length; i++) {
        ques[i]["id"] = Math.random();
        // if (ques[i].default) {
        //   if (
        //     ques[i].Question === "From when are you available for this job?"
        //   ) {
        //     _requiredAvailabilityQues.q1 = ques[i].required ? true : false;
        //   } else if (
        //     ques[i].Question === "Are you looking for full-time or part-time?"
        //   ) {
        //     _requiredAvailabilityQues.q2 = ques[i].required ? true : false;
        //   } else if (
        //     ques[i].Question === "For how many hours are you available per day?"
        //   ) {
        //     _requiredAvailabilityQues.q3 = ques[i].required ? true : false;
        //   } else if (
        //     ques[i].Question === "What days of the week you are available?"
        //   ) {
        //     _requiredAvailabilityQues.q4 = ques[i].required ? true : false;
        //   }
        // }
      }
      setRequiredAvailabilityQues(_requiredAvailabilityQues);
      if (ques[0].default && isNotExternalJobDefaultQuestion(ques[0].Question)) setAskAvailability(true);
      setQuestions(ques ? ques : []);
      if (location.state.editJobId) {
        setEditJobId(location.state.editJobId);
      }
    } else if (location.state) {
      setPayload(location.state.payload);
      setEditJobId(location.state.editJobId);
      setAskAvailability(true);
      if (location.state.payload?.questions) {
        try {
          let preloadedQues = JSON.parse(location.state.payload?.questions);
          // setQuestions(preloadedQues);
        } catch (err) {}
      }
      let defaultQues = 0;
      if (location.state.payload.jobType.includes("FT")) {
        if (location.state.payload.jobType.includes("FL")) {
          defaultQues = 2;
        } else {
          defaultQues = 1;
        }
      } else if (location.state.payload.jobType.includes("FL")) {
        defaultQues = 4;
      } else {
        defaultQues = 3;
      }
      setDefaultQuestionsLength(defaultQues);
    }
  }, []);

  const addQuestion = () => {
    var ques = [...questions];
    ques.push({
      Question: "",
      Options: ["", ""],
      Type: "radio",
      default: false,
      id: Math.random(),
      required: false,
    });
    setQuestions(ques);
  };

  const deleteQuestion = (index, deleteCustomQuestion = true) => {
    if (!(location.state && location.state.preview)) {
      var ques = [...questions];
      // ques.filter((q, i) => i !== index)
      // var p = [...ques.splice(index, 1)]
      let defaultQues = ques.filter((q)=>(q.default == true && (deleteCustomQuestion ? true : isNotExternalJobDefaultQuestion(q.Question))));
      setQuestions(ques.filter((q, i) => i !== (index + defaultQues?.length)));
    }
  };

  const handleChange = (data, index, isVMSDefaultQues = false) => {
    var ques = [...questions];
    if (!isVMSDefaultQues) {
      let defaultQues = ques.filter((q)=>(q.default == true));
      ques[index + defaultQues?.length] = data;
    } else {
      ques[index] = data;
    }
    setQuestions(ques);
  };

  const isDefaultQuesReq = (question) => {
    // if (question.Question === "From when are you available for this job?") {
    //   return requiredAvailabilityQues.q1;
    // } else if (
    //   question.Question === "Are you looking for full-time or part-time?"
    // ) {
    //   return requiredAvailabilityQues.q2;
    // } else if (
    //   question.Question === "For how many hours are you available per day?"
    // ) {
    //   return requiredAvailabilityQues.q3;
    // } else if (
    //   question.Question === "What days of the week you are available?"
    // ) {
    //   return requiredAvailabilityQues.q4;
    // }
    return (question.required ? question.required : false);
  };

  const checkEmptyFields = () => {
    var dupObj = [];

    for (var i = 0; i < questions.length; i++) {
      if (questions[i].Question === "" || questions[i].Type === "") {
        danger("Please fill all the fields.");
        return false;
      } else if (
        questions[i].Type !== "text" &&
        !questions[i].default &&
        (questions[i]["Options"].includes("") ||
          !Boolean(questions[i]["Options"][0]))
      ) {
        danger("Please fill all the fields.");
        return false;
      } else {
        dupObj.push(
          questions[i]?.key
            ? {
                key: String(i+1),
                Question: questions[i].Question,
                Options: [
                  ...(questions[i].Type !== "text"
                    ? questions[i]["Options"]
                    : []),
                ],
                Type: questions[i].Type,
                default: questions[i].default !== true ? false : true,
                required:
                  (questions[i].default !== true
                    ? (questions[i].required ? true : false)
                    : isDefaultQuesReq(questions[i])),
                scope: questions[i].scope ? questions[i].scope : null,
                stopCriteria: questions[i].stopCriteria,
              }
            : {
                key: String(i+1),
                Question: questions[i].Question,
                Options: [
                  ...(questions[i].Type !== "text"
                    ? questions[i]["Options"]
                    : []),
                ],
                Type: questions[i].Type,
                default: questions[i].default !== true ? false : true,
                required:
                  (questions[i].default !== true
                    ? (questions[i].required ? true : false)
                    : isDefaultQuesReq(questions[i])),
                scope: questions[i].scope ? questions[i].scope : null,
                stopCriteria: questions[i].stopCriteria,
              }
        );
      }
    }
    return dupObj;
  };

  const postAssessment = async () => {
    var ques = checkEmptyFields();
    if (askAvailability && ques) {
      if (ques[0] ? (ques[0].default ? !isNotExternalJobDefaultQuestion(ques[0].Question) : !ques[0].default) : true) {
        // if (!payload.jobType?.includes("FT")) {
        //   ques.unshift({
        //     Question: "What days of the week you are available?",
        //     Options: [
        //       "Sunday",
        //       "Monday",
        //       "Tuesday",
        //       "Wednesday",
        //       "Thursday",
        //       "Friday",
        //       "Saturday",
        //     ],
        //     Type: "checkbox",
        //     default: true,
        //     required: requiredAvailabilityQues.q4,
        //     scope: null,
        //   });
        //   ques.unshift({
        //     Question: "For how many hours are you available per day?",
        //     Options: [],
        //     Type: "text",
        //     default: true,
        //     required: requiredAvailabilityQues.q3,
        //     scope: null,
        //   });
        // }
        // if (payload.jobType?.includes("FL")) {
        //   ques.unshift({
        //     Question: "Are you looking for full-time or part-time?",
        //     Options: ["Full Time", "Part Time"],
        //     Type: "radio",
        //     default: true,
        //     required: requiredAvailabilityQues.q2,
        //     scope: null,
        //   });
        // }
        // ques.unshift({
        //   Question: "From when are you available for this job?",
        //   Options: [],
        //   Type: "date",
        //   default: true,
        //   required: requiredAvailabilityQues.q1,
        //   scope: null,
        // });
      }
    }
    if (ques) {
      let updatedQuestions = [];
      ques?.map((_question, index)=>{
        updatedQuestions.push({ ..._question, key: String(index+1) });
      });
      var dupPayload = { ...payload };
      dupPayload["questions"] = JSON.stringify(updatedQuestions);
      if (editJobId) dupPayload["id"] = editJobId;
      (dupPayload["checkListId"] =
        location && location.state && location.state.checkListId
          ? location.state.checkListId
          : undefined),
        await AccessService.updateAJob(dupPayload)
          .then((res) => {
            localStorage.removeItem("keyword");
            localStorage.removeItem("MyJobsPageNo");
            localStorage.removeItem("filterStatus");
            localStorage.removeItem("filterType");
            if (editJobId) {
              success(" You have successfully posted the job.");
              /*navigate('/enterprise/success')*/
              navigate("/enterprise/jobList");
            } else {
              navigate("/enterprise/jobList");
              success("Screening questions updated successfully.");
            }
            localStorage.removeItem("JD");
            localStorage.removeItem("skills");
            localStorage.removeItem("additionalDetails");
            localStorage.removeItem("JDUrl");
          })
          .catch((e) => {
            //   if (e && e.response && e.response.data && e.response.data.detailMessage) {
            //   navigate("/enterprise/jobList");
            //   danger(<div>{e.response.data.detailMessage}</div>);
            // }
            if (e) {
              navigate("/enterprise/jobList");
              danger(<div>{e.errorMessage}</div>);
            } else {
              danger("Error occured while posting Screening questions.");
            }
          });
    }
  };

  const handleAvailability = async () => {
    if (!(location.state && location.state.preview)) {
      // If user don't want availability while updating the assessment we are deleting the first question from the ques arr
      if (
        !editJobId &&
        askAvailability &&
        questions[0] &&
        questions[0].default &&
        isNotExternalJobDefaultQuestion(questions[0].Question)
      ) {
        var ques = [...questions];
        if (payload.jobType.includes("FT")) {
          if (payload.jobType.includes("FL")) {
            ques.splice(0, 2);
          } else {
            ques.splice(0, 1);
          }
        } else if (payload.jobType.includes("FL")) {
          ques.splice(0, 4);
        } else ques.splice(0, 3);
        setQuestions(ques);
      } else {
        let defaultQues = 0;
        if (payload.jobType.includes("FT")) {
          if (payload.jobType.includes("FL")) {
            defaultQues = 2;
          } else {
            defaultQues = 1;
          }
        } else if (payload.jobType.includes("FL")) {
          defaultQues = 4;
        } else {
          defaultQues = 3;
        }
        if (askAvailability) {
          setDefaultQuestionsLength(defaultQuestionsLength-defaultQues);
        } else {
          setDefaultQuestionsLength(defaultQuestionsLength+defaultQues);
        }
      }
      setAskAvailability(!askAvailability);
    }
  };
  // const handleChange = (event) => {
  //     setAge(event.target.value);
  // };

  return (
    <>
      {isLoading && <Loader />}
      <Helmet>
        {appOrigin === "N" ?
        <title>Screening Questions | {constants.APP_NAME_NEURODIVERSIFY}</title>
          : appOrigin === "H" ? <title>Screening Questions | {constants.APP_NAME_DIRECT_HEALTH}</title> :
          <title>Screening Questions | {constants.APP_NAME_GENERAL}</title>
        }
        {/* <title>Screening Questions | SimplifyHire</title> */}
        <meta name="description" content={`Screening Questions | ${constants.APP_NAME_GENERAL}`} />
        <link
          rel="simplify"
          href={process.env.REACT_APP_AWS_USER_POOL_REDIRECT_SIGN_IN}
        />
      </Helmet>
      <ScrollToTop>
        <Grid
          container
          item
          className="pt-10 responsive-view-align responsive-layout"
          sx={{ px: 3 }}
        >
          <Grid
            item
            lg={12}
            md={12}
            xs={12}
            sx={{ mt: 5 }}
            className="pr-5 joblist-align matching-location-align screening-align myJobs-status galaxy-padding-top-30 responsive-view-align responsive-layout"
          >
            <Breadcrumbs
              aria-label="breadcrumb"
              className="p-10"
              separator={
                <NavigateNextIcon fontSize="small" aria-label="Next icon" />
              }
            >
              <Link
                underline="hover"
                className="cursor-hover f-14 gray9 font-weight-400"
                to="/enterprise/JobList"
              >
                <WorkOutlineIcon sx={{ mr: 0.5 }} aria-label="Work icon" />
                Jobs
              </Link>
              {/* {editJobId ? (
                <Link
                  underline="hover"
                  to="/enterprise/postJob"
                  className="cursor-hover f-14 gray9 font-weight-400"
                >
                  Post a Job
                </Link>
              ) : (
                ""
              )} */}
              {location.state && location.state.preview ? (
                <Typography
                  onClick={() =>
                    editJobId
                      ? navigate("/enterprise/postJob", {
                          state: { previewJob: true, id: editJobId },
                        })
                      : navigate(-1)
                  }
                  className="cursor-hover f-14 gray9 font-weight-400"
                  onKeyPress={() =>
                    editJobId
                      ? navigate("/enterprise/postJob", {
                          state: { previewJob: true, id: editJobId },
                        })
                      : navigate(-1)
                  }
                  tabIndex={0}
                >
                  Preview job
                </Typography>
              ) : (
                ""
              )}
              <Link
                underline="hover"
                to="/enterprise/screeningQuestions"
                className="link-color cursor-hover f-14 font-weight-400"
                state={{ ...location.state }}
              >
                Screening Questions
              </Link>
            </Breadcrumbs>
            <Grid
              item
              id="screening-questions"
              className={
                location.state?.preview
                  ? "bg-white mt-20 jobHeader-align pointerEventsNone"
                  : "bg-white mt-20 jobHeader-align"
              }
            >
              <Typography className="font-weight-600 f-20 heading-text-color mt-15">
                Screening Questions
              </Typography>
              {/* <Typography className="mt-20" sx={{ cursor: "pointer" }}>
                <span
                  className="screen-question"
                  onClick={() => handleAvailability()}
                  tabIndex={0}
                  onKeyPress={() => handleAvailability()}
                >
                  Availability{" "}
                  {askAvailability ? (
                    <img src={availableIcon} alt="img" />
                  ) : (
                    "+"
                  )}
                </span>
              </Typography> */}

              <Grid item className="mt-30">
                {askAvailability || (questions ? questions[0] : false) ? (
                  <Typography className="font-weight-600 f-16 gray9">
                    {/* Assessment Question */}
                  </Typography>
                ) : (
                  ""
                )}
                {/* {askAvailability ? (
                  <>
                    <Typography
                      className={
                        payload.jobType?.includes("FL") ||
                        !payload.jobType?.includes("FT") ||
                        (questions?.length >= 1 &&
                          (questions?.length === 1
                            ? !questions[0].default
                            : true))
                          ? "border-gray6 pt-10 pb-10 pl-15 pr-15 border-radius-8"
                          : "border-gray6 pt-10 pb-10 pl-15 pr-15 border-radius-8"
                      }
                    >
                      <Typography className="font-weight-700 f-16 gray9 mb-10">
                        Question 1
                      </Typography>
                      <Box className="gray7 font-weight-400 f-14 mb-10">
                        Question
                      </Box>
                      <Typography className="form-control input-group mb-0 borderRadius-l align-between p-10 inputcontrasts">
                        <Box className="gray9 font-weight-400 f-16">
                          From when are you available for this job?
                        </Box>
                      </Typography>
                      <Typography className="d-flex mt-10 align-center">
                        <Box className="responsive-icon responsive-calender-icon">
                          <img
                            style={{ width: "15px" }}
                            src={calendarIcon}
                            alt="img"
                          />{" "}
                        </Box>
                        <Box className="f-16 sub-text-color font-weight-400 ml-15 galaxy-date-text">
                          Date will be selected by candidate
                        </Box>
                      </Typography>
                      <Typography className="justify-end d-flex border-top-gray5 mt-20">
                        <Typography
                          align="left"
                          className="switch-case-section pt-10"
                        >
                          <span className="f-14 font-weight-400 mr-5">
                            Required
                          </span>
                          <Switch
                            checked={requiredAvailabilityQues.q1}
                            onChange={(e) => {
                              setRequiredAvailabilityQues({
                                ...requiredAvailabilityQues,
                                q1: e.target.checked,
                              });
                            }}
                            inputProps={{ "aria-label": "controlled" }}
                            className="switch-position"
                          />
                        </Typography>
                      </Typography>
                    </Typography>

                    {payload.jobType?.includes("FL") ? (
                      <Typography
                        className={
                          !payload.jobType?.includes("FT") ||
                          (questions?.length >= 1 &&
                            (questions?.length === 2
                              ? !questions[1].default
                              : true))
                            ? "border-gray6 pt-10 pb-10 pl-15 pr-15 border-radius-8 mt-20"
                            : "border-gray6 pt-10 pb-10 pl-15 pr-15 border-radius-8 mt-20"
                        }
                      >
                        <Typography className="font-weight-700 f-16 gray9 mb-10">
                          Question 2
                        </Typography>
                        <Box className="gray7 font-weight-400 f-14 mb-10">
                          Question
                        </Box>
                        <Typography className="form-control input-group mb-0 borderRadius-l align-between p-10 inputcontrasts">
                          <Box className="gray9 font-weight-400 f-16">
                            Are you looking for full-time or part-time?
                            <span>
                              <Tooltip
                                position="right"
                                trigger="mouseenter"
                                animation="fade"
                                arrow
                                title="Candidate will share the number of hours and days of the week if availability is part-time"
                              >
                                <IconButton aria-label="Info icon">
                                  <InfoIcon aria-label="Info icon" />
                                </IconButton>
                              </Tooltip>
                            </span>
                          </Box>
                        </Typography>
                        <Typography className="d-flex mt-10 align-center">
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                          >
                            <Typography className="d-flex">
                              <FormControlLabel
                                disabled
                                className="assessment-details mr-10"
                                value=""
                                control={
                                  <Radio
                                    sx={{
                                      color: "#98A2B3",
                                      "&.Mui-checked": {
                                        color: getComputedStyle(
                                          document.documentElement
                                        ).getPropertyValue("--primary-color"),
                                      },
                                    }}
                                  />
                                }
                                label="Full time"
                              />
                              <FormControlLabel
                                disabled
                                className="assessment-details mr-10"
                                value=""
                                control={
                                  <Radio
                                    sx={{
                                      color: "#98A2B3",
                                      "&.Mui-checked": {
                                        color: getComputedStyle(
                                          document.documentElement
                                        ).getPropertyValue("--primary-color"),
                                      },
                                    }}
                                  />
                                }
                                label="Part time"
                              />
                            </Typography>
                          </RadioGroup>
                        </Typography>
                        <Typography className="justify-end d-flex border-top-gray5 mt-20">
                          <Typography
                            align="left"
                            className="switch-case-section pt-10"
                          >
                            <span className="f-14 font-weight-400 mr-5">
                              Required
                            </span>
                            <Switch
                              checked={requiredAvailabilityQues.q2}
                              onChange={(e) =>
                                setRequiredAvailabilityQues({
                                  ...requiredAvailabilityQues,
                                  q2: e.target.checked,
                                })
                              }
                              inputProps={{ "aria-label": "controlled" }}
                              className="switch-position"
                            />
                          </Typography>
                        </Typography>
                      </Typography>
                    ) : (
                      ""
                    )}

                    {!payload.jobType?.includes("FT") ? (
                      <>
                        <Typography className="border-gray6 pt-10 pb-10 pl-15 pr-15 border-radius-8 mt-20">
                          <Typography className="font-weight-700 f-16 gray9 mb-10">
                            Question {`${payload.jobType?.includes("FL") ? '3' : '2'}`}
                          </Typography>
                          <Box className="gray7 font-weight-400 f-14 mb-10">
                            Question
                          </Box>
                          <Typography className="form-control input-group mb-0 borderRadius-l align-between p-10 inputcontrasts">
                            <Box className="gray9 font-weight-400 f-16">
                              For how many hours are you available per day?
                            </Box>
                          </Typography>
                          <Typography className="d-flex mt-10 align-center">
                            <Box className="f-16 sub-text-color font-weight-400">
                              Candidate will enter the number of hours available
                            </Box>
                          </Typography>
                          <Typography className="justify-end d-flex border-top-gray5 mt-20">
                            <Typography
                              align="left"
                              className="switch-case-section pt-10"
                            >
                              <span className="f-14 font-weight-400 mr-5">
                                Required
                              </span>
                              <Switch
                                checked={requiredAvailabilityQues.q3}
                                onChange={(e) =>
                                  setRequiredAvailabilityQues({
                                    ...requiredAvailabilityQues,
                                    q3: e.target.checked,
                                  })
                                }
                                inputProps={{ "aria-label": "controlled" }}
                                className="switch-position"
                              />
                            </Typography>
                          </Typography>
                        </Typography>
                        <Typography
                          className={
                            questions?.length >= 1 &&
                            (questions?.length === 3
                              ? !questions[2].default
                              : questions?.length === 4
                              ? !questions[3].default
                              : true)
                              ? "border-gray6 pt-10 pb-10 pl-15 pr-15 border-radius-8 mt-20"
                              : "border-gray6 pt-10 pb-10 pl-15 pr-15 border-radius-8 mt-20"
                          }
                        >
                          <Typography className="font-weight-700 f-16 gray9 mb-10">
                            Question {`${payload.jobType?.includes("FL") ? '4' : '3'}`}
                          </Typography>
                          <Box className="gray7 font-weight-400 f-14 mb-10">
                            Question
                          </Box>
                          <Typography className="form-control input-group mb-0 borderRadius-l align-between p-10 inputcontrasts">
                            <Box className="gray9 font-weight-400 f-16">
                              What days of the week you are available?
                            </Box>
                          </Typography>
                          <Typography className="d-flex mt-10 align-center responsive-block screening-view flex-wrap">
                            {[
                              "Sunday",
                              "Monday",
                              "Tuesday",
                              "Wednesday",
                              "Thursday",
                              "Friday",
                              "Saturday",
                            ].map((option, index) => {
                              return (
                                <Typography
                                  className="d-flex"
                                  style={{ marginRight: "11px" }}
                                  key={index}
                                >
                                  <>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          disabled
                                          sx={{
                                            color: "#98A2B3",
                                            "&.Mui-checked": {
                                              color: "#98A2B3",
                                            },
                                          }}
                                        />
                                      }
                                      className="gray9 f-16 font-weight-400"
                                      label={option}
                                    />
                                  </>
                                </Typography>
                              );
                            })}
                          </Typography>
                          <Typography className="justify-end d-flex border-top-gray5 mt-20">
                            <Typography
                              align="left"
                              className="switch-case-section pt-10"
                            >
                              <span className="f-14 font-weight-400 mr-5">
                                Required
                              </span>
                              <Switch
                                checked={requiredAvailabilityQues.q4}
                                onChange={(e) =>
                                  setRequiredAvailabilityQues({
                                    ...requiredAvailabilityQues,
                                    q4: e.target.checked,
                                  })
                                }
                                inputProps={{ "aria-label": "controlled" }}
                                className="switch-position"
                              />
                            </Typography>
                          </Typography>
                        </Typography>
                      </>
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  ""
                )} */}
                {/* {questions?.filter((question) => question.default === true && !isNotExternalJobDefaultQuestion(question.Question))?.map((question, index) => {
                        return (
                          <Typography
                      className="border-gray6 pt-10 pb-10 pl-15 pr-15 border-radius-8 mt-20"
                      key={index}
                    >
                      <Typography className="font-weight-700 f-16 gray9 mb-10 five">
                      {"Question " + (index + 1 + (askAvailability ? payload?.jobType?.includes("FT") ? payload?.jobType?.includes("FL") ? 2 : 1 : payload?.jobType?.includes("FL") ? 4 : 3 : 0))}
                        </Typography>
                          <Box className="gray7 font-weight-400 f-14 mb-10">
                            Question
                          </Box>
                      <Typography className="form-control input-group mb-0 borderRadius-l align-between p-10 inputcontrasts">
                        <Box className="gray9 font-weight-400 f-16">
                          {question.Question}
                        </Box>
                      </Typography>
                      {question.Type == "date" &&
                      <Typography className="d-flex mt-10 align-center">
                        <Box className="responsive-icon responsive-calender-icon">
                          <img
                            style={{ width: "15px" }}
                            src={calendarIcon}
                            alt="img"
                          />{" "}
                        </Box>
                        <Box className="f-16 sub-text-color font-weight-400 ml-15 galaxy-date-text">
                          Date will be selected by candidate
                        </Box>
                      </Typography>
                      }
                      {question.Type == "checkbox" && question.Options &&
                      <Typography className="d-flex mt-10 align-center responsive-block screening-view">
                      <Grid lg={12} md={12} xs={12} container>
                      {question.Options.map((option, index) => {
                        return (
                          <Typography
                            className="d-flex"
                            style={{ marginRight: "11px" }}
                            key={index}
                          >
                            <>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    disabled
                                    sx={{
                                      color: "#98A2B3",
                                      "&.Mui-checked": {
                                        color: "#98A2B3",
                                      },
                                    }}
                                  />
                                }
                                className="gray9 f-16 font-weight-400"
                                label={option}
                              />
                            </>
                          </Typography>
                        );
                      })}
                      </Grid>
                    </Typography>
                      }
                      {question.Type == "radio" && question.Options &&
                      <Typography className="d-flex mt-10 align-center">
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                      >
                        <Typography className="d-flex">
                        <Grid lg={12} md={12} xs={12} container>
                        {question.Options.map((option, index) => (
                          <FormControlLabel
                            key={index}
                            disabled
                            className="assessment-details mr-10"
                            value=""
                            control={
                              <Radio
                                sx={{
                                  color: "#98A2B3",
                                  "&.Mui-checked": {
                                    color: getComputedStyle(
                                      document.documentElement
                                    ).getPropertyValue("--primary-color"),
                                  },
                                }}
                              />
                            }
                            label={option}
                          />))}
                        </Grid>
                        </Typography>
                      </RadioGroup>
                    </Typography>
                      }
                      <Typography className="justify-end d-flex border-top-gray5 mt-20">
                      <Box className="d-flex align-center">
                        <Typography
                          align="left"
                          className="switch-case-section pt-10"
                        >
                          <span className="f-14 font-weight-400 mr-5">
                            Required
                          </span>
                          <Switch
                            checked={question.required ? question.required : false}
                            onChange={(e) => {
                              let ques = { ...question, required: e.target.checked };
                              handleChange(ques, index, true);
                            }}
                            inputProps={{ "aria-label": "controlled" }}
                            className="switch-position"
                          />
                        </Typography>
                        {!payload?.externalJobId &&
                        <DeleteIcon
                          className="cursor-hover ml-10 mt-10 screening-del-align"
                          width="20px"
                          onClick={() => deleteQuestion(index, false)}
                          onKeyPress={() => deleteQuestion(index, false)}
                          aria-label="Delete icon"
                        />}
                      </Box>
                      </Typography>
                    </Typography>
                        );
                      })
                } */}
              </Grid>

              {questions?.filter((question)=>question.default != true)?.map((question, index) => {
                return question.default === true ? (
                  ""
                ) : (
                  <Typography className="mt-20">
                    <Question
                      question={question}
                      index={index}
                      handleChange={handleChange}
                      deleteQuestion={deleteQuestion}
                      key={question.id}
                      questions={questions}
                      jobType={payload.jobType}
                      preview={location.state && location.state.preview}
                      defaultQuestionsLength={defaultQuestionsLength}
                    />
                  </Typography>
                );
              })}

              {location.state && !location.state.preview && (
                <>
                  <Grid item sx={{ mt: 10 }} className="lead-view">
                    <Typography>
                      <Button
                        className="btn assessment-btn w-100"
                        onClick={addQuestion}
                        aria-label="Custom question"
                        tabIndex={0}
                        disableRipple="true"
                      >
                        + Add Custom Questions
                      </Button>
                    </Typography>
                  </Grid>

                  <Grid item className="mt-30 justify-end d-flex" sx={{ mb: 9 }}>
                    <Button
                      className="btn primary-button mt-20"
                      onClick={postAssessment}
                      aria-label="save"
                      tabIndex={0}
                      disableRipple="true"
                    >
                      Save
                    </Button>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      </ScrollToTop>
    </>
  );
}
