import React from 'react';
import { Tooltip } from "react-tippy";

function TooltipShared({ title, children, placement = "top", trigger = "mouseenter", animation = "fade", className }) {
    // render tooltip only if title is not empty
    // if (!title) {
    //     return children; // return children without tooltip
    // }
    // return (
    //     <Tooltip
    //         title={title}
    //         placement={placement}
    //         trigger={trigger}
    //         animation={animation}
    //         className={className}
    //         arrow
    //     >
    //         {children}
    //     </Tooltip>
    // );

    // commented out tooltip for current release
    return children;
}

export default TooltipShared;
