import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  Grid,
  Typography,
  Button,
  Container,
  Breadcrumbs,
  Box,
  Popover,
  Drawer,
  Link
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import AccessService from "../../../Services/Enterprise/Access.service";
import Loader from "../../Loader";
import moment from "moment";
import { functiondisable, functionenable } from "../../../helpers/helper";
import CloseIcon from "@mui/icons-material/Close";
import { convertHtmlToText, formatDate, getDurationPeriod, getDurationValue, getLocation } from "../../../helpers/helper-data";
import { ReactComponent as LocationIcon } from "../../../assets/images/location-new-icon.svg";
import { ReactComponent as FullTimeIcon } from "../../../assets/images/full-time-new-icon.svg";
import { ReactComponent as JobReferenceNumberIcon } from "../../../assets/images/jobRefNumberIcon.svg";
import { ReactComponent as ExternalJobIdIcon } from "../../../assets/images/externalJobIdIcon.svg";
import ShareJob from "./ShareJob";
import JobMenu from "../Project/_JobCandidateDetailsPartials/JobMenu";
import { useSelector } from "react-redux";
import { Tooltip, TooltipTrigger, TooltipContent } from "react-tippy";
import ReactPlayer from "react-player";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { textVide } from 'text-vide';
import { ReactComponent as ReferCandidate } from "../../../assets/images/refer-candidate-icon.svg";
import RefereeDetails from "../Referrals/RefereeDetails";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import copy from "copy-to-clipboard";
import { success } from "../../../helpers/message-box";

export default function JobDetails(props) {
  const appOrigin = useSelector((state) => state.location.origin);
  let location = useLocation();
  const [showMore, setShowMore] = useState(false);
  const [JD, setJD] = useState({});
  const [skills, setSkills] = useState([]);
  const [discipline, setDiscipline] = useState(null);
  const [additionalDetails, setAdditionalDetails] = useState({});
  const [isLoading, setIsLoading] = useState(null);
  const [isLoadingDrawer, setIsLoadingDrawer] = useState(false);
  const [payload, setPayload] = useState();
  const questions = location.state ? location.state.questions : "";
  const params = useParams();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [educationList, setEducationList] = useState([]);
  const [showEducations, setShowEducations] = useState(false);
  const open1 = Boolean(anchorEl);
  const job = props.job;
  const [editJobId, setEditJobId] = useState(null);
  const [postedJobId, setPostedJobId] = useState(null);
  const [open, setOpen] = useState(false);
  const [fulljobdetails, setFullJobDetails] = useState({});
  const [openReferCandidate, setOpenReferCandidate] = useState(false);
  const [bionic, setBionic] = useState(
    localStorage.getItem("isBionic") ? localStorage.getItem("isBionic") : null
  );
  const [fixation, setFixation] = useState(
    localStorage.getItem("isBionic")
      ? JSON.parse(localStorage.getItem("bionic"))
      : null
  );
  const jobDescriptionUrl = location.state
    ? location.state.jobDescriptionUrl
    : localStorage.getItem("JDUrl");
  // const jobDescriptionTitle = localStorage.getItem("JDTitle") ? localStorage.getItem("JDTitle") : "MyJD"
  // const [open, setOpen] = React.useState(false);
  const [openAssessment, setOpenAssessment] = React.useState(false);
  const handleOpenAssessment = () => setOpenAssessment(true);
  const handleCloseAssessment = (event, reason) => {
    if (reason && reason == "backdropClick") return;
    setOpenAssessment(false);
  };

  const videoLinkRegex =
    /^(https?\:\/\/)?(www\.)?(youtube\.com|youtu\.?be|player\.vimeo\.com|vimeo\.com|dailymotion\.com|facebook\.com|player\.twitch\.tv|twitch\.tv|dai\.ly|videos\.kaltura\.com|storage\.googleapis\.com)\S*/;
  let youtubeRegex =
    /^(https?\:\/\/)?(www\.)?(youtube\.com\/user|youtu\.?be\/user)\S*/;
  const urlvalidation =
    /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}\/[^\s]{3,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}\/[^\s]{3,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}\/[^\s]{3,}|www\.[a-zA-Z0-9]+\.[^\s]{2,}\/[^\s]{3,})/gi;

  const handleViewAllSkills = (event) => {
    setAnchorEl(event.currentTarget);
    functiondisable();
  };
  const handleClosePopover = () => {
    setAnchorEl(null);
    functionenable();
  };
  let currentDate = moment().format("YYYY-MM-DD");

  useEffect(() => {
    if ((params.job_id ? params.job_id : props.jobId) !== JD?.jobId ) {
    setIsLoading(true);
    setIsLoadingDrawer(true);
    AccessService.getJobDetails(params.job_id ? params.job_id : props.jobId)
      .then((response) => {
        let job = response;
        setFullJobDetails(job);
        setJD({
          jobId: job.id,
          id: job.id,
          title: job.jobTitle,
          minBudget: job.budgetMinimum,
          maxBudget: job.budgetMaximum,
          jobType: job.jobType?.replace(/\s|\[|\]/g, "")?.split(","),
          JD: job.jobDescriptionText,
          term: job.paymentTerm,
          jobStatus: job.status,
          validTill: job.validTill,
          aboutToExpire: job.aboutToExpire,
          currency: job.currency ? job.currency : "USD",
          externalJobId: job.externalJobId,
          jobReferenceNumber: job.jobReferenceNumber,
        });
        setSkills(job.jobSkills);
        if (job.jobSkills?.length) {
          setDiscipline(job.jobSkills[0].skills.subcategoryKeyword);
        }
        setAdditionalDetails({
          city: job.city ? job.city : "",
          state: job.state,
          country: job.country,
          education: job.minimumEducation,
          openings: job.numberOfOpenings,
          startDate: job.validFrom,
          endDate: formatDate(
            new Date(
              JSON.parse(
                JSON.stringify(job.validTill).replace("23:59:59", "00:00:00")
              )
            )
          ),
          duration: job.duration,
          benefits: job.benefits,
          minExp: job.overallExperienceMinimum,
          maxExp: job.overallExperienceMaximum,
          jobCategory: job.jobCategory,
          workFrom: job.workFrom ? job.workFrom : "",
          jobExperienceLevel: job.jobExperienceLevel,
          questions: job.questions ? JSON.parse(job.questions) : job.questions,
          mspEnterpriseId: job?.mspEnterpriseId,
          mspEnterpriseName: job?.mspEnterpriseName,
          neuroClassification: job?.neuroClassification,
          shift: job?.shift,
          socialMediaUrl: job?.socialMediaUrl,
          status: job?.status,
          allowedToWorkInOtherJobs: job?.allowedToWorkInOtherJobs
        });
        setEducationList(job?.jobEducationalQualificationsDtoList);
        setIsLoading(false);
        setIsLoadingDrawer(false);
      })
      .catch(function (error) {
        setIsLoading(false);
        setIsLoadingDrawer(false);
      });
    }
  }, [props.viewJobDetailDrawer]);

  let navigate = useNavigate();

  const getPaymentTerm = (term) => {
    switch (term) {
      case "Per hour":
        return "hour";
      case "Per day":
        return "day";
      case "Per month":
        return "month";
      case "Per week":
        return "week";
      default:
        return "";
    }
  };

  const getWorkType = (type) => {
    switch (type) {
      case "R":
        return "Remote";
      case "O":
        return "Onsite";
      case "H":
        return "Hybrid";
      default:
        return "-";
    }
  };

  const getJobExpLevel = (level) => {
    switch (level) {
      case "IN":
        return "Internship";
      case "EL":
        return "Entry Level";
      case "AT":
        return "Associate";
      case "ML":
        return "Mid-Senior Level";
      case "DR":
        return "Director";
      case "ET":
        return "Executive";
      default:
        return "-";
    }
  };

  const [anchorEl2, setAnchorEl2] = React.useState(null);

  const handleOpenJobType = (event) => {
    setAnchorEl2(event.currentTarget);
    functiondisable();
  };

  const copyToClipboard = (event, jobReferenceNumber) => {
    event.stopPropagation();
    copy(jobReferenceNumber);
    success("Reference number is copied.");
  };

  const handleCloseJobType = () => {
    setAnchorEl2(null);
    functionenable();
  };

  const openJobType = Boolean(anchorEl2);

  const getJobType = (viewAll) => {
    let formatted_jobType = [];
    let jobType = JD?.jobType;
    if (jobType?.length) {
      if (jobType.includes("FT")) {
        formatted_jobType.push("Full Time");
      }
      if (jobType.includes("PT")) {
        formatted_jobType.push("Part Time");
      }
      if (jobType.includes("FL")) {
        formatted_jobType.push("Contingent / Contract");
      }
      if (jobType.includes("IN")) {
        formatted_jobType.push("Internship");
      }
      if (jobType.includes("OT")) {
        formatted_jobType.push("Other");
      }
      if (!viewAll && formatted_jobType.length > 1) {
        formatted_jobType = formatted_jobType.slice(0, 1);
      }
      return formatted_jobType.join(", ");
    } else {
      return "-";
    }
  };

  const getFormattedShift = (preferredShift) => {
    if (preferredShift === "D") {
      return "Day Shift";
    } else if (preferredShift === "E") {
      return "Evening Shift";
    } else if (preferredShift === "N") {
      return "Night Shift";
    }
    return "Flexible Shift";
  };

  var paymentTerm = JD
    ? JD.term === "MN"
      ? "Per month"
      : JD.term === "WK"
      ? "Per week"
      : JD.term === "DA"
      ? "Per day"
      : "Per hour"
    : "-";
  var educationLevel = additionalDetails.education
    ? additionalDetails.education === "BR"
      ? "Bachelors"
      : additionalDetails.education === "DC"
      ? "Doctorate"
      : additionalDetails.education === "MT"
      ? "Masters"
      : additionalDetails.education === "DM"
      ? "Diploma"
      : "School"
    : "-";

  const goToAssessment = () => {
    localStorage.removeItem("JD");
    localStorage.removeItem("skills");
    localStorage.removeItem("additionalDetails");
    localStorage.removeItem("JDUrl");
    navigate("/enterprise/screeningQuestions", {
      state: { editJobId: postedJobId, payload: payload },
    });
  };

  const assessmentPreview = () => {
    localStorage.setItem("reloadScreeningQsns", true);
    navigate("/enterprise/screeningQuestions", {
      state: {
        preview: true,
        questions: additionalDetails?.questions,
        payload: JD,
      },
    });
  };
  const current = new Date();
  const date = `${current.getFullYear()}-${
    current.getMonth() + 1
  }-${current.getDate()}`;

  useEffect(() => {
    const bodyElement = document.body;
    const htmlElement = document.documentElement;
    if (props.viewJobDetailDrawer) {
      bodyElement.style.overflow = "hidden";
      htmlElement.style.overflow = "hidden";
    } else {
      bodyElement.style.overflow = "";
      htmlElement.style.overflow = "";
    }
  }, [props.viewJobDetailDrawer]);
  const YouTubeGetID = (url) => {
    url = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/|\/shorts\/|u\/\w\/)/);
    return url[2] !== undefined ? url[2]?.split(/[^0-9a-z_\-]/i)[0] : url[0];
  };
  const NormalFormat = (data, fixation) => {
    let color = fixation?.color;
    let fontSize = fixation?.font;
    let highlightedText;
    let coloredHtmlString;
    if (fixation?.bionicSelect === "bold") {
      highlightedText = textVide(data, {
        fixationPoint: fixation?.fixation,
        sep: ["<b>", "</b>"],
      });
      coloredHtmlString = highlightedText.replace(
        /<b>(.*?)<\/b>/g,
        `<b style="color: ${color} !important; font-size: ${fontSize}px !important">$1</b>`
      );
    } else {
      highlightedText = textVide(data, {
        fixationPoint: fixation?.fixation,
        sep: ["<u>", "</u>"],
      });
      coloredHtmlString = highlightedText.replace(
        /<u>(.*?)<\/u>/g,
        `<u style="color: ${color} !important; font-size: ${fontSize}px !important">$1</u>`
      );
    }

    // Replace the <b> tags with the colored version
    // let coloredHtmlString = highlightedText.replace(/<b>(.*?)<\/b>/g, `<b style="color: ${color} !important; font-size: ${fontSize}px !important">$1</b>`);
    const htmlContent = `<span>${coloredHtmlString}</span>`;
    return <div dangerouslySetInnerHTML={{ __html: htmlContent }} />;
  };
  const getfullBionicDescription = (string) => {
    if (string && bionic) {
      return NormalFormat(convertHtmlToText(string), fixation);
    } else {
      if (string) {
        return <Typography dangerouslySetInnerHTML={{ __html: string }} />;
      }
    }
  };
  const returnbionic = (string) => {
    if (bionic && string) {
      if (string?.length > 200) {
        return NormalFormat(
          `${convertHtmlToText(string).substring(0, 200)}...`,
          fixation
        );
      } else {
        return NormalFormat(
          convertHtmlToText(string).substring(0, 200),
          fixation
        );
      }
    } else {
      // return string;
      if (string) {
        if (string?.length > 200) {
          return `${convertHtmlToText(string).substring(0, 200)}...`;
        } else {
          return convertHtmlToText(string).substring(0, 200);
        }
      }
    }
  };
  const handleOpenReferee = (id) => {
      setOpenReferCandidate(true);
  };
  return (
    <>
      <Drawer
        disableEnforceFocus
        transitionDuration={{ enter: 500, exit: 500 }}
        variant="temporary"
        anchor="right"
        open={props.viewJobDetailDrawer}
        onClose={(e) => {
          e.stopPropagation();
          props.setViewJobDetailDrawer(false);
        }}
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="jobDetail-drawer"
      >
        <Grid className="p-30 drawer-align">
          {isLoading && <Loader />}
          {isLoadingDrawer ? (
          <Typography className="display-flex align-center mt-30">
            <div className="lazy-loader2">
              <div className="shimmerBG media"></div>
              <div className="p-32">
                <div className="shimmerBG title-line"></div>
                <div className="shimmerBG title-line end"></div>
                <div className="shimmerBG content-line m-t-24"></div>
                <div className="shimmerBG content-line"></div>
                <div className="shimmerBG content-line"></div>
                <div className="shimmerBG content-line"></div>
                <div className="shimmerBG content-line end"></div>
              </div>
            </div>
          </Typography>
          ) :
          <>
          <Grid className="d-flex align-center mb-20">
            <Typography
              onClick={(e) => {
                e.stopPropagation();
                props.setViewJobDetailDrawer(false);
              }}
              className="d-flex align-center cursor-hover"
              id="drawer-close-icon"
            >
              <svg
                width="18px"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="#808191"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15.75 19.5L8.25 12l7.5-7.5"
                />
              </svg>
            </Typography>
            <Typography className="heading-text-color f-20 font-weight-600 ml-10">
              Job Details
            </Typography>
            <Typography className="pl-15">
              {JD?.jobStatus === "O" &&
                additionalDetails.endDate &&
                formatDate(
                  new Date(
                    JSON.parse(
                      JSON.stringify(additionalDetails.endDate)?.replace(
                        "23:59:59",
                        "00:00:00"
                      )
                    )
                  )
                ) < formatDate(date) && (
                  <Tooltip
                    title="Refresh the job to update status"
                    position="bottom"
                    trigger="mouseenter"
                    animation="fade"
                    arrow
                  >
                    <span className="f-14 font-weight-500 declineStatus cursor-default">
                      Expired
                    </span>
                  </Tooltip>
                )}
              {JD?.jobStatus === "O" &&
              additionalDetails.endDate &&
              formatDate(
                new Date(
                  JSON.parse(
                    JSON.stringify(additionalDetails.endDate)?.replace(
                      "23:59:59",
                      "00:00:00"
                    )
                  )
                )
              ) < formatDate(date) ? (
                ""
              ) : JD?.aboutToExpire ? (
                <Typography className="f-14 font-weight-500 expiryStatus posted-date">
                  {JD?.aboutToExpire}
                </Typography>
              ) : (
                ""
              )}
            </Typography>
          </Grid>
          <Grid className="d-flex justify-between mt-30 galaxy-block file-upload">
            <Grid>
              <Typography className="main-text-color f-20 font-weight-600">
                {JD ? JD.title : "-"}
              </Typography>
              <Typography className={`d-content align-center galaxy-block ${props.isFromInterviews ? "mt-5" : "mt-10"}`}>
                {(additionalDetails?.country || additionalDetails?.city || additionalDetails?.state) && (
                  <Typography className="gray7 f-14 font-weight-400 mr-10 d-inline-flex align-center">
                    <Box className="d-flex align-center mr-5 gray7">
                      <LocationIcon width="15px" />
                    </Box>
                    {/* {additionalDetails.city?.trim()}
                    {additionalDetails?.city?.trim()
                      ? ", "
                      : ""}
                    {additionalDetails.state?.trim()}
                    {additionalDetails?.state?.trim() &&
                    additionalDetails?.country?.trim()
                      ? ", "
                      : ""}
                    {additionalDetails.country} */}
                    <Box className="d-flex align-center gray7 f-14">
                      {getLocation(additionalDetails)}
                    </Box>
                    {/* {additionalDetails.state
                    ? additionalDetails.city +
                    ", " +
                    // additionalDetails.state +
                    // ", " +
                    additionalDetails.country
                    : "-"} */}
                  </Typography>
                )}
                <Typography className="gray7 font-weight-400 f-14 mr-10 align-center d-inline-flex">
                  <Box className="d-flex align-center mr-5 gray7">
                    <FullTimeIcon width="15px" />
                  </Box>
                  <Box className="d-flex align-center gray7 f-14">
                    {getJobType(false)}
                  </Box>
                  {JD?.jobType?.length > 1 && (
                    <Typography
                      className="ml-5 link-color cursor-hover text-decoration-none d-inline-flex f-12 font-weight-600"
                      onClick={(e) => handleOpenJobType(e)}
                      tabIndex={0}
                      onKeyPress={(e) => handleOpenJobType(e)}
                    >
                      {`+${JD?.jobType?.length - 1} more`}
                    </Typography>
                  )}
                </Typography>
                {(JD?.jobReferenceNumber) && (
                  <Typography className="mr-10 d-inline-flex">
                  <Box className="f-14 gray7 font-weight-400 d-flex align-center">
                    <Box className="d-flex align-center mr-5 gray7">
                      <JobReferenceNumberIcon width="15px" className="svg-icons-gray7" />
                    </Box>
                    <Box className="d-flex align-center gray7 f-14 display-flex align-items-center">
                      Job Reference #: {JD?.jobReferenceNumber}
                      <Link
                        onClick={(e) => copyToClipboard(e, JD?.jobReferenceNumber)}
                        onKeyPress={(e) => copyToClipboard(e, JD?.jobReferenceNumber)}
                        className="cursor-hover display-flex ml-3"
                        tabIndex={0}
                      >
                      <ContentCopyIcon
                        style={{ width: "14px", margin: "0" }}
                        className="gray7"
                      />
                      </Link>
                    </Box>
                  </Box>
                </Typography>
                )}
                {(JD?.externalJobId) && (
                  <Typography className="d-inline-flex">
                  <Box className="f-14 gray7 font-weight-400 d-flex align-center">
                    <Box className="d-flex align-center mr-5 gray7">
                      <ExternalJobIdIcon width="15px" className="svg-icons-gray7" />
                    </Box>
                    <Box className="d-flex align-center gray7 f-14">
                      External Job ID: {JD?.externalJobId}
                    </Box>
                  </Box>
                </Typography>
                )}
              </Typography>
              {((JD?.minBudget && JD?.minBudget != 0) || (JD?.maxBudget && JD?.maxBudget != 0)) ?
              <Typography className={`gray9 f-20 font-weight-600 ${props.isFromInterviews ? "mt-15" : "mt-25"}`}>
                {JD ? `${JD.currency} ${JD.minBudget ? JD.minBudget : (JD.minBudget === null || JD.minBudget === "" ? "" : 0)}` : "-"}
                {JD?.minBudget !== null && JD?.minBudget !== "" && JD?.maxBudget !== null && JD?.maxBudget !== "" ? " - " : ""}
                {JD ? `${JD.maxBudget ? JD.maxBudget : ""}` : "-"} / {getPaymentTerm(paymentTerm)}
              </Typography> : <span></span>}
              <Typography className="">
                {skills
                  ? skills.map((skill, index) => {
                      return index < 6 ? (
                        (skill.skills.keyword?.toLowerCase() != "english") ?
                        <span className={`skills ${props.isFromInterviews ? "mt-20" : "mt-30"}`} key={index}>
                          {skill.skills.keyword}
                        </span> : ""
                      ) : null;
                    })
                  : "-"}
                {skills?.length > 6 && (
                  <span
                    tabIndex={0}
                    onKeyPress={(e) => handleViewAllSkills(e)}
                    className="link-color cursor-hover text-decoration-none d-block f-12 font-weight-600 p-l-5"
                    onClick={(e) => handleViewAllSkills(e)}
                  >
                    {`+${skills?.length - 6} more`}
                  </span>
                )}
              </Typography>
            </Grid>
            <Grid className="gal-slot-align">
            <Grid className="d-flex justify-end">
              {additionalDetails?.status === "O" && additionalDetails.endDate &&
                formatDate(
                  new Date(
                    JSON.parse(
                      JSON.stringify(additionalDetails.endDate)?.replace(
                        "23:59:59",
                        "00:00:00"
                      )
                    )
                  )
                ) >= formatDate(date) && (
                  <>
                  <Typography className="job-share p-t-5 p-b-5 cursor-hover">
                    <ShareJob row={JD} type="share-link"/>
                  </Typography>
                  <Box
                        className="ml-10 job-share cursor-hover d-flex align-center"
                        onClick={() => handleOpenReferee(additionalDetails)}
                      >
                        <div className="shareJob cursor-hover">
                          <ReferCandidate
                            width="20px"
                            height="20"
                            className="svg-icons"
                          />
                          </div>
                      </Box>
                  </>
                )}
              {!props.isFromInterviews &&
              <Grid className="job-menu">
                <JobMenu
                  job={fulljobdetails && fulljobdetails}
                  index={0}
                  setRefreshJobHeader={props.setRefreshJobHeader}
                  // refreshTime={refreshTime}
                  // jobDropped={() => dropJob(0)}
                />
              </Grid>
              }
            </Grid>
            <Typography className={`job-share cursor-pointer mr-10 p-t-5 p-b-5 cursor-hover ${props.isFromInterviews ? "pt-10" : "pt-5"}`}>
            {!props.isFromInterviews && additionalDetails?.status === "O" && additionalDetails.endDate &&
                formatDate(
                  new Date(
                    JSON.parse(
                      JSON.stringify(additionalDetails.endDate)?.replace(
                        "23:59:59",
                        "00:00:00"
                      )
                    )
                  )
                ) >= formatDate(date) ?
                <ShareJob row={JD} type="QRCode"/> : ""}
              </Typography>
            </Grid>
          </Grid>
          <Grid className={`${props.isFromInterviews ? "mt-30" : ""}`}>
            <Typography className="gray7 f-14 font-weight-400">
              Job Description
            </Typography>
            <Typography className="mt-5 font-weight-400 f-16 gray9">
              {showMore || JD.JD?.length <= 350 ? (
                <span
                  className="gray9 f-16 font-weight-400 description-preview"
                  style={{ whiteSpace: "initial" }}
                  // dangerouslySetInnerHTML={{
                  //   __html: JD.JD && JD.JD[8] ? JD.JD : "-",
                  // }}
                >{JD.JD && JD.JD[8] ? getfullBionicDescription(JD.JD) : "-"}</span>
              ) : (
                bionic ?
                <>
                <span
                  className="gray9 f-16 font-weight-400 description-preview"
                  style={{ whiteSpace: "initial" }}
                  // dangerouslySetInnerHTML={{
                  //   __html:
                  //     JD.JD && JD.JD[8] ? JD.JD.substr(0, 350) + "..." : "-",
                  // }}
                >{JD.JD && JD.JD[8] ? returnbionic(JD?.JD) : "-"}</span>
                </>:
                <span
                  className="gray9 f-16 font-weight-400 description-preview"
                  style={{ whiteSpace: "initial" }}
                  dangerouslySetInnerHTML={{
                    __html:
                      JD.JD && JD.JD[8] ? JD.JD.substr(0, 350) + "..." : "-",
                  }}
                />
              )}
              {(JD.JD ? JD.JD.length : 0) > 200 && (
                <span
                  tabIndex={0}
                  onKeyPress={() => setShowMore(!showMore)}
                  className="link-color cursor-hover text-decoration-none d-content f-14 font-weight-600"
                  onClick={() => setShowMore(!showMore)}
                >
                  {" "}
                  {showMore ? "read less" : "read more"}
                </span>
              )}
            </Typography>
          </Grid>
          <Grid item lg={12} md={12} xs={12} className="word-break pt-15">
            <Typography className="f-14 gray7 font-weight-400">
              Education
            </Typography>
            {!educationList.length ? (
              <Typography>-</Typography>
            ) : (
              <>
                {educationList.length && !showEducations ? (
                  <>
                    <Typography className="educationBullet">
                      <FiberManualRecordIcon aria-label="Dot icon" />
                      {educationList[0]?.levelOfEducation == "S"
                        ? "School"
                        : educationList[0]?.levelOfEducation == "D"
                        ? "Diploma"
                        : educationList[0]?.levelOfEducation == "B"
                        ? "Bachelors"
                        : educationList[0]?.levelOfEducation == "M"
                        ? "Masters"
                        : "Doctorate"}{" "}
                      {educationList[0]?.nameOfDegree
                        ? `- ${educationList[0]?.nameOfDegree}`
                        : ""}
                    </Typography>

                    {educationList.length > 1 && (
                      <Typography Typography className="educationBullet">
                        <FiberManualRecordIcon aria-label="Dot icon" />
                        {educationList[1]?.levelOfEducation == "S"
                          ? "School"
                          : educationList[1]?.levelOfEducation == "D"
                          ? "Diploma"
                          : educationList[1]?.levelOfEducation == "B"
                          ? "Bachelors"
                          : educationList[1]?.levelOfEducation == "M"
                          ? "Masters"
                          : "Doctorate"}{" "}
                        {!educationList[1]?.nameOfDegree
                          ? ""
                          : educationList.length < 3 ||
                            educationList[1]?.nameOfDegree.length < 50
                          ? `- ${educationList[1]?.nameOfDegree}`
                          : `- ${educationList[1]?.nameOfDegree.substr(
                              0,
                              50
                            )}...`}
                        {educationList.length > 2 && !showEducations && (
                          <span
                            tabIndex={0}
                            onKeyPress={(e) =>
                              setShowEducations(!showEducations)
                            }
                            className="link-color cursor-hover text-decoration-none f-12 font-weight-600 p-l-5"
                            onClick={(e) => setShowEducations(!showEducations)}
                          >
                            {` view more`}
                          </span>
                        )}
                      </Typography>
                    )}
                  </>
                ) : (
                  educationList.map((val, index) => {
                    return (
                      <Typography key={index} className="educationBullet">
                        <FiberManualRecordIcon aria-label="Dot icon" />
                        {val?.levelOfEducation == "S"
                          ? "School"
                          : val?.levelOfEducation == "D"
                          ? "Diploma"
                          : val?.levelOfEducation == "B"
                          ? "Bachelors"
                          : val?.levelOfEducation == "M"
                          ? "Masters"
                          : "Doctorate"}{" "}
                        {val.nameOfDegree ? `- ${val.nameOfDegree}` : ""}
                      </Typography>
                    );
                  })
                )}
                {educationList.length > 2 && showEducations && (
                  <span
                    tabIndex={0}
                    onKeyPress={(e) => setShowEducations(!showEducations)}
                    className="link-color cursor-hover text-decoration-none d-block f-12 font-weight-600"
                    onClick={(e) => setShowEducations(!showEducations)}
                  >
                    {`view less`}
                  </span>
                )}
              </>
            )}
          </Grid>
          <Grid container spacing={1} lg={12} md={12} xs={12} className="mt-5">
            {appOrigin === "H" && (
              <Grid item lg={6} md={6} xs={12} className="mt-15">
                <Typography className="gray7 f-14 font-weight-400">
                  Discipline
                </Typography>
                <Typography className="gray9 f-16 font-weight-400">
                  {discipline ? discipline : "-"}
                </Typography>
              </Grid>
            )}
            <Grid item lg={6} md={6} xs={12} className="mt-15">
              <Typography className="gray7 f-14 font-weight-400">
                Workplace
              </Typography>
              <Typography className="gray9 f-16 font-weight-400">
                {additionalDetails.workFrom
                  ? getWorkType(additionalDetails.workFrom)
                  : "-"}
              </Typography>
            </Grid>
            <Grid item lg={6} md={6} xs={12} className="mt-15">
              <Typography className="gray7 f-14 font-weight-400">
                Experience
              </Typography>
              <Typography className="d-flex align-center f-16 gray9">
                <Typography className="gray9 f-16 font-weight-400">
                  {additionalDetails?.minExp !== null
                    ? String(additionalDetails.minExp)
                    : "-"}
                </Typography>
                    {additionalDetails?.maxExp !== null && (
                      <>
                <span>-</span>
                <Typography className="gray9 f-16 font-weight-400">
                          {(additionalDetails.maxExp !== "undefined" && additionalDetails.maxExp !== "undefined")
                            && String(additionalDetails.maxExp)}
                </Typography>
                      </>
                    )}
                <span>&nbsp;Year(s)</span>
              </Typography>
            </Grid>
            <Grid item lg={6} md={6} xs={12} className="mt-15">
              <Typography className="gray7 f-14 font-weight-400">
                Number of Openings
              </Typography>
              <Typography className="gray9 f-16">
                {additionalDetails.openings ? additionalDetails.openings : "-"}
              </Typography>
            </Grid>
            <Grid item lg={6} md={6} xs={12} className="mt-15">
              <Typography className="gray7 f-14 font-weight-400">
                Job Experience Level
              </Typography>
              <Typography className="gray9 f-16 font-weight-400">
                {additionalDetails.jobExperienceLevel
                  ? getJobExpLevel(additionalDetails.jobExperienceLevel)
                  : "-"}
              </Typography>
            </Grid>
            <Grid item lg={6} md={6} xs={12} className="mt-15">
              <Typography className="gray7 f-14 font-weight-400">
                Start Date
              </Typography>
              <Typography className="gray9 f-16">
                {additionalDetails.startDate
                  ? moment(additionalDetails.startDate).format("MMM DD, YYYY")
                  : "-"}
              </Typography>
            </Grid>
            <Grid item lg={6} md={6} xs={12} className="mt-15">
              <Typography className="gray7 f-14 font-weight-400">
                End Date
              </Typography>
              <Typography className="gray9 f-16">
                {additionalDetails.endDate
                  ? moment(additionalDetails.endDate).format("MMM DD, YYYY")
                  : "-"}
              </Typography>
            </Grid>
            <Grid item lg={6} md={6} xs={12} className="mt-15">
              <Typography className="gray7 f-14 font-weight-400">
                Duration
              </Typography>
              <Typography className="gray9 f-16">
                {additionalDetails?.duration
                  ? `${getDurationValue(additionalDetails?.duration)} ${getDurationPeriod(additionalDetails?.duration)}`
                  : "-"}
              </Typography>
            </Grid>
            <Grid item lg={6} md={6} xs={12} className="mt-15">
              <Typography className="gray7 f-14 font-weight-400">
                Benefits
              </Typography>
              <Typography className="gray9 f-16">
                {additionalDetails.benefits ? additionalDetails.benefits : "-"}
              </Typography>
            </Grid>
            <Grid item lg={6} md={6} xs={12} className="mt-15">
              <Typography className="gray7 f-14 font-weight-400">
                Job Category
              </Typography>
              <Typography className="gray9 f-16">
                {additionalDetails.jobCategory
                  ? additionalDetails.jobCategory
                  : "-"}
              </Typography>
            </Grid>
            <Grid item lg={6} md={6} xs={12} className="mt-15">
              <Typography className="gray7 f-14 font-weight-400">
                Job Status
              </Typography>
              <Typography className="gray9 f-16">
                {additionalDetails.status
                  ? additionalDetails.status == "O" ? "Open" : additionalDetails.status == "H" ? "Hold" :
                  additionalDetails.status == "P" ? "Pending" : additionalDetails.status == "C" ? "Closed" :
                  additionalDetails.status == "D" ? "Dropped / Cancelled" : "-"
                  : "-"}
              </Typography>
            </Grid>
            {appOrigin === "H" && (
              <Grid item lg={6} md={6} xs={6} className="mt-15">
                <Typography className="f-14 gray7 font-weight-400">
                  Shift Timings
                </Typography>
                <Typography className="f-16 gray9 font-weight-400">
                  {additionalDetails?.shift
                    ? getFormattedShift(additionalDetails?.shift)
                    : "-"}
                </Typography>
              </Grid>
            )}
          </Grid>
          {additionalDetails?.allowedToWorkInOtherJobs === "N" && (
            <Grid
            item
            lg={12}
            md={12}
            xs={12}
            className="mt-15 galaxy-padding-top-0"
          >
            <Typography className="f-14 gray7 font-weight-400">
            Offer Condition
            </Typography>
            <Typography className="f-16 gray9 font-weight-400">
              Full Time Applicants Only
            </Typography>
          </Grid>
          )}
          {additionalDetails?.socialMediaUrl && (
            <Grid
              item
              lg={12}
              md={12}
              xs={12}
              className="mt-15 galaxy-padding-top-0"
            >
              <Typography className="f-14 gray7 font-weight-400 mb-5">
                Know More
              </Typography>
              {additionalDetails?.socialMediaUrl?.match(urlvalidation) !=
                null &&
              (additionalDetails?.socialMediaUrl?.match(videoLinkRegex) !=
                null ||
                additionalDetails?.socialMediaUrl?.match(youtubeRegex) !=
                  null) ? (
                <>
                  {/.MP4|.WebM|.OGG|.mp4|.webm|.ogg/.test(
                    additionalDetails?.socialMediaUrl
                  ) ? (
                    <video
                      src={additionalDetails?.socialMediaUrl}
                      controls
                      height="250"
                      width="100%"
                      className="video-image"
                    />
                  ) : additionalDetails?.socialMediaUrl?.includes(
                      "videos.kaltura.com"
                    ) ? (
                    <iframe
                      src={additionalDetails?.socialMediaUrl}
                      width="100%"
                      height="250px"
                      allow="fullscreen; picture-in-picture"
                    ></iframe>
                  ) : (
                    <ReactPlayer
                      controls={true}
                      loop="1"
                      width="100%"
                      height="250px"
                      url={additionalDetails?.socialMediaUrl}
                    />
                  )}
                </>
              ) : /\.(gif|jpg|jpeg|png)$/i.test(
                  additionalDetails?.socialMediaUrl
                ) ? (
                <a
                  href={additionalDetails?.socialMediaUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    width="50%"
                    src={additionalDetails?.socialMediaUrl}
                  ></img>
                </a>
              ) : (
                <span className="f-16 font-weight-400 description-preview">
                  <a
                    href={additionalDetails?.socialMediaUrl}
                    className="link-color text-decoration-none decorationcolor"
                    target="_blank"
                    rel="noreferrer"
                    style={{ whiteSpace: "initial", wordBreak: "break-all" }}
                  >
                    {additionalDetails?.socialMediaUrl}
                  </a>
                </span>
              )}
            </Grid>
          )}
          {((additionalDetails?.questions &&
            additionalDetails?.questions.length) ||
            (location.state?.isFromJobListing &&
              location.state?.questions)) && (
            <Grid item lg={12} md={12} xs={12} className=" mt-10 file-upload">
              <Grid item lg={12} md={12} xs={12}>
                <Typography className="pb-30">
                  <span className="f-14 gray9">
                    To view screening questions,{" "}
                  </span>
                  <span
                    tabIndex={0}
                    onKeyPress={assessmentPreview}
                    className="f-14 link-color font-weight-600 cursor-hover"
                    onClick={assessmentPreview}
                  >
                    click here
                  </span>
                </Typography>
              </Grid>
            </Grid>
          )}
          </>}
          {/* </Grid> */}
          {/* </Grid> */}
          <Popover
            id="simple-popover-list"
            open={openJobType}
            anchorEl={anchorEl2}
            onClose={handleCloseJobType}
            anchorOrigin={{
              vertical: "center",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "center",
              horizontal: "right",
            }}
          >
            <Grid item sx={{ p: 1 }}>
              <Box>
                <Grid item xs={12}>
                  <Typography
                    id="modal-title"
                    className="d-flex align-between p-10"
                  >
                    <Typography variant="h5" className="f-16 font-weight-600 heading-text-color">
                      Work Type
                    </Typography>
                    <Typography
                      align="right"
                      className="cursor-hover sub-text-color icon-button"
                    >
                      <CloseIcon
                        onClick={handleCloseJobType}
                        onKeyPress={handleCloseJobType}
                        aria-label="Close icon"
                        tabIndex={0}
                      />
                    </Typography>
                  </Typography>
                </Grid>
              </Box>
              <Grid
                item
                className="pl-10 pr-10 pb-10"
                style={{
                  maxHeight: "250px",
                  overflow: "auto",
                  maxWidth: "300px",
                }}
              >
                <Box className="font-family">{getJobType(true)}</Box>
              </Grid>
            </Grid>
          </Popover>
          <Popover
            id="simple-popover-list"
            open={open1}
            anchorEl={anchorEl}
            onClose={handleClosePopover}
            anchorOrigin={{
              vertical: "center",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "center",
              horizontal: "right",
            }}
          >
            <Grid item sx={{ p: 1 }}>
              <Box className="align-between m-b-5">
                <Grid item xs={12}>
                  <Typography
                    id="modal-title"
                    className="d-flex align-between p-10"
                  >
                    <Typography variant="h5" className="f-16 font-weight-600 heading-text-color">
                      {appOrigin === "H" ? "Specialization" : "Skills"}
                    </Typography>
                    <Typography
                      align="right"
                      className="cursor-hover gray7 icon-button"
                    >
                      <CloseIcon
                        onClick={handleClosePopover}
                        onKeyPress={handleClosePopover}
                        aria-label="Close icon"
                        tabIndex={0}
                      />
                    </Typography>
                  </Typography>
                </Grid>
              </Box>
              <Grid
                item
                className="p-10"
                style={{
                  maxHeight: "250px",
                  overflow: "auto",
                  maxWidth: "300px",
                }}
              >
                <Box>
                  {skills?.map((skill, index) => {
                    return (
                      (skill.skills.keyword?.toLowerCase() != "english") ?
                      <span key={index} className="skills">
                        {skill.skills.keyword}
                      </span> : ""
                    );
                  })}
                </Box>
              </Grid>
            </Grid>
          </Popover>
        </Grid>
      </Drawer>
      <RefereeDetails
        open={openReferCandidate}
        setOpen={setOpenReferCandidate}
        jobId={JD.jobId}
        setIsLoading={props.setIsLoading1}
      />
    </>
  );
}
