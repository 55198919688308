// import PlacesAutocomplete, { geocodeByAddress, getLatLng, geocodeByPlaceId }  from "react-places-autocomplete";
import React from "react";
import { Grid, TextField, Box, Typography, Autocomplete } from "@mui/material";
import ApiCalls from "../api/customAxios";

class AddressShortenSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      address: props.address ? props.address : "",
      isDirty: false,
      openLocationsAutoComplete: false,
      locationsAutoFill: [],
      clear: false,
    };
    document.addEventListener("clearLocation", () => {
      if (this.props.address) {
        this.props.handleAddressSelect({
          //here
          address: "",
          // city: "",
          // state: "",
          // country: "",
        });
        this.setState({ address: "" });
      }
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.address !== this.props.address) {
      if (this.props.address) {
        this.props.handleAddressSelect({
          address: this.props.address,
          // city: "",
          // state: "",
          // country: "",
        });
        this.setState({ address: this.props.address });
      }
    }
  }
  handleChange = (address) => {
    if (address === "") {
      this.props.handleAddressSelect({
        //here
        address: "",
        // city: "",
        // state: "",
        // country: "",
      });
    }
    this.setState({ address });
    this.setState({ isDirty: true });
  };
  //get the split array and set last three to repective
  // handleSelect = (location) => {
  //     // console.log(location);
  //     const locationList = location.split(",");
  //     let locationDetails = {
  //         address: "",
  //         city: "",
  //         state: "",
  //         country: "",
  //     };
  //     let holder = "";
  //     if (locationList.length) {
  //         locationDetails["country"] = locationList[locationList.length - 1];
  //         locationList.pop();
  //     }
  //     if (locationList.length) {
  //         locationDetails["state"] = locationList[locationList.length - 1];
  //         locationList.pop();
  //     }
  //     if (locationList.length) {
  //         locationDetails["city"] = locationList[locationList.length - 1];
  //         locationList.pop();
  //     }
  //     if (locationList.length) {
  //         locationList.forEach((i) => {
  //             holder += i + ",";
  //         });
  //         locationDetails["address"] = holder;
  //     }
  //     // console.log(locationDetails);
  //     if (locationDetails["country"]) {
  //         this.props.handleAddressSelect(locationDetails);
  //     };
  //     this.setState({ address: holder }); //make change add cond
  //     this.setState({ isDirty: false });
  // };

  setLocationAddress = (location) => {
    let totalAddress = [];
    let adr = location && location["address"];
    console.log(location, "/", adr);
    let locationDetails = {
      address: "",
      city: "",
      state: "",
      country: "",
      zipCode: "",
    };
    if (location?.format) {
      locationDetails["address"] = location.format;
    }
    for (let i = 0; i < adr.length; i++) {
      if (adr[i].types.includes("LOCALITY") || adr[i].types.includes("POSTAL_TOWN")) {
        locationDetails["city"] = adr[i].shortName.toString();
      } else if (adr[i].types[0] === "ADMINISTRATIVE_AREA_LEVEL_1") {
        locationDetails["state"] = adr[i].shortName.toString();
      } else if (adr[i].types[0] === "COUNTRY") {
        locationDetails["country"] = adr[i].shortName.toString();
      } else if (adr[i].types[0] == "POSTAL_CODE") {
        locationDetails["zipCode"] = adr[i].shortName.toString();
      }
    }
    if (locationDetails["country"]) {
      this.props.handleAddressSelect(locationDetails);
    }
    this.setState({ address: locationDetails.address }); //make change add cond
    this.setState({ isDirty: false });
  };

  nonAssisted = (event) => {
    this.setState({ address: event.target.value }); //make change add cond
    this.setState({ isDirty: true });
    const temp = {
      address: event.target.value,
    };
    this.props.handleAddressSelect(temp);
    if (event.target.value.length <= 1024) {
      this.getLocations(event);
    } else {
      this.setState({ openLocationsAutoComplete: false });
    }
  };

  // handleSelectCity = (address, placeId) => {
  //   let totalAddress = {};
  //   geocodeByAddress(address)
  //       .then((results) => {
  //           totalAddress["address"] = results[0].address_components;
  //           return getLatLng(results[0]);
  //       })
  //       .then((latLng) => {
  //           totalAddress["latlng"] = latLng;
  //           if (this.props.isLng) {
  //             this.props.onSelectlatLng(latLng);
  //           }
  //       })
  //       .then(() => {
  //           totalAddress["placeId"] = placeId;
  //           this.setLocationCity(totalAddress);
  //           return geocodeByPlaceId(placeId);
  //       })
  //       .catch((error) => console.error("Error", error));
  // };
  getLocations = async (event) => {
    let Url;
    if (this.props?.isAuthenticated) {
      Url =
        process.env.REACT_APP_API_URL.replace("api/v1/", "public/api/v1/") +
        "places/";
    } else {
      Url = process.env.REACT_APP_API_URL + "places/";
    }
    this.handleChange(event.target.value);
    if (!event.target.value) {
      this.setState({ locationsAutoFill: [] });
    } else if (event.target.value.length >= 1) {
      await ApiCalls.get(Url + event.target.value + "?type=A")
        .then((res) => {
          if (!res.length) {
            this.setState({ openLocationsAutoComplete: false });
          } else {
            this.setState({ locationsAutoFill: res });
          }
          // console.log(res);
        })
        .catch(function (error) {});
    }
  };
  handleLatLong = (data) => {
    if (this.props.handleLatLong) {
      this.props.handleLatLong(data);
    }
  }
  getAddressDetails = (address, placeId) => {
    let Url;
    if (this.props?.isAuthenticated) {
      Url =
        process.env.REACT_APP_API_URL.replace("api/v1/", "public/api/v1/") +
        "places/";
    } else {
      Url = process.env.REACT_APP_API_URL + "places/";
    }
    let totalAddress = {};
    if (address != undefined) {
      ApiCalls.get(Url + address + "?type=D")
        .then((results) => {
          totalAddress["address"] = results[0].addressComponents;
          totalAddress["latlng"] = results[0].geometry.location;
          totalAddress["placeId"] = placeId;
          totalAddress["format"] = results[0].formattedAddress;
          this.handleLatLong(results[0].geometry.location);
          this.setLocationAddress(totalAddress);
        })
        .catch((error) => {
          if (error?.statusCode != 500) {
            danger(<div>{error?.errorMessage}</div>);
          }
          this.setState({ address: "" });
        });
    }
  };

  render() {
    return (
      <>
        <Grid item lg={12} md={12} xs={12} className="city-placeholder">
          <Autocomplete
            open={this.state.openLocationsAutoComplete}
            onClose={() => this.setState({ openLocationsAutoComplete: false })}
            key={this.props.addLocation ? this.state.clear : ""}
            id="free-solo-demo"
            options={this.state.locationsAutoFill}
            popupIcon={""}
            className="test addree-profile"
            noOptionsText={<span className="gray9 f-14 font-weight-400">Please select from the list</span>}
            getOptionLabel={(option) =>
              option.description ? option.description : this.state.address
            }
            value={this.state.address ? this.state.address : null}
            isOptionEqualToValue={(option, value) =>
              option.description[0].trim() === value
            }
            onInputChange={(_, value) => {
              if (value.length === 0) {
                if (this.state.openLocationsAutoComplete) {
                  this.setState({ openLocationsAutoComplete: false });
                }
              } else {
                if (!this.state.openLocationsAutoComplete) {
                  this.setState({ openLocationsAutoComplete: true });
                }
              }
              if (value == this.state.address) {
                this.setState({ openLocationsAutoComplete: false });
              }
            }}
            clearIcon={<div style={{ cursor: "auto" }}></div>}
            renderInput={(params) => (
              <TextField
                {...params}
                type="text"
                className="form-control input-group inputcontrasts position-issue inputcontrasts edit-skill-align m-t-0 m-b-0 location-search-input citySection basicCityAlign address-input"
                margin="normal"
                placeholder={
                  this.props.placeholder
                    ? this.props.placeholder
                    : "Enter address"
                }
                onChange={this.nonAssisted}
              />
            )}
            onChange={(event, address) =>
              this.getAddressDetails(address?.description, address?.placeId)
            }
            ListboxProps={{ style: { maxHeight: "10rem" } }}
          />
        </Grid>
      </>
    );
  }
}

export default AddressShortenSearch;
