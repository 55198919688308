import ApiCalls from "../../api/customAxios";
class CandidateServiceClass {
  matchingCandidatesUrl = process.env.REACT_APP_API_URL + "enterprises/jobs/";
  candidatesByStatusUrl = process.env.REACT_APP_API_URL + "enterprises/pool/";
  checkPriorityUrl =
    process.env.REACT_APP_API_URL + "enterprises/talentPool/candidate";
  shortlistUrl = process.env.REACT_APP_API_URL + "enterprises/pool/shortlist";
  unshortlistUrl =
    process.env.REACT_APP_API_URL + "enterprises/pool/unshortlist";
  hideCandidateUrl = process.env.REACT_APP_API_URL + "enterprises/pool/hide";
  rejectApplicationUrl =
    process.env.REACT_APP_API_URL + "enterprises/pool/reject";
  rejectApplicationReasonUrl =
    process.env.REACT_APP_API_URL + "enterprises/rejection/reason";
  createOfferUrl = process.env.REACT_APP_API_URL + "enterprises/pool/offer";
  createOfferMultipleJobsUrl =
    process.env.REACT_APP_API_URL + "enterprises/pool/candidate/offer-jobs/";
  onboardUrl = process.env.REACT_APP_API_URL + "enterprises/pool/onboard";
  offboardUrl = process.env.REACT_APP_API_URL + "enterprises/pool/offboard";
  assessmentReponseUrl =
    process.env.REACT_APP_API_URL + "enterprises/jobs/assessment/response/";
  candidateTimeLineUrl =
    process.env.REACT_APP_API_URL + "enterprises/pool/candidate/timeline/";
  candidateDetailsUrl = process.env.REACT_APP_API_URL + "enterprises/pool/candidates/";
  allCandidatesUrl =process.env.REACT_APP_API_URL + "enterprises/allCandidates/";
  searchBarUrl =
    process.env.REACT_APP_API_URL.replace("api/v1/", "public/api/v1/") +
    "enterprises/";
  companiesUrl =
    process.env.REACT_APP_API_URL.replace("api/v1/", "public/api/v1/") +
    "enterprises";
  filterURL = process.env.REACT_APP_API_URL + "enterprises/jobs/";
  getProfileUrl =
    process.env.REACT_APP_API_URL.replace("api/v1/", "public/api/v1/") +
    "candidates/";
  getCandidateReviewUrl =
    process.env.REACT_APP_API_URL.replace("api/v1/", "public/api/v1/") +
    "enterprises/candidate-review/";
  downloadProfile =
    process.env.REACT_APP_API_URL.replace("api/v1/", "public/api/v1/") +
    "candidates/profile/pdf/";
  statusCapture =
    process.env.REACT_APP_API_URL + "enterprises/pool/candidates/status";
  locations = localStorage.getItem("token") ? process.env.REACT_APP_API_URL + "places/" :
    process.env.REACT_APP_API_URL.replace("api/v1/", "public/api/v1/") + "places/";
  University =
    process.env.REACT_APP_API_URL.replace("api/v1/", "public/api/v1/") +
    "enterprises/search?";
  myCandateList =
    process.env.REACT_APP_API_URL + "enterprises/user/my-candidates";
  cohortData =
    process.env.REACT_APP_API_URL +
    "enterprises/matching-candidates/cohort-analysis/";
  matchedJobsMatchingBreakdown =
    process.env.REACT_APP_API_URL +
    "enterprises/matching-candidates/breakdown/";
  reviewDetailsUrl =
    process.env.REACT_APP_API_URL +
    "enterprises/pool/candidate/offboarded-jobs/";
    candidateNotificationUrl = process.env.REACT_APP_API_URL + "enterprises/pool/";
    FullResumeParseUrl =  process.env.REACT_APP_API_URL.replace("api/v1/", "public/api/v1/") + "candidates/resume/parse/data";
    resumeParseUrl =  process.env.REACT_APP_API_URL + "enterprises/candidates/documents/parse-resume";
    skillsParseUrl =  process.env.REACT_APP_API_URL + "skills/parse";

    candidateEmailVerificationUrl = process.env.REACT_APP_API_URL + "enterprises/pool/candidates/exists";

  async getMatchingCandidates(
    job_id,
    query,
    sort = "1",
    page = 0
  ) {
    return ApiCalls.get(
      this.matchingCandidatesUrl +
        job_id +
        "/candidates/" +
        (page ? page : 0) +
        `/10?${
          sort === "1"
            ? "sortByDate=true&"
            : sort === "3"
            ? "sortByExperience=true&"
            : ""
        }` +
        query
    );
  }

  async getshortlistedORAppliedCandidates(job_id, query, processStatus, page) {
    return ApiCalls.get(
      this.matchingCandidatesUrl +
        job_id +
        "/candidates/" +
        (page ? page : 0) +
        `/10?processStatus=${
          processStatus
        }&` +
        query
    );
  }
  async parseResume(payload, resumeUrlData) {
    let url = resumeUrlData === "full" ? this.FullResumeParseUrl : this.resumeParseUrl;
    return ApiCalls.post(url, payload);
  }
  async skillsParseResume(skills) {
    return ApiCalls.put(this.skillsParseUrl, skills);
  }
  async candidateEmailVerificationMethod(email, phone) {
    return ApiCalls.get(`${this.candidateEmailVerificationUrl}?emailAddress=${email}&mobileNumber=${phone}`);
  }
  async createCandidate(payload) {
    return ApiCalls.post(this.candidateDetailsUrl, payload);
  }

  async getCandidatesByStatus(job_id, status, query, page = 0) {
    return ApiCalls.get(
      this.candidatesByStatusUrl +
        job_id +
        "/" +
        (status === "I" ? "interviews" : status) +
        "/" +
        (page ? page : 0) +
        "/10?" +
        query
    );
  }
  async getCandidatesFromNotification(job_id, status, candidateId, page = 0) {
    let url = this.candidateNotificationUrl +
    job_id +
    "/" +
    (status === "I" ? "interviews" : status) +
    "/" +
    (page ? page : 0)+"/10?";
    if (candidateId && candidateId != null) {
      url  = `${url}candidateId=${candidateId}`;
    }
    return ApiCalls.get(url);
    // return ApiCalls.get(
    //   this.candidateNotificationUrl +
    //     job_id +
    //     "/" +
    //     status +
    //     "/" +
    //     (page ? page : 0) +
    //     "/10?" +
    //     `candidateId=${candidateId}}`
    // );
  }

  async getCompletedJobRatings() {
    return ApiCalls.get(this.candidatesByStatusUrl + "pending");
  }

  async getCandidateDetails(Id) {
    // // token not needed here as it is a public endpoint
    return ApiCalls.get(this.candidateDetailsUrl + Id);
  }
  async getCandidateAllDetails(candidateId, jobID, isNotFromPool) {
    // // token not needed here as it is a public endpoint
    return ApiCalls.get(
      isNotFromPool
        ? this.candidateDetailsUrl + candidateId
        : `${this.candidatesByStatusUrl}${jobID}/${candidateId}`
    );
  }
  async getCandidateCohort(candidateId, jobId, candidate_pool_status) {
    return ApiCalls.get(
      this.cohortData +
        candidateId +
        `/${jobId}?candidate-pool-status=${candidate_pool_status}`
    );
  }
  async getReviewDetails(candidate_id, pgNo) {
    return ApiCalls.get(this.reviewDetailsUrl + `${candidate_id}/${pgNo}/10`);
  }
  async getMatchingbreakdownJobs(candiadte_id, jobId) {
    return ApiCalls.get(
      this.matchedJobsMatchingBreakdown + candiadte_id + `/${jobId}`
    );
  }

  async getReviewDetails(candidate_id, pgNo) {
    return ApiCalls.get(this.reviewDetailsUrl + `${candidate_id}/${pgNo}/10`);
  }

  async getAssessmentReponse(job_id, candidate_id) {
    return ApiCalls.get(
      this.assessmentReponseUrl + job_id + "/" + candidate_id
    );
  }
  async getTimeLine(candidate_id, pgNo, status) {
    return ApiCalls.get(
      this.candidateTimeLineUrl +
        candidate_id +
        `/${pgNo}/10` +
        `${status && `?status=${status}`}`
    );
  }
  async shortList(payload) {
    return ApiCalls.put(this.shortlistUrl, payload);
  }

  async hideCandidate(payload) {
    return ApiCalls.put(this.hideCandidateUrl, payload);
  }

  async unshortList(payload) {
    return ApiCalls.put(this.unshortlistUrl, payload);
  }

  async rejectApplication(payload) {
    return ApiCalls.put(this.rejectApplicationUrl, payload);
  }
  async rejectApplicationReason(payload) {
    return ApiCalls.put(this.rejectApplicationReasonUrl, payload);
  }
  async createOffer(payload, params) {
    return ApiCalls.put(`${this.createOfferUrl}?sendEmail=${params}`, payload);
  }
  async createOfferMultipleJobs(candidateId, payload) {
    return ApiCalls.put(this.createOfferMultipleJobsUrl + candidateId, payload);
  }

  async onBoard(payload) {
    return ApiCalls.put(this.onboardUrl, payload);
  }

  async Offboard(payload) {
    return ApiCalls.put(this.offboardUrl, payload);
  }

  async filterCandidates(jobId, query) {
    return ApiCalls.get(this.filterURL + jobId + "/candidates/0/10?" + query);
  }

  async allCandidates(query, pageNo, pageSize) {
    return ApiCalls.get(
      this.allCandidatesUrl + `${pageNo}/${pageSize}?` + query
    );
  }
  async getMyCandidateList(lead, payload) {
    return ApiCalls.put(this.myCandateList + `?leads=${lead}`, payload);
  }

  async searchBar(query) {
    return ApiCalls.get(this.searchBarUrl + `search?` + query);
  }

  async searchUniversities(query) {
    return ApiCalls.get(this.University + query);
  }
  async getCompanies(query) {
    return ApiCalls.get(this.companiesUrl + query);
  }

  async candidateJobs(candidateId) {
    return ApiCalls.get(`${this.filterURL}candidate/${candidateId}`);
  }

  async candidateMatchedJobs(candidateId, pageNo) {
    return ApiCalls.get(
      `${this.filterURL}candidates/${candidateId}/${pageNo}/10`
    );
  }
  async candidateRecommendedJobs(candidateId, pageNo, value, searchValue) {
    return ApiCalls.get(
      `${this.filterURL}candidates/${candidateId}/${pageNo}/10?allJobs=${value}&search=${searchValue ? searchValue : ""}`
    );
  }
  async candidateRecommendedJobsForRehire(candidateId, pageNo, value, searchValue) {
    return ApiCalls.get(
      `${this.createOfferMultipleJobsUrl}${candidateId}/${pageNo}/10?allJobs=${value}&search=${searchValue ? searchValue : ""}`
    );
  }
  async myCandidateMatchedJobs(candidateId, page, numberOfResults) {
    return ApiCalls.get(
      `${this.filterURL}candidates/${candidateId}/matched-jobs/${page}/${numberOfResults}?relevance=true`
    );
  }

  async shortListCandidate(candidateId, payload) {
    return ApiCalls.put(`${this.shortlistUrl}/${candidateId}`, payload);
  }

  async enterpriseAllJobs() {
    return ApiCalls.get(`${this.filterURL}/all/0/999`);
  }

  getProfile(candidateId) {
    return ApiCalls.get(this.getProfileUrl + candidateId);
  }

  async getCandidateReview(candidateId) {
    return ApiCalls.get(this.getCandidateReviewUrl + candidateId);
  }

  async getPdfs(id, payload) {
    return ApiCalls.post(this.downloadProfile + id, payload);
  }

  async getStatus(payload) {
    return ApiCalls.put(this.statusCapture, payload);
  }

  async getLocations(query) {
    return ApiCalls.get(this.locations + query);
  }

  async blukshortListCandidate(jobId, payload) {
    return ApiCalls.put(`${this.candidatesByStatusUrl}${jobId}/S`, payload);
  }

  async blukHideCandidate(jobId, payload) {
    return ApiCalls.put(`${this.candidatesByStatusUrl}${jobId}/H`, payload);
  }

  async candidateOfferJobs(candidateId, page) {
    return ApiCalls.get(
      `${this.candidatesByStatusUrl}candidate/offer-jobs/${candidateId}/${page}/10`
    );
  }
  async checkPriorityCandidate(candidateId) {
    return ApiCalls.get(`${this.checkPriorityUrl}/${candidateId}`);
  }
}

const CandidateService = new CandidateServiceClass();
export default CandidateService;
