import * as React from "react";
import copy from "copy-to-clipboard";
import {
  Typography,
  Grid,
  Box,
  InputAdornment,
  TextField,
  Button,
  Link,
  Popper,
  Stack,
  Grow,
  ClickAwayListener,
  Paper,
} from "@mui/material";
import {
  FacebookShareButton,
  WhatsappShareButton,
  LinkedinShareButton,
  FacebookIcon,
  WhatsappIcon,
  LinkedinIcon,
} from "react-share";
import { danger, success } from "../../../helpers/message-box";
import { useNavigate, useLocation } from "react-router-dom";
import facebookLogo from "../../../assets/images/Facebook.svg";
import whatsappLogo from "../../../assets/images/Whatsapp.svg";
import linkedInLogo from "../../../assets/images/Linkedin.svg";
// import { ReactComponent as whatsappLogos } from "../../../assets/images/Whatsapp.svg";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { ReactComponent as ShareIcon } from "../../../assets/images/Vector.svg";
import { ReactComponent as ShareActiveIcon } from "../../../assets/images/ShareJobActiveIcon.svg";
import copyicon from "../../../assets/images/Copyicon.svg";
import { Container } from "aws-amplify-react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import QRCode from "react-qr-code";
import { ReactComponent as DownloadIcon } from "../../../assets/images/resume-download-icon.svg";
import html2canvas from 'html2canvas';
import ApiCalls from "../../../api/customAxios";

export default function ShareJob(props) {
  const location = useLocation();
  const URLPath = location.pathname;
  let baseURL = process.env.REACT_APP_CANDIDATE_BASE_URL;
  let newUrl = baseURL;
  const anchor = React.useRef(null);
  const [openPoPover, setOpenPoPover] = useState(false);
  const [url, setURL] = useState("");
  const [title, setTitle] = useState("");
  const qrCodeRef = React.useRef(null);

  const prevOpen = React.useRef(openPoPover);
  React.useEffect(() => {
    if (prevOpen.current === true && openPoPover === false) {
      anchor.current.focus();
    }
    prevOpen.current = openPoPover;
  }, [openPoPover]);

  useEffect(() => {
    document.addEventListener("scroll", () => {
      setOpenPoPover(false);
    });
  }, []);

  const openPopovers = async(event) =>{
    event != undefined && event.stopPropagation();
    // e.preventDefault();
    // e.stopPropagation();
    // event != undefined && setOpenPoPover((prevOpen) => !prevOpen);
    let jobUrl = "";

    if (localStorage.getItem("token")) {
      await ApiCalls.get(
        "candidates/pool/getReferralUrl/" +
        (props?.row?.jobId ? props?.row.jobId : props?.row.jobId)
      )
        .then((resp) => {
          if (resp) {
            jobUrl = newUrl + "/" + resp + "?isFromShareJob=true";
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    // let jobUrl = `${newUrl}/${props?.row?.jobId}`;
    // setURL(jobUrl);
    // setTitle(props.row.title);
    event != undefined && setOpenPoPover((prevOpen) => !prevOpen);
    if (jobUrl == "") {
      jobUrl = `${newUrl}/job/${props?.row?.jobId}?isFromShareJob=true`;
    }
    setURL(jobUrl);
    setTitle(props?.row?.jobTitle);
  };
  const copyToClipboard = () => {
    setOpenPoPover(false);
    copy(url);
    success("Link is Copied.");
  };
  const handleClose = (event) => {
    if (anchor.current && anchor.current.contains(event.target)) {
      return;
    }
    setOpenPoPover(false);
  };
  const downloadQrCode = () => {
    if (qrCodeRef.current) {
      html2canvas(qrCodeRef.current, {
        scale: 3
      })
        .then((canvas) => {
          const link = document.createElement('a');
          link.href = canvas.toDataURL('image/png');
          link.download = `${props?.row?.jobId}.png`;
          link.click();
          setTimeout(() => {
            success("QR code downloaded successfully.");
          }, 100);
        })
        .catch((error) => {
          console.error('Error generating QR code image:', error);
        });
    }
    setOpenPoPover(false);
  };
  useEffect(() => {
    if ((props?.row?.jobId || props?.row?.jobId) && openPoPover) {
      openPopovers();
    }
  }, [props?.row]);

  const QrCodeBlock = (props) => {
    return (<Grid className={`${!props.popover ? "border-1 borderRadius-10 pl-25 pr-25" : "pl-15 pr-10 responsive-margin"}`}>
      <div className="border-1 borderRadius-10 mt-10 responsive-qr galaxy-margin-left-10 screening-del-align">
      <Box ref={qrCodeRef} className="text-center galaxy-margin-left-10" style={{ paddingTop: "7px", width: "130px" }}>
        <QRCode value={url}
          size={100}
          style={{ height: "110", maxWidth: "100%" }} />
      </Box>
      </div>
      <Stack direction="row" spacing={2} className="display-flex justify-center mb-10 mt-10">
        <Button className="btn secondary-button font-weight-400"
          disableRipple={true}
          style={{ padding: "5px 15px" }}
          startIcon={<DownloadIcon height="15" width="15" className="fill-svg-icons" />}
          onClick={() => downloadQrCode()}>
          Download
        </Button>
      </Stack>
    </Grid>);
  };

  return (
    props.type == "QRCode" ?
      <Grid>
        {url != "" && <QrCodeBlock popover={false} />}
      </Grid> :
      <>
        <Box className="share-job">
          <Button
            ref={anchor}
            onClick={openPopovers}
            onKeyPress={openPopovers}
            style={{ paddingtop: "3px" }}
            aria-label="Share icon"
            disableRipple="true"
            className="shareJob"
          >
            <span className="f-16 link-color d-flex">
              {URLPath === "/enterprise/jobList" ? (
                <ShareIcon
                  alt="share icon"
                  className="gray9 experience-cont svg-icons"
                />
              ) : (
                <ShareActiveIcon
                  alt="share icon"
                  className="gray9 experience-cont svg-icons"
                />
              )}
            </span>
          </Button>
        </Box>
        <Popper
          open={openPoPover}
          anchorEl={anchor.current}
          placement="bottom-end"
          style={{ width: props.type == "share-link" && "300px", zIndex: "9999" }}
          className="py-5 responsive-share-container share-button cand-list-align"
        >
          <ClickAwayListener onClickAway={handleClose}>
            <Paper className="py-5 share-jobAlign">
            {props.type != "share-link" ? <Grid
                  container spacing={1} lg={12} md={12} xs={12}
                  className="justify-between display-flex align-center share-align preview-space"
                >
                  <Grid item lg={5} md={5} xs={12} className="galaxy-padding-0">
                    <QrCodeBlock popover={true} />
                  </Grid>
                <Grid item lg={7} md={7} xs={12} className="pt-0">
                  <div className="responsive-share">
                    <Grid>
                    <Grid item lg={12} md={12} xs={12}>
                      <Typography className="gray9 add-checklist-align gal-slot-align screening-del-align">Copy Link</Typography>
                    </Grid>
                    <Grid item lg={12} md={12} xs={12} className="pr-10">
                      <Typography className="share-job-align">
                        <span>
                          <TextField
                            value={`${url.substring(0, 31)}...`}
                            // disabled={true}
                            variant="outlined"
                            margin="normal"
                            style={{
                              width: "100%",
                              margin: "0px",
                            }}
                            InputProps={{ readOnly: true }}
                            tabIndex={0}
                            className="inputcontrasts responsive-copy-link"
                          />
                        </span>
                        <span
                          className="responsive-share-icon"
                          style={{
                            marginTop: "10px",
                            position: "absolute",
                            marginLeft: "-18px",
                          }}
                        >
                          <Link
                            onClick={copyToClipboard}
                            onKeyPress={copyToClipboard}
                            className="cursor-hover"
                            tabIndex={0}
                          >
                            <ContentCopyIcon
                              style={{ width: "20px", margin: "0" }}
                              className="gray7"
                            />
                          </Link>
                        </span>
                      </Typography>
                    </Grid>
                    </Grid>
                    <Grid item lg={12} md={12} xs={12} className="mt-40 galaxy-margin-0 screening-del-align">
                      <Typography className="gray9">Share on</Typography>
                      <Grid
                        item
                        lg={12}
                        md={12}
                        xs={12}
                        className="d-flex justify-between pt-10 align-center"
                        style={{ marginBottom: '-8px' }}
                      >
                        {/* <Typography>
                    <img scr={whatsappLogo} style={{ width: "100%" }} />
                  </Typography> */}
                        <Grid item lg={4} md={4} xs={4}>
                          <span>
                            <FacebookShareButton size={32} url={url} tabIndex={0} className="d-flex align-center">
                              {/* <FacebookIcon size={32} round={true}/> */}
                              <img
                                src={facebookLogo}
                                width="25px"
                                height="25px"
                                alt="facebook"
                                tabIndex={0}
                                onClick={()=>setOpenPoPover(false)}
                              />
                            </FacebookShareButton>
                          </span>
                        </Grid>
                        <Grid item lg={4} md={4} xs={4}>
                          <span>
                            <LinkedinShareButton size={32} url={url} tabIndex={0} className="d-flex align-center">
                              {/* <img scr={linkedInLogo}/> */}
                              <img
                                src={linkedInLogo}
                                width="25px"
                                height="25px"
                                alt="linkedin"
                                tabIndex={0}
                                onClick={()=>setOpenPoPover(false)}
                              />
                              {/* <LinkedinIcon size={32} round={true}/> */}
                            </LinkedinShareButton>
                          </span>
                        </Grid>
                        <Grid item lg={4} md={4} xs={4}>
                          <span className="share_job_image">
                            <WhatsappShareButton
                              size={32}
                              url={url}
                              title={`Job Role - (${title})`}
                              separator=" Apply Now: "
                              className="d-flex align-center"
                            >
                              <img
                                src={whatsappLogo}
                                style={{ width: "30px" }}
                                alt="whatsapp logo"
                                tabIndex={0}
                                onClick={()=>setOpenPoPover(false)}
                              />
                              {/* <img scr={whatsappLogo}/> */}
                              {/* <whatsappLogos style={{ width: "20px" }}/> */}
                              {/* <WhatsappIcon size={32} round={true}/> */}
                            </WhatsappShareButton>
                          </span>
                        </Grid>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
                </Grid> :
              <Container className="py-5 share-align">
                {props.type != "share-link" && <QrCodeBlock popover={true} />}
                <div className="pl-20 pr-20">
                  <Grid item lg={12} md={12} xs={12} className="pt-10">
                    <Typography className="gray9 f-14">Copy Link</Typography>
                  </Grid>

                  <Grid item lg={12} md={12} xs={12} className="">
                    <Typography className="">
                      <span>
                        <TextField
                          value={`${url.substring(0, 31)}...`}
                          // disabled={true}
                          variant="outlined"
                          margin="normal"
                          style={{
                            width: "100%",
                            margin: "0px",
                          }}
                          InputProps={{ readOnly: true }}
                          tabIndex={0}
                          className="inputcontrasts responsive-copy-link share-copied"
                        />
                      </span>
                      <span
                        className="responsive-share-icon"
                        style={{
                          marginTop: "10px",
                          position: "absolute",
                          marginLeft: "-18px",
                        }}
                      >
                        <Link
                          onClick={copyToClipboard}
                          onKeyPress={copyToClipboard}
                          className="cursor-hover"
                          tabIndex={0}
                        >
                          <ContentCopyIcon
                            style={{ width: "20px", margin: "0" }}
                            className="gray7"
                          />
                        </Link>
                      </span>
                    </Typography>
                  </Grid>
                  <Grid item lg={12} md={12} xs={12} className="pb-10">
                    <Typography className="gray9 f-14">Share on</Typography>
                    <Grid
                      item
                      lg={12}
                      md={12}
                      xs={12}
                      className="d-flex justify-between pt-10"
                    >
                      {/* <Typography>
                    <img scr={whatsappLogo} style={{ width: "100%" }} />
                  </Typography> */}
                      <Grid item lg={4} md={4} xs={4}>
                        <span>
                          <FacebookShareButton size={32} url={url} tabIndex={0}>
                            {/* <FacebookIcon size={32} round={true}/> */}
                            <img
                              src={facebookLogo}
                              style={{ width: "25px" }}
                              alt="facebook"
                              tabIndex={0}
                              onClick={()=>setOpenPoPover(false)}
                            />
                          </FacebookShareButton>
                        </span>
                      </Grid>
                      <Grid item lg={4} md={4} xs={4}>
                        <span>
                          <LinkedinShareButton size={32} url={url} tabIndex={0}>
                            {/* <img scr={linkedInLogo}/> */}
                            <img
                              src={linkedInLogo}
                              style={{ width: "25px" }}
                              alt="linkedin"
                              tabIndex={0}
                              onClick={()=>setOpenPoPover(false)}
                            />
                            {/* <LinkedinIcon size={32} round={true}/> */}
                          </LinkedinShareButton>
                        </span>
                      </Grid>
                      <Grid item lg={4} md={4} xs={4}>
                        <span className="share_job_image">
                          <WhatsappShareButton
                            size={32}
                            url={url}
                            title={`Job Role - (${title})`}
                            separator=" Apply Now: "
                          >
                            <img
                              src={whatsappLogo}
                              style={{ width: "30px" }}
                              alt="whatsapp logo"
                              tabIndex={0}
                              onClick={()=>setOpenPoPover(false)}
                            />
                            {/* <img scr={whatsappLogo}/> */}
                            {/* <whatsappLogos style={{ width: "20px" }}/> */}
                            {/* <WhatsappIcon size={32} round={true}/> */}
                          </WhatsappShareButton>
                        </span>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              </Container>}
            </Paper>
          </ClickAwayListener>
        </Popper>
      </>
  );
}
