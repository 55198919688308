import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  Grid,
  Typography,
  Button,
  Container,
  Breadcrumbs,
  Box,
  Popover,
} from "@mui/material";
import Drawer from "@mui/material/Drawer";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useNavigate, useParams } from "react-router-dom";
import AccessService from "../../../Services/Enterprise/Access.service";
import { useSelector } from "react-redux";
import { Tooltip, TooltipTrigger, TooltipContent } from "react-tippy";
import AIGenerateOfferLetter from "../OfferLetter/AIGenerateOfferLetter.js";
import AiGenerateOfferLetterMatchingCandidate from "../OfferLetter/AiGenerateOfferLetterMatchingCandidate.js";


const OfferLetter = React.memo(function OfferLetter(props) {
  const appOrigin = useSelector((state) => state.location.origin);
  const [offerType, setOfferType] = useState("AI");
  useEffect(() => {
    const bodyElement = document.body;
    const htmlElement = document.documentElement;
    if (props.viewOfferDrawer) {
      bodyElement.style.overflow = "hidden";
      htmlElement.style.overflow = "hidden";
    } else {
      bodyElement.style.overflow = "";
      htmlElement.style.overflow = "";
    }
  }, [props.viewOfferDrawer]);
  return (
    <>
      <Drawer
        disableEnforceFocus
        transitionDuration={{ enter: 500, exit: 500 }}
        variant="temporary"
        anchor="right"
        open={props.viewOfferDrawer}
        onClose={(e) => {
          e.stopPropagation();
          props.setResendOfferHeading(false);
          props.setViewOfferDrawer(false);
          if (props.isDetailsPage) {
            // props.closeMatchingCandidateDrawer();
            props.setIsDrawerVisible();
          }
        }}
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="jobDetail-drawer"
      >
      <Grid sx={{ p: 5 }} className="drawer-align">
          <Grid className="display-flex align-center login-responsive">
              <Typography
                onClick={(e) => {
                  e.stopPropagation();
                  props.setViewOfferDrawer(false);
                  props?.handleClosePopover();
                  props.setResendOfferHeading(false);
                  if (props.isDetailsPage) {
            // props.closeMatchingCandidateDrawer();
            props.setIsDrawerVisible();
          }
                }}
                className="cursor-hover gray7 display-flex align-center apply-job-align"
              >
                <ChevronLeftIcon className="f-24" />
              </Typography>
              <Typography className="heading-text-color f-20 font-weight-600 ml-10">
              {props.resendOfferHeading ? "Resend Offer Letter" : "Generate Offer Letter"}
              </Typography>
          </Grid>
          <Grid className="mt-30">
{props.isFromMatchingCandidates ?
              <AiGenerateOfferLetterMatchingCandidate
                offerType={offerType}
                setOfferType={setOfferType}
                jobId={props.jobId}
                candidateApplicationData={props.candidateApplicationData}
                interviewDetails={props.interviewDetails}
                setViewOfferDrawer={props.setViewOfferDrawer}
                setIsLoading={props.setIsLoading}
                expiryDate={props.expiryDate}
                jobStartDate={props.jobStartDate}
                jobCompletionDate={props.jobCompletionDate}
                payRate={props.payRate}
                resendOfferHeading={props.resendOfferHeading}
                currency={props.currency}
                updatedstatus={props.updatedstatus}
                sendOfferEmailChecked={props.sendOfferEmailChecked}
                candidateCall={props.candidate}
                status={props.status}
                isFromShortlistCandidates={props?.isFromShortlistCandidates}
                handleClosePopover={props?.handleClosePopover}
                getCandidatesByStatus={props.getCandidatesByStatus}
isDetailsPage={props.isDetailsPage}
              /> :
            <AIGenerateOfferLetter
                offerType={offerType}
                setOfferType={setOfferType}
                generateOffer={props.generateOffer}
                interviewDetails={props.interviewDetails}
                setViewOfferDrawer={props.setViewOfferDrawer}
                setIsLoading={props.setIsLoading}
                interviewClouser={props.interviewClouser}
                setToUpdateDetails={props.setToUpdateDetails}
                toUpdateDetails={props.toUpdateDetails}
                resendOfferHeading={props.resendOfferHeading}
                sendOfferEmailChecked={props.sendOfferEmailChecked}
              />}
          </Grid>
      </Grid>
      </Drawer>
    </>
  );
});

export default OfferLetter;
