import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Stack,
  Typography,
  Modal,
  Link,
  Tooltip as TooltipData,
} from "@mui/material";
import React, { useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import CandidateProfileVideoPreview from "../../Components/shared/CandidateProfileVideoPreview";
import CandidateServiceClass from "../../Services/Enterprise/Candidate.service";
import Loader from "../../Views/Loader";
import { ReactComponent as DefaultLogo } from "../../assets/images/default-user.svg";
import { ReactComponent as LocationIcon } from "../../assets/images/location-new-icon-gray.svg";
import { ReactComponent as PriorityIcon } from "../../assets/images/priority-pool-icon.svg";
import { ReactComponent as PriorityIconGreen } from "../../assets/images/starRename.svg";
import { ReactComponent as PrivateIcon } from "../../assets/images/private-pool-icon.svg";
import noCandidate from "../../assets/images/NoJobsFound.svg";
import { checkIsImage } from "../../helpers/helper-data";
import * as Constants from "../../helpers/constants";
import { danger, success } from "../../helpers/message-box";
import playIcon from "../../assets/images/playIcon.svg";
import { ReactComponent as JobReferenceNumberIcon } from "../../assets/images/jobRefNumberIcon.svg";
import copy from "copy-to-clipboard";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import AccessService from "../../Services/Enterprise/Access.service";
import ApiCalls from "../../api/customAxios";
import ExclusiveWorkPermissionModal from "../shared/ExclusiveWorkPermissionModal";
import ShortlistCandidateDrawer from "../../../src/Views/EnterprisePortal/Project/ShortlistCandidateDrawer";

export default function ControlCheckboxGroup(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isMenuLoading, setIsMenuLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [pageNumber, setPageNumber] = React.useState(0);
  const [openshortlist, setShortlist] = useState(false);
  const handleOpenShortlist = () => {
    document.body.style.overflow = "hidden";
    document.documentElement.style.overflow = "hidden";
    setShortlist(true);
  };
  const handleCloseShortlist = () => {
    document.body.style.overflow = "";
    document.documentElement.style.overflow = "";
    setShortlist(false);
  };
  const [loadMore, setLoadMore] = React.useState(true);
  const [videoSrc, setVideoSrc] = useState(null);
  const [candidateName, setCandidateName] = useState("");
  const [openVideoPreview, setOpenVideoPreview] = useState(false);
  React.useEffect(() => {
    if (id) {
      document.getElementById(id).addEventListener("scroll", handleClose);
    }
    window.addEventListener("scroll", handleClose);
  });

  const handleToggle = (event) => {
    event.preventDefault();
    props?.setIsLoading(true);
    setOpen((prevOpen) => !prevOpen);
    setIsMenuLoading(true);
    const page = 0;
    setAnchorEl(event.currentTarget);
    CandidateServiceClass.candidateMatchedJobs(candidateId, page)
      .then((response) => {
        handleOpenShortlist();
        document.getElementsByClassName(
          "shortlist-modal-fix"
        )[0].style.visibility = "hidden";
        if (response.length < 10) {
          setLoadMore(false);
        } else {
          setLoadMore(true);
        }
        const jobsOptions = response.map((job) => ({
          id: job.id,
          name: job.jobTitle,
          jobDescriptionText: job.jobDescriptionText,
          city: job.city,
          state: job.state,
          country: job.country,
          jobReferenceNumber: job.jobReferenceNumber,
          isChecked: false,
          allowedToWorkInOtherJobs: job.allowedToWorkInOtherJobs,
        }));
        setOptions({ allChecked: false, list: jobsOptions });
        setIsMenuLoading(false);
        props?.setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching matched jobs:", error);
        setIsMenuLoading(false);
        props?.setIsLoading(false);
      });
  };
  const fetchMoreData = () => {
    let pgNo = pageNumber + 1;
    setPageNumber(pgNo);
    CandidateServiceClass.candidateMatchedJobs(candidateId, pgNo).then(
      (response) => {
        const jobsOptions = [];
        response.map((jobs) => {
          jobsOptions.push({
            id: jobs.id,
            name: jobs.jobTitle,
            jobDescriptionText: jobs.jobDescriptionText,
            city: jobs.city,
            state: jobs.state,
            country: jobs.country,
            jobReferenceNumber: jobs.jobReferenceNumber,
            isChecked: false,
            allowedToWorkInOtherJobs: jobs.allowedToWorkInOtherJobs
          });
        });
        if (response.length < 10) {
          setLoadMore(false);
        } else {
          setLoadMore(true);
        }
        setOptions({
          allChecked: false,
          list: options.list.concat(jobsOptions),
        });
        setIsMenuLoading(false);
      }
    );
  };
  const handleClose = (event) => {
    setOpen(false);
    if (anchorRef.current) {
      return;
    }
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  const handleChange = (e) => {
    let itemName = e.target.name;
    let checked = e.target.checked;
    setOptions((prevState) => {
      let { list, allChecked } = prevState;
      if (itemName === "checkAll") {
        allChecked = checked;
        list = list.map((item) => ({ ...item, isChecked: checked }));
      } else {
        list = list.map((item) =>
          item.id === itemName ? { ...item, isChecked: checked } : item
        );
        allChecked = list.every((item) => item.isChecked);
      }
      return { list, allChecked };
    });
  };
  const [options, setOptions] = React.useState({ allChecked: false, list: [] });
  const {
    title,
    buttonTitle,
    submitButtonTitle,
    candidateId,
    candidateInfo,
    className,
    id,
    candidate_id,
    setCandidateProfileDrawer,
    candidateProfileDrawer,
    setCandidateListId,
    optionValue,
    fromShortlistDrawer,
    user,
    setUserList,
    viewType
  } = props;
  const [openExclusiveWorkPermissionModal, setOpenExclusiveWorkPermissionModal] = useState(false);
  const [selectedExclusiveJobDetails, setSelectedExclusiveJobDetails] = useState(null);
  const handleSubmit = () => {
    const selectedJobs = options.list.filter((o) => o.isChecked);
    if (selectedJobs.length === 1) {
      if (selectedJobs[0].allowedToWorkInOtherJobs === "N" && props.candidateInfo?.occupiedCandidate) {
        setSelectedExclusiveJobDetails(selectedJobs[0]);
        setOpenExclusiveWorkPermissionModal(true);
        return;
      }
    }
    const selectedJobsIds = selectedJobs.map((o) => o.id);
    if (selectedJobsIds.length <= 0) {
      danger(" Please select jobs.");
      return;
    }
    CandidateServiceClass.shortListCandidate(candidateId, selectedJobsIds).then(
      async (response) => {
        if (selectedJobsIds.length == 1) {
          let jobData = {};
          await AccessService.getJobDetails(selectedJobsIds[0]).then((resp) => {
            jobData = resp;
          });
          if (jobData?.externalJobId) {
            await ApiCalls.get(
              process.env.REACT_APP_API_URL +
              `candidates/missingFields/${candidateId}?forExternal=true&jobId=${selectedJobsIds[0]}`
            ).then((res) => {
              let response = [];
              console.log(res);
              response = JSON.parse(res);
              response = response.filter((i) => {
                return i != "availableDate";
              });
              if (response?.length) {
                // success(
                //   `Candidate is shortlisted, but their details cannot be forwarded to VMS due to missing information.`
                // );
                success(
                  `Candidate ${
                      candidateInfo.firstName ?
                          ((candidateInfo?.lastName && candidateInfo?.lastName != null)  ? candidateInfo.firstName + " " + candidateInfo.lastName : candidateInfo.firstName)
                          : candidateInfo.candidateName
                  } is shortlisted.`
                );
              } else {
                success(
                  `Candidate ${
                      candidateInfo.firstName ?
                          ((candidateInfo?.lastName && candidateInfo?.lastName != null)  ? candidateInfo.firstName + " " + candidateInfo.lastName : candidateInfo.firstName)
                          : candidateInfo.candidateName
                  } is shortlisted.`
              );
              }
            });
          } else {
            success(
              `Candidate ${
                  candidateInfo.firstName ?
                      ((candidateInfo?.lastName && candidateInfo?.lastName != null)  ? candidateInfo.firstName + " " + candidateInfo.lastName : candidateInfo.firstName)
                      : candidateInfo.candidateName
              } is shortlisted.`
          );
          }
        } else {
          success(
            `Candidate ${
                candidateInfo.firstName ?
                    ((candidateInfo?.lastName && candidateInfo?.lastName != null)  ? candidateInfo.firstName + " " + candidateInfo.lastName : candidateInfo.firstName)
                    : candidateInfo.candidateName
            } is shortlisted.`
        );
        }
        handleCloseShortlist();
        props.setOpen(false);
      },
      (error) => {
        danger(<div>{error.errorMessage}</div>);
      }
    );
  };
  const handleOpenVideoPreview = (e, profileVideo, candidateName) => {
    e?.stopPropagation();
    setVideoSrc(profileVideo);
    setCandidateName(candidateName);
    setOpenVideoPreview(true);
  };
  // if (isLoading) {
  //   return <Loader />;
  // }
  const copyToClipboard = (event, jobReferenceNumber) => {
    event.stopPropagation();
    event.preventDefault();
    copy(jobReferenceNumber);
    success("Reference number is copied.");
  };
  const getExclusiveEnterprise = (id) => {
    const currentEnterprise = localStorage.getItem("childEnterpriseId") ? localStorage.getItem("childEnterpriseId") : localStorage.getItem("enterpriseId");
    const enterprisesList = JSON.parse(localStorage.getItem("enterprisesList"));
    const childEnterprisesList = JSON.parse(localStorage.getItem("mspEnterprsieData"))?.childEnterprises;
    const childEnterprise = childEnterprisesList?.find((ent) => ent.id == id);
    const enterprise = enterprisesList?.find((ent) => ent.id == id);
    return currentEnterprise != id ? (enterprise ? "To " + enterprise?.establishmentName : childEnterprise ? "To " + childEnterprise?.establishmentName : "") : "";
  };
  return (
    <React.Fragment>
      {/* btn primary-button w-100 */}
      <Button
        ref={anchorRef}
        id="composition-button"
        aria-controls={open ? "composition-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        className={className}
        onClick={handleToggle}
        // onClick={handleClick}
        disableRipple="true"
      // onClick={handleOpenShortlist}
      >
        {buttonTitle}
      </Button>
      {/* <Popper
        style={{ zIndex: "9" }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="right-start"
        transition
        disablePortal
        className="shortlist-options"
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "right-start" ? "left top" : "left bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose} onKeyPress={handleClose} tabIndex={0}>
                <MenuList
                  autoFocusItem={open}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                  onKeyDown={handleListKeyDown}
                >
                  {isMenuLoading ? (
                    <Grid container item spacing={1} lg={12} md={12}>
                      <Grid item lg={12} className="m-auto p-10 pb-0">
                        <Box>
                          <Stack
                            direction="row"
                            spacing={2}
                            sx={{ px: 2 }}
                            className="border-bottom-1"
                          >
                            <FormLabel
                              className=" f-16 font-weight-600 gray9"
                              component="legend"
                            >
                              {title}
                            </FormLabel>
                          </Stack>
                          <FormControl
                            style={{ width: "250px" }}
                            component="fieldset"
                            variant="standard"
                          >
                            <FormGroup>
                              <FormLabel
                                sx={{ px: 2 }}
                                className=" f-12 gray9 font-weight-500 pt-7 ml-4 gray9"
                                component="legend"
                              >
                                Please wait while loading...
                              </FormLabel>
                            </FormGroup>
                          </FormControl>
                          <Typography className="mt-10 d-flex border-top-1 align-right justify-end"></Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid
                      container
                      item
                      spacing={1}
                      lg={12}
                      md={12}
                      style={{ maxWidth: "400px" }}
                    >
                      <Grid item lg={12} className="m-auto p-10">
                        <Box>
                          <Stack
                            direction="row"
                            spacing={2}
                            sx={{ px: 2 }}
                            className="border-bottom-1 justify-between assess-question align-center"
                          >
                            <FormLabel
                              className="f-16 font-weight-600 gray9"
                              component="legend"
                            >
                              {title}
                            </FormLabel>
                            {options.list.length > 0 && (
                              <FormControlLabel
                                className="small-text-font font-weight-500"
                                control={
                                  <Checkbox
                                    name="checkAll"
                                    checked={options.allChecked}
                                    onChange={handleChange}
                                    sx={{
                                      color: "#98A2B3",
                                      "&.Mui-checked": {
                                        color: getComputedStyle(document.documentElement).getPropertyValue('--primary-color'),
                                      },
                                    }}
                                    tabIndex={0}
                                  />
                                }
                                label="Select All"
                                tabIndex={0}
                              />
                            )}
                          </Stack>
                          <FormControl
                            className="assess-question w-100"
                            sx={{ m: 2 }}
                            style={{
                              height:
                                options.list.length <= 4 ? "unset" : "200px",
                              overflowY: "auto",
                              marginRight: "0",
                              marginBottom: "0",
                            }}
                            component="fieldset"
                            variant="standard"
                          >
                            <InfiniteScroll
                              dataLength={options.list.length}
                              next={() => fetchMoreData()}
                              hasMore={loadMore}
                              inverse={false}
                              height={200}>
                            <FormGroup className="shortlist-align">
                              {options.list.length <= 0 ? (
                                <FormLabel
                                  className=" f-14 font-weight-500 gray9 ml-4 pt-7"
                                  component="legend"
                                >
                                  No jobs to shortlist this candidate
                                </FormLabel>
                              ) : (
                                options.list.map((item, index) => (
                                  <FormControlLabel
                                   key={index}
                                    className={`small-text-font font-weight-500 align-start w-100 pb-10  ${
                                      options.list.length - 1 === index
                                        ? ""
                                        : "borderBottom"
                                    } checkbox-card mt-5`}
                                    control={
                                      <Checkbox
                                        key={item.id}
                                        name={item.id}
                                        checked={item.isChecked}
                                        onChange={handleChange}
                                        sx={{
                                          color: "#98A2B3",
                                          "&.Mui-checked": {
                                            color: getComputedStyle(document.documentElement).getPropertyValue('--primary-color'),
                                          },
                                        }}
                                        tabIndex={0}
                                      />
                                    }
                                    label={
                                      <>
                                        <b>{item.name}</b>
                                        <Typography className="f-12 textEllipse w-300"
                                        >
                                          {item.jobDescriptionText
                                            ? convertHtmlToText(
                                                item.jobDescriptionText
                                              ).substring(0, 200) +
                                              ((item.jobDescriptionText
                                                ? item.jobDescriptionText.length
                                                : 0) > 200
                                                ? "..."
                                                : "")
                                            : ""}
                                        </Typography>
                                        <Typography
                                          className=" navbarMargin atching-details-align matching-details-align"
                                        >
                                          <Box className="f-12 small-text-font font-weight-500 align-center d-flex candidate-info-align mt-2">
                                            {item.city?.length > 0 && (
                                              <LocationOnIcon className="f-16 gray8 icon-margin icon-height-14" aria-label="Location icon" />
                                            )}{" "}
                                            <i>
                                              {item.city}
                                              {item.city?.length > 0
                                                ? ","
                                                : ""}{" "}
                                              {item.state} {item.country}
                                            </i>{" "}
                                          </Box>
                                        </Typography>
                                      </>
                                    }
                                    tabIndex={0}
                                  />
                                ))
                              )}
                            </FormGroup>
                            </InfiniteScroll>
                          </FormControl>
                          {options.list.length > 0 && (
                            <Typography className="mt-10 d-flex border-top-1 align-right justify-end shortlist-btn">
                              <Button
                                onClick={() => {
                                  handleSubmit();
                                  handleClose();
                                }}
                                disabled={
                                  options.list.filter((o) => o.isChecked)
                                    .length <= 0
                                    ? true
                                    : false
                                }
                                className="btn primary-button mt-10"
                                variant="contained"
                                disableRipple="true"
                              >
                                {submitButtonTitle}
                              </Button>
                            </Typography>
                          )}
                        </Box>
                      </Grid>
                    </Grid>
                  )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper> */}
      <Modal
        // style={{ marginTop: "60px" }}
        // open={openshortlist}
        onClose={() => {
          handleCloseShortlist();
          props.setOpen(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="shortlist-modal inputcontrasts">
          <Grid className="d-flex align-center justify-between">
            <Typography className="f-16 font-weight-600 heading-text-color">
              Shortlist Candidate{" "}
            </Typography>
            <Typography className="cursor-hover gray7 align-center d-flex">
              <CloseIcon
                tabIndex={0}
                onKeyPress={() => {
                  handleCloseShortlist();
                  props.setOpen(false);
                }}
                onClick={() => {
                  handleCloseShortlist();
                  props.setOpen(false);
                }}
                aria-label="Close icon"
              />
            </Typography>
          </Grid>
          <Grid className="d-flex mt-10 pb-30">
            <Grid
            className={`${localStorage.getItem("childEnterpriseId") && (localStorage.getItem("mspEnterpriseId") === candidateInfo?.sourceEnterpriseId) ? "w-101" : candidateInfo?.talentPool === "P" ? "w-81" : "w-101"}`}>
              <Grid className="p-relative">
                <Grid
                  item
                  lg={12}
                  md={12}
                  xs={12}
                  className="justify-center d-flex"
                >
                  {(candidateInfo.mediaFile === "****" ||
                    candidateInfo.mediaFileSigned === null ||
                    candidateInfo.mediaFileSigned === "") && (
                      <Typography
                        className="default-img-border justify-center align-center d-flex primary-shadow-color bg-input f-16 font-weight-700 text-capitalize"
                        width="45px"
                        height="45px"
                        left="2px"
                        aria-label="Default image"
                      >
                        {candidateInfo.firstName.trim().substring(0, 1)}
                      </Typography>
                    )}
                  {(candidateInfo.profileImageUrl === "****" ||
                    candidateInfo.profileImageUrlSigned === null ||
                    candidateInfo.profileImageUrlSigned === "") && (
                      <Typography
                        className="default-img-border justify-center align-center d-flex primary-shadow-color bg-input f-16 font-weight-700 text-capitalize"
                        width="45px"
                        height="45px"
                        left="2px"
                        aria-label="Default image"
                      >
                        {candidateInfo.candidateName.trim().substring(0, 1)}
                      </Typography>
                    )}
                  {candidateInfo.mediaFile !== "****" &&
                    candidateInfo.mediaFileSigned &&
                    candidateInfo.mediaFileSigned !== null &&
                    candidateInfo.mediaFileSigned !== "" &&
                    !checkIsImage(candidateInfo.mediaFileSigned) && (
                      <>
                        <video
                          className="default-img-border cursor-hover p-relative"
                          style={{ height: "45px", width: "45px" }}
                          src={candidateInfo.mediaFileSigned}
                          onClick={() => {
                            handleOpenVideoPreview(
                              candidateInfo.mediaFileSigned,
                              candidateInfo.firstName +
                              " " +
                              candidateInfo.lastName
                            );
                          }}
                          muted
                          onKeyPress={() => {
                            handleOpenVideoPreview(
                              candidateInfo.mediaFileSigned,
                              candidateInfo.firstName +
                              " " +
                              candidateInfo.lastName
                            );
                          }}
                          tabIndex={0}
                        />
                        <img
                          onClick={() => {
                            handleOpenVideoPreview(
                              candidateInfo.mediaFileSigned,
                              candidateInfo.firstName +
                              " " +
                              candidateInfo.lastName
                            );
                          }}
                          className="profileImage-overlay play-icon-profile cursor-hover"
                          src={playIcon}
                          style={{ top: "-10px", left: "42px" }}
                          alt="img"
                          tabIndex={0}
                          onKeyPress={() => {
                            handleOpenVideoPreview(
                              candidateInfo.mediaFileSigned,
                              candidateInfo.firstName +
                              " " +
                              candidateInfo.lastName
                            );
                          }}
                        />
                      </>
                    )}
                  {candidateInfo.profileImageUrl !== "****" &&
                    candidateInfo.profileImageUrlSigned &&
                    candidateInfo.profileImageUrlSigned !== null &&
                    candidateInfo.profileImageUrlSigned !== "" &&
                    !checkIsImage(candidateInfo.profileImageUrlSigned) && (
                      <>
                        <video
                          className="default-img-border cursor-hover p-relative"
                          style={{ left: "2px" }}
                          width="45px"
                          height="45px"
                          src={candidateInfo.profileImageUrlSigned}
                          onClick={() => {
                            handleOpenVideoPreview(
                              candidateInfo.profileImageUrlSigned,
                              candidateInfo.candidateName
                            );
                          }}
                          muted
                          onKeyPress={() => {
                            handleOpenVideoPreview(
                              candidateInfo.profileImageUrlSigned,
                              candidateInfo.candidateName
                            );
                          }}
                          tabIndex={0}
                        />
                        <img
                          onClick={() => {
                            handleOpenVideoPreview(
                              candidateInfo.profileImageUrlSigned,
                              candidateInfo.candidateName
                            );
                          }}
                          className="profileImage-overlay play-icon-profile cursor-hover"
                          src={playIcon}
                          style={{ top: "-10px", left: "42px" }}
                          alt="img"
                          tabIndex={0}
                          onKeyPress={() => {
                            handleOpenVideoPreview(
                              candidateInfo.profileImageUrlSigned,
                              candidateInfo.candidateName
                            );
                          }}
                        />
                      </>
                    )}
                  {candidateInfo.mediaFile !== "****" &&
                    candidateInfo.mediaFileSigned &&
                    candidateInfo.mediaFileSigned !== null &&
                    candidateInfo.mediaFileSigned !== "" &&
                    checkIsImage(candidateInfo.mediaFileSigned) && (
                      <img
                        className="default-img-border"
                        style={{ left: "2px" }}
                        src={candidateInfo.mediaFileSigned}
                        alt="img"
                        width="45px"
                        height="45px"
                      />
                    )}
                  {candidateInfo.profileImageUrl !== "****" &&
                    candidateInfo.profileImageUrlSigned &&
                    candidateInfo.profileImageUrlSigned !== null &&
                    candidateInfo.profileImageUrlSigned !== "" &&
                    checkIsImage(candidateInfo.profileImageUrlSigned) && (
                      <img
                        className="default-img-border"
                        style={{ left: "2px" }}
                        src={candidateInfo.profileImageUrlSigned}
                        alt="img"
                        width="45px"
                        height="45px"
                      />
                    )}
                </Grid>
                <Grid>
                  <TooltipData
                    title={
                      <div className="mt-15">
                        <Typography>
                          <div className="d-flex align-center">
                            <span className={`${localStorage.getItem("childEnterpriseId") && (localStorage.getItem("mspEnterpriseId") === candidateInfo?.sourceEnterpriseId) ? "mspExclusive" : "priorityStatus"} d-flex align-center justify-center`}>
                              <span className="d-flex align-center">
                                {localStorage.getItem("childEnterpriseId") && (localStorage.getItem("mspEnterpriseId") === candidateInfo?.sourceEnterpriseId) ? <PriorityIconGreen className="msp-svg" /> : <PriorityIcon />}
                              </span>
                              <span className="pl-2">
                                {localStorage.getItem("childEnterpriseId") && (localStorage.getItem("mspEnterpriseId") === candidateInfo?.sourceEnterpriseId) ? Constants.MSP_EXCLUSIVE : "Exclusive"}
                              </span>
                            </span>
                            <span className="f-12 c-white pl-5">{getExclusiveEnterprise(candidateInfo?.sourceEnterpriseId)}</span>
                          </div>
                        </Typography>
                        <Typography className="f-12 c-white mt-5">
                          {localStorage.getItem("childEnterpriseId") && (localStorage.getItem("mspEnterpriseId") === candidateInfo?.sourceEnterpriseId) ? Constants.MSP_EXCLUSIVE_Detail : "These are the candidates who have come to the platform through you and only you have the access to their profile"}
                        </Typography>
                        <br />
                      </div>
                    }
                    position="top"
                    trigger="mouseenter"
                    animation="fade"
                    arrow
                  >
                  <Typography className="mt-5 mb-5">
                    {candidateInfo?.talentPool === "Z" && (
                      <span
                        className={`${localStorage.getItem("childEnterpriseId") && (localStorage.getItem("mspEnterpriseId") === candidateInfo?.sourceEnterpriseId) ? "mspExclusive" : "priorityStatus"} d-flex align-center justify-center p-absolute`}
                        style={{
                          bottom: "-20px",
                          left: "-1px",
                        }}
                      >
                        <span className="d-flex align-center">
                          {(localStorage.getItem("childEnterpriseId") && (localStorage.getItem("mspEnterpriseId") === candidateInfo?.sourceEnterpriseId)) ? <PriorityIconGreen className="msp-svg" /> : <PriorityIcon />}
                        </span>
                        <span className="pl-5">{localStorage.getItem("childEnterpriseId") && (localStorage.getItem("mspEnterpriseId") === candidateInfo?.sourceEnterpriseId) ? Constants.MSP_EXCLUSIVE : "Exclusive"}</span>
                      </span>
                    )}
                  </Typography>
                  </TooltipData>
                  <TooltipData
                    title={
                      <div className="mt-15">
                        <Typography
                          style={{
                            width: "110px",
                          }}
                        >
                          <span className="myCandidate-Ongoing d-flex align-center justify-center">
                            <span className="d-flex align-center">
                              <PrivateIcon />
                            </span>
                            <span className="pl-5">
                              Qualified
                            </span>
                          </span>
                        </Typography>
                        <Typography className="f-12 c-white mt-5">
                          These are the
                          candidates with whom
                          you have interacted on
                          our platform
                        </Typography>
                        <br />
                      </div>
                    }
                    placement="top"
                    trigger="mouseenter"
                    animation="fade"
                    arrow
                  >
                  <Typography className="mt-5 mb-5">
                    {candidateInfo?.talentPool === "P" && (
                      <span
                        className="myCandidate-Ongoing d-flex align-center justify-center p-absolute"
                        style={{
                          bottom: "-20px",
                          left: "0px",
                        }}
                      >
                        <span className="d-flex align-center">
                          <PrivateIcon />
                        </span>
                        <span className="pl-5">Qualified</span>
                      </span>
                    )}
                  </Typography>
                  </TooltipData>
                </Grid>
              </Grid>
            </Grid>
            <Grid className="ml-5 mt-2">
              <Typography className="main-text-color f-14 font-weight-600">
                {candidateInfo.firstName
                  ? (candidateInfo.firstName + (candidateInfo.lastName ? " " + candidateInfo.lastName : ""))
                  : candidateInfo.candidateName}
              </Typography>
              {candidateInfo?.city && candidateInfo?.country && (
                <Typography className="gray7 f-12 font-weight-400 mt-5 d-flex align-center">
                  <LocationIcon
                    width="15px"
                    className="mr-5 gray7 f-12 font-weight-400 icon-margin"
                  />
                  <span>
                    {candidateInfo.city}
                    {candidateInfo?.city ? "," : ""} {candidateInfo.country}
                    {/* {candidateInfo.city}
                  {candidateInfo.city?.length > 0 ? ", " : ""} */}
                    {/* {candidateInfo.state}
                  {candidateInfo.state?.length > 0 ? ", " : ""}{" "} */}
                    {/* {candidateInfo.country}
                  {!candidateInfo.city &&
                    !candidateInfo.country &&
                    "No location"} */}
                    {/* {candidateInfo?.city ? candidateInfo?.city : "-"},{" "}
                  {candidateInfo?.country ? candidateInfo?.country : "-"} */}
                  </span>
                </Typography>
              )}
            </Grid>
          </Grid>
          <Grid>
            {isMenuLoading ? (
              <Grid
                container
                item
                spacing={1}
                lg={12}
                md={12}
                xs={12}
                className=""
              >
                <Grid item lg={12} md={12} xs={12} className="m-auto p-10 pb-0">
                  <Box>
                    <Stack
                      direction="row"
                      spacing={2}
                      sx={{ px: 2 }}
                      className="border-bottom-1"
                    >
                      <FormLabel
                        className=" f-16 font-weight-600 gray9"
                        component="legend"
                      >
                        {title}
                      </FormLabel>
                    </Stack>
                    <FormControl
                      // style={{ width: "250px" }}
                      component="fieldset"
                      variant="standard"
                    >
                      <FormGroup>
                        <FormLabel
                          sx={{ px: 2 }}
                          className=" f-12 gray9 font-weight-500 pt-7 ml-4 gray9"
                          component="legend"
                        >
                          Please wait while loading...
                        </FormLabel>
                      </FormGroup>
                    </FormControl>
                    <Typography className="mt-10 d-flex border-top-1 align-right justify-end"></Typography>
                  </Box>
                </Grid>
              </Grid>
            ) : (
              <InfiniteScroll
                dataLength={options.list.length}
                next={() => fetchMoreData()}
                hasMore={loadMore}
                inverse={false}
                height={300}
              >
                <Grid
                  container
                  item
                  spacing={1}
                  lg={12}
                  md={12}
                  xs={12}
                  // style={{ maxWidth: "400px" }}
                  className=""
                >
                  <Grid item lg={12} md={12} xs={12} className="m-auto p-10">
                    <Box>
                      <Stack
                        direction="row"
                        spacing={1}
                        className="assess-question align-center"
                      >
                        {/* <FormLabel
                        className="f-16 font-weight-600 gray9"
                        component="legend"
                      >
                        {title}
                      </FormLabel> */}
                        {options.list.length > 0 && (
                          <FormControlLabel
                            className="small-text-font font-weight-500 check-all"
                            control={
                              <Checkbox
                                name="checkAll"
                                checked={options.allChecked}
                                onChange={handleChange}
                                sx={{
                                  color: "#98A2B3",
                                  "&.Mui-checked": {
                                    color: getComputedStyle(
                                      document.documentElement
                                    ).getPropertyValue("--secondary-color"),
                                  },
                                }}
                                tabIndex={0}
                              />
                            }
                            label="Select All"
                            tabIndex={0}
                          />
                        )}
                      </Stack>
                      <FormGroup className="shortlist-align">
                        {options.list.length === 0 ? (
                          <>
                            <Grid
                              item
                              lg={11}
                              md={11}
                              xs={12}
                              className="m-auto text-center"
                            >
                              <img
                                src={noCandidate}
                                alt="img"
                                width="200px"
                                height="200px"
                              />
                              <FormLabel
                                className=" f-14 font-weight-500 gray9 ml-4 pt-7"
                                component="legend"
                              >
                                No jobs to shortlist this candidate
                              </FormLabel>
                            </Grid>
                          </>
                        ) : (
                          options.list.map((item, index) => (
                            <FormControlLabel
                              key={index}
                              className={`small-text-font font-weight-500 align-start w-100 pb-10  ${options.list.length - 1 === index
                                  ? ""
                                  : "border-bottom-gray4"
                                } checkbox-card mt-5`}
                              control={
                                <Checkbox
                                  key={item.id}
                                  name={item.id}
                                  checked={item.isChecked}
                                  onChange={handleChange}
                                  sx={{
                                    color: "#98A2B3",
                                    paddingTop: "2px",
                                    "&.Mui-checked": {
                                      color: getComputedStyle(
                                        document.documentElement
                                      ).getPropertyValue("--primary-color"),
                                      paddingTop: "2px",
                                    },
                                  }}
                                  tabIndex={0}
                                />
                              }
                              label={
                                <>
                                  <span className="f-14 font-weight-600 main-text-color cursor-hover">
                                    {item.name}
                                  </span>
                                  {/* <Typography className="f-12 textEllipse w-300"
                                    >
                                      {item.jobDescriptionText
                                        ? convertHtmlToText(
                                          item.jobDescriptionText
                                        ).substring(0, 200) +
                                        ((item.jobDescriptionText
                                          ? item.jobDescriptionText.length
                                          : 0) > 200
                                          ? "..."
                                          : "")
                                        : ""}
                                    </Typography> */}
                                  {item?.city && item?.country && (
                                    <Typography className="matching-details-align">
                                      <Box className="f-12 font-weight-400 gray7 align-center d-flex candidate-info-align mt-2">
                                        {item.city?.length > 0 && (
                                          <LocationIcon
                                            width="15px"
                                            className="mr-5 gray7 f-12 font-weight-400 icon-margin"
                                          />
                                        )}{" "}
                                        {item.city}
                                        {item?.city ? "," : ""} {item.country}
                                        {/* {item.city}
                                      {item.city?.length > 0 ? "," : ""}{" "} */}
                                        {/* {item.state} */}
                                        {/* {item.country}{" "} */}
                                        {/* {(item?.city === "" || item?.city === null) &&
                                        (item.country === "" || item.country === null) && (
                                          <span style={{ display: "block" }}>-</span>
                                        )} */}
                                      </Box>
                                    </Typography>
                                  )}
                                  <Box className="font-weight-400 d-flex align-center">
                                    <Box className="d-flex align-center mr-5 ml-2">
                                      <JobReferenceNumberIcon width="12px" height="12px" className="svg-icons-gray7" />
                                    </Box>
                                    <Box className="d-flex align-center gray7 f-12 display-flex align-items-center">
                                      Job Reference #: {item?.jobReferenceNumber}
                                      <Link
                                        onClick={(e) => copyToClipboard(e, item?.jobReferenceNumber)}
                                        onKeyPress={(e) => copyToClipboard(e, item?.jobReferenceNumber)}
                                        className="cursor-hover display-flex p-3"
                                        tabIndex={0}
                                      >
                                        <ContentCopyIcon
                                          style={{ width: "13px", height: '13px', margin: "0" }}
                                          className="gray7"
                                        />
                                      </Link>
                                    </Box>
                                  </Box>
                                </>
                              }
                              tabIndex={0}
                            />
                          ))
                        )}
                      </FormGroup>
                    </Box>
                  </Grid>
                </Grid>
              </InfiniteScroll>
            )}
          </Grid>
          <Grid id="modal-footer" className="d-flex justify-end align-center">
            {options.list.length > 0 && (
              <Typography className="mt-10 d-flex align-right justify-end shortlist-btn">
                <Button
                  onClick={() => {
                    handleSubmit();
                    handleClose();
                  }}
                  disabled={
                    options.list.filter((o) => o.isChecked).length <= 0
                      ? true
                      : false
                  }
                  className="btn primary-button mt-10"
                  variant="contained"
                  disableRipple="true"
                >
                  {submitButtonTitle}
                </Button>
              </Typography>
            )}
          </Grid>
        </Box>
      </Modal>
      {openshortlist &&
      <ShortlistCandidateDrawer
        candidateProfileDrawer={openshortlist}
        setCandidateProfileDrawer={setShortlist}
        setCandidateListId={setCandidateListId}
        candidate_id={candidate_id ? candidate_id : candidateId}
        optionValue={optionValue}
        setIsLoading={setIsLoading}
        isFromMyCandidates={true}
        handleDropdownClick={props.handleDropdownClick}
        // candidate_id={candidateListId}
        setUserList={setUserList}
        viewType={viewType}
        user={user}
        drawerTitle={"Shortlist Candidate"}
        />
      }
      <CandidateProfileVideoPreview
        open={openVideoPreview}
        setOpen={setOpenVideoPreview}
        videoSrc={videoSrc}
        candidateName={candidateName}
      />
      <ExclusiveWorkPermissionModal
        openExclusiveWorkPermissionModal={openExclusiveWorkPermissionModal}
        setOpenExclusiveWorkPermissionModal={setOpenExclusiveWorkPermissionModal}
        action={"shortlist"}
        jobDetails={selectedExclusiveJobDetails}
      />
    </React.Fragment>
  );
}
