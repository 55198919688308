import {
    Box,
    Button,
    Grid,
    Typography,
    Input,
    TextareaAutosize,
    MenuItem,
    Select,
    TextField,
    Checkbox,
    InputAdornment,
    useMediaQuery,
  } from "@mui/material";
  import React, { useEffect, useState, useRef } from "react";
  import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
  import DateFnsUtils from "@date-io/date-fns";
  import AddressShortenSearch from "../../../Components/AddressShortenSearch";
  import LocationSearchInput from "../../EnterprisePortal/Project/Profile/LocationSearchInput";
  import { danger, success, warning } from "../../../../src/helpers/message-box";
  import UploadResumeModal from "../../EnterprisePortal/Project/UploadResumeModal";
  import Loader from "../../Loader";
  import {
    handleSpaces,
    getselectedNationality,
    getWorkType,
  } from "../../../../src/helpers/helper-data";
  import { Nationalities } from "../../../../src/data/nationalities";
  import Autocomplete from "@mui/material/Autocomplete";
  import { useForm } from "react-hook-form";
  import * as Yup from "yup";
  import { yupResolver } from "@hookform/resolvers/yup";
  import ZipcodeSearchInput from "../../../Components/ZipcodeSearchInput";
  import AutoCompleteCountryCode from "../../EnterprisePortal/Referrals/AutoCompleteCountryCode";
  import makeStyles from "@material-ui/core/styles/makeStyles";
  import { useDispatch, useSelector } from "react-redux";
  import moment from "moment";
  import CloseIcon from "@mui/icons-material/Close";
  import { formatDate } from "../../../../src/helpers/helper-data";
  import ProfileService from "../../../Services/Enterprise/profile.service";
  import { ReactComponent as Sparkle } from "../../../assets/images/profile-summary-sparkle.svg";
  import ApiCalls from "../../../api/customAxios";
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const useStyles = makeStyles({
    customTextField: {
      "& input::placeholder": {
        fontWeight: "normal",
        color: "#667085 !important",
        opacity: "0.6",
      },
    },
  });
  const MissingDetails = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const fileRef = useRef(null);
    const candidateInfo = useSelector((state) => state.profile.candidate);
    const [missingDetails, setMissingDetails] = useState({
        id: props?.candidateId,
        dateOfBirth: null,
        mobileNumber: "",
        phoneCode: "+1",
        city: "",
        nationality: null,
        state: "",
        country: "",
        zipCode: "",
        payRate: "",
        gender: "",
        ethnicity: "",
        customEthnicity: "",
        militaryStatus: "",
        wageExpectations: "",
        yearsOfExperience: "",
        knownAs: "",
        profileSummary: "",
        currency: "USD",
  });
    const [latitude, setLatitude] = useState(null);
    const [openAddResumeModal, setOpenAddResumeModal] = useState(false);
    const [longitude, setLongitude] = useState(null);
    const [workPlace, setWorkPlace] = useState([]);
    const [currentShift, setCurrentShift] = useState("");
    const [currentZone, setCurrentZone] = useState("EST");
    const [invalidMobilePhoneError, setInvalidMobilePhoneError] = useState("");
    const [openInput, setOpenInput] = useState(false);
    const [workType, setWorkTypes] = useState([]);
    const [isFileValid, setIsFileValid] = useState(false);
    const [filename, setFilename] = useState("");
    const [resumeFile, setResumeFile] = useState([]);
    const [pictureFile, setPictureFile] = useState(null);
    const [generateApiButtonDisable, setGenerateApiButtonDisable] =
      useState(false);
    const [selectedEthnicityOptions, setSelectedEthnicityOptions] = useState([]);
    const [shouldOpenconfirmation, setShouldOpenconfirmation] = useState(false);
    const [customEthnicityErr, setCustomEthnicityErr] = useState("");
    const [ethnicityError, setEthnicityError] = useState("");
    const [nationalityError, setNatinaityError] = useState("");
    const [isLoadingForSummary, setIsLoadingForSummary] = useState(false);
    const [resumeDocuments, setResumeDocuments] = useState([]);
    const [isLoadingResume, setIsLoadingResume] = useState(false);
    const [shifts, setShifts] = useState({
      shiftStartTime: null,
      shiftEndTime: null,
    });
    const [locationDetails, setLocationDetails] = useState({
      address: "",
      city: "",
      state: "",
      country: "",
      zipCode: "",
    });
    const shiftTimes = {
      "1st": "6:00 AM - 2:00 PM",
      "2nd": "2:00 PM - 10:00 PM",
      "3rd": "10:00 PM - 6:00 AM",
      default: "--Select--",
    };
    const optionsEthnicity = [
      "White / Caucasian",
      "Black / African American",
      "Hispanic / Latino",
      "Asian / Asian American",
      "Native American / Indigenous",
      "Pacific Islander / Native Hawaiian",
      "Middle Eastern / Arab",
      "Mixed Race / Multiracial",
      "Prefer not to say",
      "",
      null,
    ];
    const ethnicityList = [
      "White / Caucasian",
      "Black / African American",
      "Hispanic / Latino",
      "Asian / Asian American",
      "Native American / Indigenous",
      "Pacific Islander / Native Hawaiian",
      "Middle Eastern / Arab",
      "Mixed Race / Multiracial",
    ];
    const validationSchema = Yup.object().shape({
      yearsOfExperience: Yup.string()
        .required("Overall years of experience is required")
        .matches(
          /^(0*[0-9]{0,2}|0*[0-9]{0,2}(\.([1-9]|1[0-2]))|[0-9]{0,2}(\.([1-9]|1[0-2]))?)$/,
          {
            excludeEmptyString: true,
            message: "Please enter valid overall years of experience",
          }
        ),
      knownAs: Yup.string()
        .required("known as is required")
        .max(128, "Exceeded maximum character length of 128"),
      address: Yup.string()
        .nullable()
        .required("Address is required")
        .max(1024, "Exceeded maximum character length of 1024"),
      zipCode: Yup.string()
        .trim()
        .required("Zip code is required")
        .max(32, "Exceeded maximum character length of 32")
        .matches(/^(?!-).*(?<!-)$/, {
          excludeEmptyString: true,
          message: "Please enter a valid zipcode",
        }),
      country: Yup.string().required("Country is required"),
      state: Yup.string().required("State is required"),
      city: Yup.string().required("City is required"),
      customEthnicity:
        missingDetails?.ethnicity == "Other"
          ? Yup.string()
              .required("Ethnicity is required")
              .max(256, "Exceeded maximum character length of 256")
          : Yup.string().nullable(),
      hourlyRateNumber: Yup.string()
        .required("Pay rate is required")
        .max(10, "Exceeded maximum character length of 10")
        .matches(/^\d+$/, {
          excludeEmptyString: true,
          message: "Please enter valid pay rate",
        }),
      nationality: Yup.string().required("Nationality is required"),
      ethnicity:
        missingDetails?.customEthnicity != ""
          ? Yup.string().nullable()
          : Yup.string().required("Ethnicity is required"),
    });
    const {
      register,
      reset,
      handleSubmit,
      getValues,
      setValue,
      formState: { errors, isValid },
    } = useForm({
      resolver: yupResolver(validationSchema),
      mode: "onChange",
      defaultValues: missingDetails,
    });
    const handleSelectlatLng = (data) => {
      setLatitude(data.lat);
      setLongitude(data.lng);
    };
    useEffect(() => {
      // alert(props?.questions);
      if (props?.missedResume?.length) {
        setResumeFile(props?.missedResume);
      }
    }, [props?.missedResume]);
    useEffect(() => {
      setMissingDetails((prevDetails) => ({
        ...prevDetails,
        city: locationDetails.city,
        state: locationDetails.state,
        country: locationDetails.country,
        zipCode: locationDetails.zipCode,
      }));
    }, [locationDetails]);
    useEffect(() => {
      if (props?.profileEssentialsApiCall) {
        const candidatesdto = {
          candidatesDto: {
              ...missingDetails,
              nationality: missingDetails.nationality ? missingDetails.nationality.alpha_3_code : "",
          },
      };
      candidatesdto.candidatesDto = Object.keys(candidatesdto.candidatesDto)
      .filter((key) => props?.questions.includes(key) || key === "id")
      .reduce((obj, key) => {
          if (key === "profileSummary" && props?.questions.includes(key)) {
              obj["brief"] = candidatesdto.candidatesDto[key];
          } else {
              obj[key] = candidatesdto.candidatesDto[key];
          }
          return obj;
      }, {});
        ProfileService.updateProfile(candidatesdto)
        .then((response) => {
          if (response) {
            props?.setIsLoading(false);
            props.setProfileEssentialsApiCall(false);
            if (props.isFromTalentConsole) {
            props?.getCandidatesCall();
          }
          }
        }).catch(function (error) {
          props.setIsLoading(false);
        });
      };
    }, [props.profileEssentialsApiCall]);
    useEffect(() => {
      props?.questions?.length == 0 && props?.setProfileEssentialsValidations(true);
      const isValid = checkMissingDetails();
      props?.setProfileEssentialsValidations(isValid); // Update the validation state in the parent component
      // alert(isValid);
    }, [missingDetails, props?.setProfileEssentialsValidations]);
    const checkMissingDetails = () => {
      // Ensure props.question is defined and is an array
      const fieldsToCheck = props?.questions || [];
      // Check if any of the fields in fieldsToCheck are empty or null in missingDetails
      const isValid = fieldsToCheck.every((field) => {
        const value = missingDetails[field];
        return value !== null && value !== "";
      });
      return isValid;
    };
    const addNewResumeDocument = () => {
      setOpenAddResumeModal(true);
    };
    const getShiftDetails = (selectedValue) => {
      const [startTime, endTime] = selectedValue?.split(" - ");
      // const timezone = endTime.substring(endTime.lastIndexOf(' ') + 1);
      const convertedStartTime = convertTo24HourFormat(startTime);
      const convertedEndTime = convertTo24HourFormat(endTime);
      return [convertedStartTime, convertedEndTime];
    };
    const convertTo24HourFormat = (time) => {
      const [hours, minutes, meridiem] = time.split(/:| /);
      let convertedHours = parseInt(hours, 10);
      if (meridiem === "PM" && convertedHours < 12) {
        convertedHours += 12;
      } else if (meridiem === "AM" && convertedHours === 12) {
        convertedHours = 0;
      }
      return `${convertedHours.toString().padStart(2, "0")}${minutes.padStart(
        2,
        "0"
      )}`;
    };
    const handleSelectChange = (e) => {
      const value = shiftTimes[e.target.value];
      setCurrentShift(e.target.value);
      if (e.target.value != "default") {
        const [startTime, endTime] = getShiftDetails(value);
        setShifts({ shiftStartTime: startTime, shiftEndTime: endTime });
      }
    };
    const handleAddressSelect = (data) => {
      setValue("address", data.address, { shouldValidate: true });
      if (data?.city) {
        setLocationDetails(data);
        setValue("state", data.state, { shouldValidate: true });
        setValue("city", data.city, { shouldValidate: true });
        setValue("country", data.country, { shouldValidate: true });
        setValue("zipCode", data.zipCode, {
          shouldValidate:
            props?.fieldMapping?.includes("address") &&
            !props?.fieldMapping?.includes("zipCode")
              ? false
              : true,
        });
      } else {
        const temp = {
          address: data.address,
          city: locationDetails.city,
          state: locationDetails.state,
          country: locationDetails.country,
          zipCode: locationDetails.zipCode,
        };
        setLocationDetails(temp);
      }
    };
    const handleZipCodeSelect = (data) => {
      setValue("zipCode", data.zipCode, { shouldValidate: true });
      if (data?.city) {
        setLocationDetails(data);
        setValue("state", data.state, { shouldValidate: true });
        setValue("city", data.city, { shouldValidate: true });
        setValue("country", data.country, { shouldValidate: true });
      } else {
        const temp = {
          zipCode: data.zipCode,
          city: locationDetails.city,
          state: locationDetails.state,
          country: locationDetails.country,
        };
        setLocationDetails(temp);
      }
    };
    const handleCheckboxChange = (type) => {
      setWorkTypes((prevWorkTypes) => {
        if (prevWorkTypes?.includes(type)) {
          return prevWorkTypes?.filter((item) => item !== type);
        } else {
          return [...prevWorkTypes, type];
        }
      });
    };
    const handleWorkPlaceChange = (type) => {
      setWorkPlace((prevWorkPlaces) => {
        if (prevWorkPlaces?.includes(type)) {
          return prevWorkPlaces?.filter((item) => item !== type);
        } else {
          return [...prevWorkPlaces, type];
        }
      });
    };
    const workTypeAbbreviations = {
      fullTime: "FT",
      partTime: "PT",
      contract: "FL",
      internship: "IN",
      other: "OT",
    };
    const jobType = {
      FT: "fullTime",
      PT: "partTime",
      FL: "contract",
      IN: "internship",
      OT: "other",
    };
    const workPlaceAbbreviations = {
      Remote: "R",
      Onsite: "O",
      Hybrid: "H",
    };
    const getSelectedWorkTypes = () => {
      return workType?.map((type) => workTypeAbbreviations[type]).join(",");
    };
    const getSelectedWorkPlaces = () => {
      return workPlace?.map((type) => workPlaceAbbreviations[type]).join(",");
    };
    const handleLocationSelect = (data) => {
      setLocationDetails(data);
      setValue("state", data.state, { shouldValidate: true });
      setValue("city", data.city, { shouldValidate: true });
      setValue("country", data.country, { shouldValidate: true });
      if (data?.zipCode) {
        setValue("zipCode", data.zipCode, { shouldValidate: true });
      } else {
        setValue("zipCode", "", { shouldValidate: true });
      }
    };
    const getCandidateInfo = (shiftStartTime, shiftEndTime) => {
      if (shiftStartTime != undefined && shiftStartTime != "") {
        let startTime =
          shiftStartTime?.toString()?.slice(0, 2) +
          ":" +
          shiftStartTime?.toString()?.slice(2, 4);
        let endTime =
          shiftEndTime?.toString()?.slice(0, 2) +
          ":" +
          shiftEndTime?.toString()?.slice(2, 4);
        let shiftStart = startTime
          ? moment("2017-08-30T" + startTime + ":00Z")
              .utc()
              .format("hh:mm A")
          : null;
        let shiftEnd = endTime
          ? moment("2017-08-30T" + endTime + ":00Z")
              .utc()
              .format("hh:mm A")
          : null;
        setCurrentShift(
          shiftStart.startsWith("06")
            ? "1st"
            : shiftStart.startsWith("02")
            ? "2nd"
            : "3rd"
        );
      }
    };
    const filterEthnicity = (val) => {
      if (!val) {
        setMissingDetails({ ...missingDetails, ethnicity: "" });
        setValue("customEthnicity", "", { shouldValidate: true });
        setCustomEthnicityErr("");
        setOpenInput(false);
        return [];
      }
      let customEthnicity = val
        ?.split(",")
        ?.find((_ethnicity) => !optionsEthnicity.includes(_ethnicity.trim()));
      if (customEthnicity) {
        setValue("customEthnicity", customEthnicity.trim(), {
          shouldValidate: true,
        });
        if (customEthnicity?.length > 256) {
          setCustomEthnicityErr("Exceeded maximum character length of 256");
        }
        setOpenInput(true);
        setMissingDetails({ ...missingDetails, ethnicity: "Other" });
        return [];
      } else if (val?.trim() === "Prefer not to say") {
        setMissingDetails({ ...missingDetails, ethnicity: val });
        setValue("customEthnicity", "", { shouldValidate: true });
        setCustomEthnicityErr("");
        setOpenInput(false);
        return [];
      } else {
        setMissingDetails({ ...missingDetails, ethnicity: "" });
        setValue("customEthnicity", "", { shouldValidate: true });
        setCustomEthnicityErr("");
        setOpenInput(false);
      }
      return val?.split(",");
    };
    useEffect(() => {
      if (localStorage.getItem("missingDetails")) {
        let parsedData = JSON.parse(localStorage.getItem("missingDetails"));
        setSelectedEthnicityOptions(filterEthnicity(parsedData?.ethnicity));
        setMissingDetails({
          ...missingDetails,
          dateOfBirth: parsedData?.dateOfBirth,
          mobileNumber: parsedData?.mobileNumber,
          phoneCode: parsedData?.mobileNumber?.split("-")[0],
          nationality: getselectedNationality(parsedData?.nationality),
          payRate: Number(parsedData?.ratePerHour),
          gender: parsedData?.gender,
          // ethnicity: parsedData?.ethnicity,
          // customEthnicity: parsedData?.customEthnicity,
          militaryStatus: parsedData?.militaryStatus,
          wageExpectations: parsedData?.wageExpectationCategory,
          yearsOfExperience: parsedData?.overallYearsOfExperience,
          knownAs: parsedData?.knownAs,
          profileSummary: parsedData?.brief,
          currency: parsedData?.currency,
        });
        setLocationDetails({
          address: parsedData?.address,
          city: parsedData?.city,
          state: parsedData?.state,
          country: parsedData?.country,
          zipCode: parsedData?.zipCode,
        });
        setValue("state", parsedData?.state);
        setValue("city", parsedData?.city);
        setValue("country", parsedData?.country);
        setValue("zipCode", parsedData?.zipCode);
        setValue("address", parsedData?.address);
        setWorkTypes(
          parsedData?.workType?.split(",")?.map((type) => jobType[type])
        );
        setWorkPlace(
          parsedData?.workPlace?.split(",")?.map((type) => getWorkType(type))
        );
        setLatitude(parsedData?.latitude);
        setLongitude(parsedData?.longitude);
        setCurrentZone(parsedData?.shiftTimezone);
        setShifts({
          shiftEndTime: parsedData?.shiftEndTime,
          shiftStartTime: parsedData?.shiftStartTime,
        });
        getCandidateInfo(parsedData?.shiftStartTime, parsedData?.shiftEndTime);
      }
    }, [localStorage.getItem("missingDetails")]);
    const onSubmit = () => {
      let data = {};
      if (missingDetails?.nationality?.alpha_3_code) {
        data.nationality = missingDetails?.nationality?.alpha_3_code
          ? missingDetails?.nationality?.alpha_3_code
          : "";
      }
      const { city, state, country } = locationDetails;
      if (city) data.city = city;
      if (state) data.state = state;
      if (country) data.country = country;
      if (missingDetails?.dateOfBirth) {
        data.dateOfBirth = formatDate(missingDetails?.dateOfBirth);
      }
      if (missingDetails?.gender && missingDetails?.gender != "default") {
        data.gender = missingDetails?.gender;
      }
      let _ethnicity = "";
      if (selectedEthnicityOptions?.length) {
        _ethnicity = selectedEthnicityOptions?.join(",");
        data.ethnicity = _ethnicity;
      } else if (
        missingDetails?.ethnicity === "Other" &&
        missingDetails?.customEthnicity
      ) {
        _ethnicity = missingDetails?.customEthnicity;
        data.ethnicity = _ethnicity;
      } else if (missingDetails?.ethnicity === "Prefer not to say") {
        _ethnicity = missingDetails?.ethnicity;
        data.ethnicity = _ethnicity;
      }
      if (getValues("address")) {
        data.address = getValues("address") ? getValues("address").trim() : "";
      }
      if (locationDetails.zipCode) {
        data.zipCode = locationDetails.zipCode
          ? locationDetails.zipCode.trim()
          : "";
      }
      if (missingDetails.knownAs) {
        data.knownAs = missingDetails.knownAs
          ? missingDetails.knownAs.trim()
          : "";
      }
      if (missingDetails?.militaryStatus) {
        data.militaryStatus =
          missingDetails?.militaryStatus != "default"
            ? missingDetails?.militaryStatus
            : "";
      }
      if (missingDetails?.wageExpectations) {
        data.wageExpectationCategory =
          missingDetails?.wageExpectations != "default"
            ? missingDetails?.wageExpectations
            : "";
      }
      if (shifts?.shiftEndTime) {
        data.shiftEndTime = shifts?.shiftEndTime;
        data.shiftStartTime = shifts?.shiftStartTime;
        data.shiftTimezone = currentZone;
      }
      if (latitude) {
        data.latitude = latitude || null;
        data.longitude = longitude || null;
      }
      if (workType) {
        data.workType = getSelectedWorkTypes();
      }
      if (workPlace) {
        data.workPlace = getSelectedWorkPlaces();
      }
      if (missingDetails?.payRate) {
        data.ratePerHour = String(Number(missingDetails?.payRate)).trim();
        data.currency =
          Number(missingDetails?.currency) == 0 ? "" : missingDetails?.currency;
      }
      if (missingDetails?.mobileNumber) {
        data.mobileNumber =
          missingDetails?.phoneCode +
          "-" +
          (missingDetails?.mobileNumber.split("-").length > 1
            ? missingDetails?.mobileNumber.split("-")[1]
            : missingDetails?.mobileNumber.split("-")[0]);
      }
      if (missingDetails?.yearsOfExperience) {
        data.overallYearsOfExperience = String(
          missingDetails?.yearsOfExperience
        ).trim();
      }
      if (missingDetails?.profileSummary) {
        data.brief = missingDetails?.profileSummary;
      }
      if (candidateInfo?.id) {
        data.id = candidateInfo?.id;
      }
      localStorage.setItem("missingDetails", JSON.stringify(data));
    };
    const handlePhoneCodeChange = (event, newValue) => {
      if (
        missingDetails?.mobileNumber !== "" &&
        /^\d+(?: \d+)*$/.test(
          missingDetails?.mobileNumber.includes("-")
            ? missingDetails?.mobileNumber.split("-")[1]
            : missingDetails?.mobileNumber
        ) == false
      ) {
        setInvalidMobilePhoneError("Please enter valid phone number");
      } else if (
        (missingDetails?.mobileNumber.split("-").length > 1
          ? missingDetails?.mobileNumber.split("-")[1]
          : missingDetails?.mobileNumber.split("-")[0]
        ).length +
          (newValue && newValue.dial_code ? newValue.dial_code.length : 2) >
        15
      ) {
        setInvalidMobilePhoneError("Exceeded maximum character length of 16");
      } else {
        setInvalidMobilePhoneError("");
      }
      setMissingDetails({
        ...missingDetails,
        phoneCode: newValue && newValue.dial_code ? newValue.dial_code : "+1",
      });
    };
    const handleContactNo = (e) => {
      if (
        e.target.value.trimStart() === "" ||
        /^\d+(?: \d+)*$/.test(e.target.value) === false
      ) {
        setInvalidMobilePhoneError("Please enter valid phone number");
      } else if (
        e.target.value?.length + missingDetails?.phoneCode?.length >
        15
      ) {
        setInvalidMobilePhoneError("Exceeded maximum character length of 16");
      } else {
        setInvalidMobilePhoneError("");
      }
      setMissingDetails({
        ...missingDetails,
        mobileNumber: e.target.value.trimStart(),
      });
    };
    const getSkills = (response) => {
      let skills = JSON.parse(response);
      let _formatedSkillsData = [];
      if (skills?.length) {
        let promiseForFormattingSkills = new Promise((resolve, reject) => {
          skills?.forEach((skill, index) => {
            _formatedSkillsData.push({
              id: skill.id,
              skills: skill.skills,
              yearsOfExperience: skill.yearsOfExperience,
              lastUsed:
                skill.lastUsed === "000000" ||
                skill.lastUsed === "197001" ||
                skill.lastUsed === null
                  ? "0"
                  : skill.lastUsed,
            });
            if (index === skills?.length - 1) resolve();
          });
          if (skills?.length === 0) resolve();
        });
        promiseForFormattingSkills.then(() => {
          return _formatedSkillsData;
        });
      }
      return _formatedSkillsData;
    };
    const apiCallForSummary = async () => {
      setIsLoadingForSummary(true);
      const payload = {};
      const candidateInfo = props?.candidate;
      payload.profileTitle = candidateInfo?.profileTitle;
      if (candidateInfo?.skillData) {
          const skilldata = getSkills(candidateInfo?.skillData);
          let skill_Array = [];
          skilldata.map((item, index) => {
              if (item.skills.keyword != "") {
                  skill_Array.push(item.skills.keyword);
              }
          });
          skill_Array.join(",");
          payload.skills = skill_Array;
        }
        payload.yearsOfExperience = candidateInfo.overallYearsOfExperience;
        const encodedParams = Object.keys(payload)
            .map(
                (key) =>
                    `${encodeURIComponent(key)}=${encodeURIComponent(payload[key])}`
            )
            .join("&");
        await ApiCalls.post(
            "candidates/get/ai/generated/profile/summary",
            encodedParams
        )
            .then(async (res) => {
                if (res) {
                    let result = await JSON.parse(res);
                    if (result.includes("Resume Summary:")) {
                        result = result.split("Resume Summary:")[1]?.trim();
                    }
                    if (result.toLowerCase().includes("n/a")) {
                        danger("Profile summary generation failed, please try again.");
                        return;
                    }
                    setMissingDetails({ ...missingDetails, profileSummary: result });
                    setShouldOpenconfirmation(true);
                    setGenerateApiButtonDisable(true);
                } else {
                    danger("Profile summary generation failed, please try again.");
                }
            })
            .catch(() => {
                danger("Profile summary generation failed, please try again.");
                setIsLoadingForSummary(false);
            });
        setIsLoadingForSummary(false);
    };
    const isValidData = () => {
      let data = {};
      if (missingDetails?.nationality?.alpha_3_code) {
        data.nationality = missingDetails?.nationality?.alpha_3_code
          ? missingDetails?.nationality?.alpha_3_code
          : "";
      }
      const { city, state, country } = locationDetails;
      if (city) data.city = city;
      if (state) data.state = state;
      if (country) data.country = country;
      if (missingDetails?.dateOfBirth) {
        data.dateOfBirth = formatDate(missingDetails?.dateOfBirth);
      }
      if (missingDetails?.gender && missingDetails?.gender != "default") {
        data.gender = missingDetails?.gender;
      }
      let _ethnicity = "";
      if (selectedEthnicityOptions?.length) {
        _ethnicity = selectedEthnicityOptions?.join(",");
        data.ethnicity = _ethnicity;
      } else if (
        missingDetails?.ethnicity === "Other" &&
        missingDetails?.customEthnicity
      ) {
        _ethnicity = missingDetails?.customEthnicity;
        data.ethnicity = _ethnicity;
      } else if (missingDetails?.ethnicity === "Prefer not to say") {
        _ethnicity = missingDetails?.ethnicity;
        data.ethnicity = _ethnicity;
      }
      if (getValues("address")) {
        data.address = getValues("address") ? getValues("address").trim() : "";
      }
      if (locationDetails.zipCode) {
        data.zipCode = locationDetails.zipCode
          ? locationDetails.zipCode.trim()
          : "";
      }
      if (missingDetails.knownAs) {
        data.knownAs = missingDetails.knownAs
          ? missingDetails.knownAs.trim()
          : "";
      }
      if (missingDetails?.militaryStatus) {
        data.militaryStatus =
          missingDetails?.militaryStatus != "default"
            ? missingDetails?.militaryStatus
            : "";
      }
      if (missingDetails?.wageExpectations) {
        data.wageExpectationCategory =
          missingDetails?.wageExpectations != "default"
            ? missingDetails?.wageExpectations
            : "";
      }
      if (shifts?.shiftEndTime) {
        data.shiftEndTime = shifts?.shiftEndTime;
        data.shiftStartTime = shifts?.shiftStartTime;
        data.shiftTimezone = currentZone;
      }
      if (latitude) {
        data.latitude = latitude || null;
        data.longitude = longitude || null;
      }
      if (workType?.length) {
        data.workType = getSelectedWorkTypes();
      }
      if (workPlace?.length) {
        data.workPlace = getSelectedWorkPlaces();
      }
      if (missingDetails?.payRate) {
        data.ratePerHour = String(Number(missingDetails?.payRate)).trim();
        data.currency =
          Number(missingDetails?.currency) == 0 ? "" : missingDetails?.currency;
      }
      if (missingDetails?.mobileNumber) {
        data.mobileNumber =
          missingDetails?.phoneCode +
          "-" +
          (missingDetails?.mobileNumber.split("-").length > 1
            ? missingDetails?.mobileNumber.split("-")[1]
            : missingDetails?.mobileNumber.split("-")[0]);
      }
      if (missingDetails?.yearsOfExperience) {
        data.overallYearsOfExperience = String(
          missingDetails?.yearsOfExperience
        ).trim();
      }
      if (missingDetails?.profileSummary) {
        data.brief = missingDetails?.profileSummary;
      }
      if (candidateInfo?.id) {
        data.id = candidateInfo?.id;
      }
      let missingDataKeys = [];
      props?.fieldMapping?.forEach((key) => {
        if (
          !data[key] ||
          (typeof data[key] != "object" && data[key]?.trim() === "")
        ) {
          missingDataKeys.push(key);
        }
        if (key === "resume" && resumeFile?.length) {
          let index = missingDataKeys.indexOf("resume");
          if (index > -1) {
            missingDataKeys?.splice(index, 1);
          }
        }
      });
      console.log(missingDataKeys, "missingDataKeys");
    };
    const activateFileClick = () => {
      fileRef.current?.click();
    };
    const isValidFileType = (fName) => {
      let extensionLists = ["pdf", "doc", "docx"];
      return extensionLists.indexOf(fName.split(".").pop()) > -1;
    };
    function ValidateSingleInput(e) {
      if (!isValidFileType(e.target.files[0]?.name)) {
        danger("Please upload only DOC, DOCX and PDF formats!");
        return false;
      }
      setResumeFile([e.target.files[0]]);
      setFilename(e.target.files[0]?.name);
      props?.setMissedResume([e.target.files[0]]);
    }
    const changeFile = () => {
      fileRef.current.value = null;
    };
    const handleDeleteDocumentWithIndex = (index) => {
      setResumeFile([]);
      setFilename();
      props?.setMissedResume([]);
      success("Document is deleted successfully.");
    };
    const handleChangeEthnicity = (option) => {
      let newValue = [];
      if (option === "Prefer not to say") {
        newValue = ["Prefer not to say"];
        // Unselect other options if "Prefer not to say" is selected
        setSelectedEthnicityOptions(newValue);
      } else if (option === "Other") {
        newValue = ["Other"];
        // Unselect other options if "Other" is selected
        setSelectedEthnicityOptions(newValue);
      } else {
        // If an ethnicity option is selected
        if (
          selectedEthnicityOptions.includes("Prefer not to say") ||
          selectedEthnicityOptions.includes("Other")
        ) {
          // Clear "Other" or "Prefer not to say" if another ethnicity is selected
          newValue = [option];
        } else {
          newValue = selectedEthnicityOptions.includes(option)
            ? selectedEthnicityOptions.filter((opt) => opt !== option) // Remove option if already selected
            : [...selectedEthnicityOptions, option]; // Add option if not already selected
          // Ensure no more than three options are selected
          if (newValue.length > 3) {
            setEthnicityError("You can select up to three ethnicities only.");
            return;
          }
        }
      }
      // If either "Other" or "Prefer not to say" is selected, clear the other options
      if (newValue.includes("Other") || newValue.includes("Prefer not to say")) {
        newValue = newValue.filter(
          (opt) => opt === "Other" || opt === "Prefer not to say"
        );
      }
      // Update selected options and missing details
      setSelectedEthnicityOptions(newValue);
      setMissingDetails((prev) => ({
        ...prev,
        ethnicity: newValue.join(", "),
      }));
      if (newValue.length === 0) {
        setEthnicityError("Ethnicity is required");
      } else {
        setEthnicityError("");
      }
      // Manage the custom input for "Other"
      if (newValue.includes("Other")) {
        setOpenInput(true);
      } else {
        setOpenInput(false);
      }
    };
    const handleRemoveOption = (option) => {
      // Remove the option
      const newValue = selectedEthnicityOptions.filter((opt) => opt !== option);
      setSelectedEthnicityOptions(newValue);
      setMissingDetails((prev) => ({
        ...prev,
        ethnicity: newValue.join(", "),
      }));
      // If "Other" or "Prefer not to say" is removed, close the input
      if (option === "Other" || option === "Prefer not to say") {
        setOpenInput(false);
      }
      if (newValue.length === 0) {
        setEthnicityError("Ethnicity is required");
      } else {
        setEthnicityError("");
      }
    };
    return (
      <>
        <Grid className="p-10 profile">
        {isLoadingForSummary && <Loader />}
          {props?.profileDetails?.length > 0
            ? props?.profileDetails?.map((missing) => {
                return missing === "Date of Birth" ? (
                  <Grid container>
                    <Grid item lg={12} md={12} xs={12} className="pt-10">
                      <Box className="date-of-birth">
                        <Typography className="m-b-0 f-14 font-weight-400 gray7">
                          Date of Birth<span className="text-black">*</span>
                        </Typography>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <DatePicker
                            className={`form-control input-group pt-15 pl-10 inputcontrasts certificate-date`}
                            placeholder="Select date of birth"
                            value={missingDetails?.dateOfBirth}
                            onChange={(date) => {
                              date.setDate(1);
                              setMissingDetails({
                                ...missingDetails,
                                dateOfBirth: date,
                              });
                            }
                            }
                            format="MMM yyyy"
                            views={["month", "year"]}
                            emptyLabel=""
                            disableFuture={true}
                            TextFieldComponent={(params) => (
                              <div style={{ position: "relative" }}>
                                <Input {...params} readOnly />
                              </div>
                            )}
                          />
                        </MuiPickersUtilsProvider>
                      </Box>
                    </Grid>
                  </Grid>
                ) : missing === "Address" ? (
                  <Grid item lg={12} md={12} xs={12} className="pt-10">
                    <Box>
                      <Typography className="m-b-0 f-14 font-weight-400 gray7">
                        Address<span className="text-black">*</span>
                      </Typography>
                      <AddressShortenSearch
                        address={locationDetails?.address}
                        handleAddressSelect={handleAddressSelect}
                        handleLatLong={handleSelectlatLng}
                        isLng={true}
                      />
                      <span className="danger-color error-msg">
                        {" "}
                        {errors.address && errors.address.message}
                      </span>
                    </Box>
                  </Grid>
                ) : missing === "Known as" ? (
                  <Grid item lg={12} md={12} xs={12} className="pt-10">
                    <Box>
                      <Typography className="m-b-0 f-14 font-weight-400 gray7">
                        Known as<span className="text-black">*</span>
                      </Typography>
                      <Input
                        type="text"
                        name="knownAs"
                        id="knownAs"
                        onInput={(e) =>
                          setMissingDetails({
                            ...missingDetails,
                            knownAs: e.target.value,
                          })
                        }
                        className={`form-control input-group m-b-0 inputcontrasts`}
                        //   aria-invalid={errors.knownAs ? "true" : "false"}
                        margin="normal"
                        value={missingDetails?.knownAs}
                        onKeyPress={handleSpaces}
                        placeholder="Enter known as"
                        {...register("knownAs")}
                      />
                      <span className="danger-color error-msg">
                        {" "}
                        {errors.knownAs && errors.knownAs.message}
                      </span>
                    </Box>
                  </Grid>
                ) : missing === "Nationality" ? (
                  <Grid item lg={12} md={12} xs={12} className="pt-10">
                    <Box className="nationality-align nationality-input">
                      <Typography className="m-b-0 f-14 font-weight-400 gray7">
                        Nationality<span className="text-black">*</span>
                      </Typography>
                      <Autocomplete
                        onChange={(event, value) => {
                          setMissingDetails({
                            ...missingDetails,
                            nationality: value,
                          });
                          if (value == null) {
                            setNatinaityError("Nationality is required");
                          } else {
                            setNatinaityError("");
                          }
                        }}
                        onKeyPress={handleSpaces}
                        autoHighlight
                        name="nationality"
                        className={`form-control input-group m-b-0 inputcontrasts`}
                        id="tags-outlined"
                        options={Nationalities}
                        getOptionLabel={(option) => option.nationality}
                        value={missingDetails?.nationality}
                        noOptionsText={
                          <span className="gray9 f-14 font-weight-400">
                            Please select from the list
                          </span>
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            placeholder="Enter nationality"
                            className="input-nationality"
                          />
                        )}
                      />
                      <span className="danger-color error-msg">
                        {" "}
                        {nationalityError}
                      </span>
                    </Box>
                  </Grid>
                ) : missing === "Ethnicity" ? (
                  <Grid item lg={12} md={12} xs={12} className="pt-10">
                    <Box>
                      <Typography className="m-b-0 f-14 font-weight-400 gray7">
                        Ethnicity<span className="text-black">*</span>
                      </Typography>
                      <Box>
                        <Select
                          onMouseDown={props?.handleDropdownClick}
                          inputProps={{ "aria-label": "With label" }}
                          className={`m-b-0 w-100 inputcontrasts form-control input-group`}
                          name="ethnicity"
                          style={{ marginTop: "6px" }}
                          value={
                            selectedEthnicityOptions.length
                              ? selectedEthnicityOptions
                              : "default"
                          }
                          renderValue={(selected) => {
                            if (selected.includes("default")) {
                              return "--Select--";
                            } else {
                              return selected.join(", ") || "--Select--";
                            }
                          }}
                          autoHighlight
                        >
                          <MenuItem value="default" disabled>
                            --Select--
                          </MenuItem>
                          {ethnicityList.map((option, index) => (
                            <MenuItem
                              key={index}
                              onClick={() => handleChangeEthnicity(option)}
                            >
                              <div className="d-flex align-items-center">
                                <Checkbox
                                  checked={selectedEthnicityOptions.includes(
                                    option
                                  )}
                                  className="ethnicity-checkbox"
                                />
                                <Typography className="f-14 font-weight-400 m-l-5 m-t-2">
                                  {option}
                                </Typography>
                              </div>
                            </MenuItem>
                          ))}
                          <MenuItem
                            value="Other"
                            onClick={() => handleChangeEthnicity("Other")}
                          >
                            <Typography className="f-14 font-weight-400 m-l-5 m-t-2">
                              Other
                            </Typography>
                          </MenuItem>
                          <MenuItem
                            value="Prefer not to say"
                            onClick={() =>
                              handleChangeEthnicity("Prefer not to say")
                            }
                          >
                            <Typography className="f-14 font-weight-400 m-l-5 m-t-2">
                              Prefer not to say
                            </Typography>
                          </MenuItem>
                        </Select>
                        <span className="danger-color error-msg">
                          {ethnicityError}
                        </span>
                      </Box>
                      <Box
                        className={selectedEthnicityOptions.length ? "m-t-4" : ""}
                      >
                        {selectedEthnicityOptions.map((option, index) => (
                          <span
                            key={index}
                            className="text-transform-none ethnicity-chip"
                          >
                            <span>{option}</span>
                            <CloseIcon
                              className="small-text-font m-l-5 cursor-hover"
                              style={{ width: "12px" }}
                              onClick={() => handleRemoveOption(option)}
                              tabIndex={0}
                            />
                          </span>
                        ))}
                      </Box>
                      {openInput && (
                        <Grid item lg={12} md={12} xs={12} className="pt-10">
                          <Box>
                            <Input
                              type="text"
                              name="customEthnicity"
                              className={`form-control input-group m-b-0 inputcontrasts `}
                              onInput={(e) => {
                                const customValue = e.target.value;
                                setMissingDetails((prev) => ({
                                  ...prev,
                                  customEthnicity: customValue,
                                }));
                              }}
                              margin="normal"
                              placeholder="Enter ethnicity"
                              {...register("customEthnicity")}
                            />
                            <span className="danger-color error-msg">
                              {errors.customEthnicity &&
                                errors.customEthnicity.message}
                            </span>
                          </Box>
                        </Grid>
                      )}
                    </Box>
                  </Grid>
                ) : missing === "Wage Expectations" ? (
                  <Grid item lg={12} md={12} xs={12} className="pt-10">
                    <Box>
                      <Typography className="m-b-0 f-14 font-weight-400 gray7">
                        Wage Expectations
                        <span className="text-black">*</span>
                      </Typography>
                      <Box>
                        <Select
                          onMouseDown={props?.handleDropdownClick}
                          inputProps={{ "aria-label": "With label" }}
                          className={`m-b-0 w-100 inputcontrasts form-control input-group`}
                          name="WageExpectations"
                          style={{ marginTop: "6px" }}
                          value={
                            missingDetails?.wageExpectations
                              ? missingDetails?.wageExpectations
                              : "default"
                          }
                          onChange={(event) => {
                            setMissingDetails({
                              ...missingDetails,
                              wageExpectations: event.target.value,
                            });
                          }}
                          defaultValue={"default"}
                          autoHighlight
                        >
                          <MenuItem value="default" disabled>
                            --Select--
                          </MenuItem>
                          <MenuItem value="HR">Hourly</MenuItem>
                          <MenuItem value="DA">Daily</MenuItem>
                          <MenuItem value="WK">Weekly</MenuItem>
                          <MenuItem value="FN">Fortnightly</MenuItem>
                          <MenuItem value="MN">Monthly</MenuItem>
                          <MenuItem value="QA">Quarterly</MenuItem>
                          <MenuItem value="HY">Half-Yearly</MenuItem>
                          <MenuItem value="AL">Annually</MenuItem>
                          <MenuItem value="EP">End of Project</MenuItem>
                        </Select>
                      </Box>
                    </Box>
                  </Grid>
                ) : missing === "Military Status" ? (
                  <Grid item lg={12} md={12} xs={12} className="pt-10">
                    <Box>
                      <Typography className="m-b-0 f-14 font-weight-400 gray7">
                        Military Status<span className="text-black">*</span>
                      </Typography>
                      <Box>
                        <Select
                          onMouseDown={props?.handleDropdownClick}
                          inputProps={{ "aria-label": "With label" }}
                          className={`m-b-0 w-100 inputcontrasts form-control input-group`}
                          name="MilitaryStatus"
                          style={{ marginTop: "6px" }}
                          value={
                            missingDetails?.militaryStatus
                              ? missingDetails?.militaryStatus
                              : "default"
                          }
                          onChange={(event) => {
                            setMissingDetails({
                              ...missingDetails,
                              militaryStatus: event.target.value.trim(),
                            });
                          }}
                          defaultValue={"default"}
                          autoHighlight
                        >
                          <MenuItem value="default" disabled>
                            --Select--
                          </MenuItem>
                          <MenuItem value="I am NOT a Veteran">
                            I am NOT a Veteran
                          </MenuItem>
                          <MenuItem value="I currently serve in the Military">
                            I currently serve in the Military
                          </MenuItem>
                          <MenuItem value="I identify as a Veteran, just not a protected veteran">
                            I identify as a Veteran, just not a protected veteran
                          </MenuItem>
                          <MenuItem value="I identify as a Veteran">
                            I identify as a Veteran
                          </MenuItem>
                          <MenuItem value="I identify as one or more of the classification of the protected veteran">
                            I identify as one or more of the classification of the
                            protected veteran
                          </MenuItem>
                          <MenuItem value="I do not wish to self identify">
                            I do not wish to self identify
                          </MenuItem>
                        </Select>
                      </Box>
                    </Box>
                  </Grid>
                ) : missing === "Gender" ? (
                  <Grid item lg={12} md={12} xs={12} className="pt-10">
                    <Box>
                      <Typography className="m-b-0 f-14 font-weight-400 gray7">
                        Gender<span className="text-black">*</span>
                      </Typography>
                      <Box>
                        <Select
                          onMouseDown={props?.handleDropdownClick}
                          inputProps={{ "aria-label": "With label" }}
                          className={`m-b-0 w-100 inputcontrasts form-control input-group`}
                          name="gender"
                          style={{ marginTop: "6px" }}
                          value={
                            missingDetails?.gender
                              ? missingDetails?.gender
                              : "default"
                          }
                          onChange={(e) =>
                            setMissingDetails({
                              ...missingDetails,
                              gender: e.target.value,
                            })
                          }
                          defaultValue={"default"}
                        >
                          <MenuItem value={"default"} disabled>
                            --Select--
                          </MenuItem>
                          <MenuItem value="Male">Male</MenuItem>
                          <MenuItem value="Female">Female</MenuItem>
                          <MenuItem value="Transgender">Transgender</MenuItem>
                          <MenuItem value="Gender neutral">
                            Gender neutral
                          </MenuItem>
                          <MenuItem value="Non binary">Non-binary</MenuItem>
                          <MenuItem value="Agender">Agender</MenuItem>
                          <MenuItem value="Pangender">Pangender</MenuItem>
                          <MenuItem value="Genderqueer">Genderqueer</MenuItem>
                          <MenuItem value="Two-spirit">Two-spirit</MenuItem>
                          <MenuItem value="Third gender">Third gender</MenuItem>
                          <MenuItem value="All">All</MenuItem>
                          <MenuItem value="Combination of these">
                            Combination of these
                          </MenuItem>
                          <MenuItem value="None">None</MenuItem>
                          <MenuItem value="Prefer not to say">
                            Prefer not to say
                          </MenuItem>
                        </Select>
                      </Box>
                    </Box>
                  </Grid>
                ) : missing === "Total Experience" ? (
                  <Grid>
                    <Typography className="f-14 font-weight-400 gray7 pt-10">
                      Overall Years of Experience
                      <span className="text-black">*</span>
                    </Typography>
                    <Input
                      type="text"
                      name="yearsOfExperience"
                      id="yearsOfExperience"
                      onInput={(e) =>
                        setMissingDetails({
                          ...missingDetails,
                          yearsOfExperience: e.target.value,
                        })
                      }
                      className={`form-control input-group m-b-0 pl-10 inputcontrasts ${
                        errors.yearsOfExperience ? "is-invalid" : ""
                      }`}
                      margin="normal"
                      placeholder="Enter overall years of experience"
                      value={missingDetails?.yearsOfExperience}
                      {...register("yearsOfExperience")}
                    />
                    <span className="danger-color error-msg">
                      {" "}
                      {errors.yearsOfExperience &&
                        errors.yearsOfExperience.message}
                    </span>
                  </Grid>
                ) : missing === "Zip Code" ? (
                  <Grid item lg={12} md={12} xs={12} className="pt-10">
                    <Box>
                      <Typography className="m-b-0 f-14 font-weight-400 gray7">
                        Zip Code<span className="text-black">*</span>{" "}
                      </Typography>
                      <ZipcodeSearchInput
                        zipCode={locationDetails.zipCode}
                        country={locationDetails.country}
                        handleZipCodeSelect={handleZipCodeSelect}
                        handleLatLong={handleSelectlatLng}
                        isLng={true}
                        dangerError={
                          props.missingDetails?.includes("Zip Code") &&
                          (locationDetails?.zipCode == null ||
                            locationDetails?.zipCode == "")
                        }
                      />
                      <span className="danger-color error-msg">
                        {" "}
                        {errors.zipCode && errors.zipCode.message}
                      </span>
                    </Box>
                  </Grid>
                ) : missing === "Location" ? (
                  <Grid container spacing={2} className="pt-10">
                    <Grid item lg={4} md={4} xs={12}>
                      <Box className="cityName p-relative">
                        <Typography className="m-b-0 f-14 font-weight-400 gray7">
                          City<span className="text-black">*</span>{" "}
                        </Typography>
                        <LocationSearchInput
                          city={locationDetails.city}
                          onSelectLocation={handleLocationSelect}
                          handleLatLong={handleSelectlatLng}
                          isLng={true}
                        />
                        <span className="danger-color error-msg">
                          {" "}
                          {errors.city && errors.city.message}
                        </span>
                      </Box>
                    </Grid>
                    <Grid item lg={4} md={4} xs={12}>
                      <Box className="inputGroup state-input location-disable">
                        <Typography className="m-b-0 f-14 font-weight-400 gray7">
                          State<span className="text-black">*</span>{" "}
                        </Typography>
                        <Input
                          type="text"
                          name="state"
                          id="state"
                          readOnly
                          value={locationDetails?.state}
                          className={`form-control input-group m-b-0 inputcontrasts input-align`}
                          margin="normal"
                          placeholder="State"
                          disabled
                          {...register("state")}
                        />
                        <span className="danger-color error-msg">
                          {" "}
                          {errors.state && errors.state.message}
                        </span>
                      </Box>
                    </Grid>
                    <Grid item lg={4} md={4} xs={12}>
                      <Box className="inputGroup state-input location-disable">
                        <Typography className="m-b-0 f-14 font-weight-400 gray7">
                          Country<span className="text-black">*</span>{" "}
                        </Typography>
                        <Input
                          type="text"
                          name="country"
                          id="country"
                          readOnly
                          value={locationDetails?.country}
                          className={`form-control input-group m-b-0 inputcontrasts input-align`}
                          margin="normal"
                          disabled
                          placeholder="Country"
                          {...register("country")}
                        />
                        <span className="danger-color error-msg">
                          {" "}
                          {errors.country && errors.country.message}
                        </span>
                      </Box>
                    </Grid>
                  </Grid>
                ) : missing === "Phone Number from Settings" ? (
                  <>
                    <Typography className="m-b-0 f-14 font-weight-400 gray7 pt-10">
                      Phone Number<span className="text-black">*</span>
                    </Typography>
                    <Grid container spacing={1}>
                      <Grid
                        item
                        lg={2}
                        md={2}
                        xs={2}
                        className="reference-input missing-phone-email"
                      >
                        <AutoCompleteCountryCode
                          className="m-b-0 w-100 select-option-section mt-7"
                          defaultValue={
                            missingDetails?.phoneCode
                              ? missingDetails?.phoneCode
                              : "+1"
                          }
                          onChange={handlePhoneCodeChange}
                          // disablePhone={disablePhone}
                        />
                      </Grid>
                      <Grid item lg={10} md={10} xs={10}>
                        <Input
                          classes={{ root: classes.customTextField }}
                          type="text"
                          className={`form-control input-group mb-0 p-0 inputcontrasts ${
                            invalidMobilePhoneError ? "is-invalid" : ""
                          }`}
                          margin="normal"
                          placeholder="Enter phone number"
                          onChange={handleContactNo}
                          value={
                            missingDetails?.mobileNumber?.split("-").length > 1
                              ? missingDetails?.mobileNumber?.split("-")[1]
                              : missingDetails?.mobileNumber?.split("-")[0]
                          }
                          // disabled={disablePhone}
                        />
                        <span className="danger-color error-msg">
                          {" "}
                          {invalidMobilePhoneError}
                        </span>
                      </Grid>
                    </Grid>
                  </>
                ) : null;
              })
            : ""}
          {props?.profileDetailsGroup?.length > 0
            ? props?.profileDetailsGroup?.map((missing) => {
                return missing === "Pay Rate" ? (
                  <>
                    <Typography
                      className={`h4-text heading-text-color pb-10 border-bottom-gray4 ${
                        props?.profileDetailsGroup?.includes("Profile Summary")
                          ? "pt-0"
                          : "pt-20"
                      }`}
                    >
                      Pay Rate<span className="text-black">*</span>
                    </Typography>
                    <Grid
                      item
                      lg={12}
                      md={12}
                      xs={12}
                      className="display-flex pt-10"
                    >
                      <Grid item lg={2} md={2} xs={2}>
                        <Typography className="pr-20">
                          <Box style={{ padding: "0px 0px" }}>
                            <Select
                              onMouseDown={props?.handleDropdownClick}
                              inputProps={{
                                name: "curreny",
                                id: "uncontrolled-native",
                              }}
                              className={`m-b-0 w-100 inputcontrasts form-control input-group discipline-input pay-rate-input-profile ${
                                missingDetails?.currency === null ||
                                missingDetails?.currency === ""
                                  ? "dangerBorder"
                                  : ""
                              }`}
                              name="curreny"
                              defaultValue={missingDetails?.currency}
                              onChange={(e) =>
                                setMissingDetails({
                                  ...missingDetails,
                                  currency: e.target.value,
                                })
                              }
                              displayEmpty
                            >
                              <MenuItem value="USD">USD</MenuItem>
                              <MenuItem value="GBP">GBP</MenuItem>
                              <MenuItem value="EUR">EUR</MenuItem>
                              <MenuItem value="INR">INR</MenuItem>
                            </Select>
                          </Box>
                        </Typography>
                      </Grid>
                      <Grid item lg={4} md={4} xs={4}>
                        <Typography
                          className={`display-flex align-center form-control input-group`}
                        >
                          <Input
                            type="text"
                            name="hourlyRateNumber"
                            id="hourlyRateNumber"
                            onInput={(e) =>
                              setMissingDetails({
                                ...missingDetails,
                                payRate: e.target.value,
                              })
                            }
                            className={`m-b-0 pl-0 inputcontrasts`}
                            margin="normal"
                            placeholder="Enter pay rate"
                            value={missingDetails?.payRate}
                            style={{ width: "75%" }}
                            {...register("hourlyRateNumber")}
                          />
                          <Typography className="f-16 font-weight-600 gray11">
                            /
                          </Typography>
                          <Typography className="pl-5 f-16 font-weight-600 gray11">
                            hour
                          </Typography>
                        </Typography>
                        <span className="danger-color error-msg">
                          {errors.hourlyRateNumber &&
                            errors.hourlyRateNumber.message}
                        </span>
                      </Grid>
                    </Grid>
                  </>
                ) : missing === "Profile Summary" ? (
                  <>
                    <Typography className="h4-text heading-text-color pt-20 pb-10 border-bottom-gray4">
                      Profile Summary<span className="text-black">*</span>
                    </Typography>
                    <Typography className="input-focus-none mt-15">
                      <Grid className={`form-control input-group`}>
                        <Grid
                          container
                          className="p-8 justify-between align-center notification-secondary-bg-color"
                          style={{
                            borderRadius: "8px 8px 0px 0px",
                          }}
                        >
                          <Grid item lg={8} md={8} xs={12}>
                            <Typography className=" p-l-4 prof-summary-text">
                              Write a bio/summary for your profile
                            </Typography>
                          </Grid>
                          <Grid item lg={4} md={4} xs={12}>
                          <Typography className="align-item-right">
                              <Button
                              type="submit"
                              disabled={generateApiButtonDisable}
                              className="btn primary-button cursor-hover aiButton"
                              variant="contained"
                              disableRipple="true"
                              onClick={apiCallForSummary}
                              aria-label="button"
                              >
                              <Sparkle
                              className="p-r-5"
                              width="25px"
                              height="25px"
                              />
                              Generate Summary
                            </Button>
                            </Typography>
                            </Grid>
                        </Grid>
                        <TextareaAutosize
                          minRows={3}
                          maxRows={4}
                          aria-label="maximum height"
                          className={`form-control input-group inputcontrasts summar-border text-justify cursor-auto`}
                          maxLength={2000}
                          inputProps={{ maxLength: 2000 }}
                          onChange={(e) => {
                            if (e.target.value.length === 1) {
                              setMissingDetails({
                                ...missingDetails,
                                profileSummary: e.target.value.toUpperCase(),
                              });
                              return;
                            }
                            if (e.target.value.length === 0) {
                              setShouldOpenconfirmation(false);
                            }
                            setMissingDetails({
                              ...missingDetails,
                              profileSummary: e.target.value,
                            });
                          }}
                          onKeyPress={handleSpaces}
                          name="aboutme"
                          id="aboutme"
                          value={missingDetails?.profileSummary}
                          placeholder="Write here..."
                          style={{ whiteSpace: "pre-line" }}
                        />
                      </Grid>
                      <Typography className="d-flex justify-between">
                        <span className="danger-color error-msg">
                          {missingDetails?.profileSummary?.length > 1000
                            ? "Exceeded maximum character length of 1000"
                            : ""}
                        </span>
                        <Typography align="right" className="f-14 gray7">
                          {missingDetails?.profileSummary?.length
                            ? missingDetails?.profileSummary?.length
                            : 0}
                          /1000
                        </Typography>
                      </Typography>
                    </Typography>
                  </>
                ) : missing === "Work Type" ? (
                  <Grid className="align-left">
                    <Typography className="h4-text heading-text-color pb-10 border-bottom-gray4 pt-20">
                      Work Type
                    </Typography>
                    <Typography className="f-14 font-weight-500 gray7 pt-10">
                      Please select your preferred work type from below options
                      <span className="text-black">*</span>
                    </Typography>
                    <Typography>
                      <Grid container lg={12} md={12} xs={12}>
                        <Grid
                          item
                          lg={2}
                          md={2}
                          xs={12}
                          className="mt-10 display-flex"
                        >
                          <Typography>
                            <Checkbox
                              {...label}
                              checked={workType?.includes("fullTime")}
                              sx={{
                                color: "#667085",
                                padding: "0px",
                                "&.Mui-checked": {
                                  color: getComputedStyle(
                                    document.documentElement
                                  ).getPropertyValue("--primary-color"),
                                  padding: "0px",
                                  "&.hoveer": {
                                    background: "none",
                                    padding: "0px",
                                  },
                                },
                              }}
                              onClick={() => handleCheckboxChange("fullTime")}
                              onKeyPress={() => handleCheckboxChange("fullTime")}
                            />
                          </Typography>
                          <Typography
                            className="small-text-font p-l-5 cursor-hover"
                            onClick={() => handleCheckboxChange("fullTime")}
                            style={{ marginTop: "3px" }}
                            onKeyPress={() => handleCheckboxChange("fullTime")}
                          >
                            Full Time
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          lg={2}
                          md={2}
                          xs={12}
                          className="mt-10 display-flex"
                        >
                          <Typography>
                            <Checkbox
                              {...label}
                              checked={workType?.includes("partTime")}
                              sx={{
                                color: "#667085",
                                padding: "0px",
                                "&.Mui-checked": {
                                  color: getComputedStyle(
                                    document.documentElement
                                  ).getPropertyValue("--primary-color"),
                                  padding: "0px",
                                  "&.hoveer": {
                                    background: "none",
                                    padding: "0px",
                                  },
                                },
                              }}
                              onClick={() => handleCheckboxChange("partTime")}
                              onKeyPress={() => handleCheckboxChange("partTime")}
                            />
                          </Typography>
                          <Typography
                            className="small-text-font p-l-5 cursor-hover"
                            onClick={() => handleCheckboxChange("partTime")}
                            style={{ marginTop: "3px" }}
                            onKeyPress={() => handleCheckboxChange("partTime")}
                          >
                            Part Time
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          lg={3.5}
                          md={3.5}
                          xs={12}
                          className="mt-10 display-flex"
                        >
                          <Typography>
                            <Checkbox
                              {...label}
                              checked={workType?.includes("contract")}
                              sx={{
                                color: "#667085",
                                padding: "0px",
                                "&.Mui-checked": {
                                  color: getComputedStyle(
                                    document.documentElement
                                  ).getPropertyValue("--primary-color"),
                                  padding: "0px",
                                  "&.hoveer": {
                                    background: "none",
                                    padding: "0px",
                                  },
                                },
                              }}
                              onClick={() => handleCheckboxChange("contract")}
                              onKeyPress={() => handleCheckboxChange("contract")}
                            />
                          </Typography>
                          <Typography
                            className="small-text-font p-l-5 cursor-hover"
                            onClick={() => handleCheckboxChange("contract")}
                            style={{ marginTop: "3px" }}
                            onKeyPress={() => handleCheckboxChange("contract")}
                          >
                            Contingent / Contract
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          lg={2}
                          md={2}
                          xs={12}
                          className="mt-10 display-flex"
                        >
                          <Typography>
                            <Checkbox
                              {...label}
                              checked={workType?.includes("internship")}
                              sx={{
                                color: "#667085",
                                padding: "0px",
                                "&.Mui-checked": {
                                  color: getComputedStyle(
                                    document.documentElement
                                  ).getPropertyValue("--primary-color"),
                                  padding: "0px",
                                  "&.hoveer": {
                                    background: "none",
                                    padding: "0px",
                                  },
                                },
                              }}
                              onClick={() => handleCheckboxChange("internship")}
                              onKeyPress={() =>
                                handleCheckboxChange("internship")
                              }
                            />
                          </Typography>
                          <Typography
                            className="small-text-font p-l-5 cursor-hover"
                            onClick={() => handleCheckboxChange("internship")}
                            style={{ marginTop: "3px" }}
                            onKeyPress={() => handleCheckboxChange("internship")}
                          >
                            Internship
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          lg={2}
                          md={2}
                          xs={12}
                          className="mt-10 display-flex"
                        >
                          <Typography>
                            <Checkbox
                              {...label}
                              checked={workType?.includes("other")}
                              sx={{
                                color: "#667085",
                                padding: "0px",
                                "&.Mui-checked": {
                                  color: getComputedStyle(
                                    document.documentElement
                                  ).getPropertyValue("--primary-color"),
                                  padding: "0px",
                                  "&.hoveer": {
                                    background: "none",
                                    padding: "0px",
                                  },
                                },
                              }}
                              onClick={() => handleCheckboxChange("other")}
                              onKeyPress={() => handleCheckboxChange("other")}
                            />
                          </Typography>
                          <Typography
                            className="small-text-font p-l-5 cursor-hover"
                            onClick={() => handleCheckboxChange("other")}
                            style={{ marginTop: "3px" }}
                            onKeyPress={() => handleCheckboxChange("other")}
                          >
                            Other
                          </Typography>
                        </Grid>
                      </Grid>
                    </Typography>
                  </Grid>
                ) : missing === "WorkPlace" ? (
                  <Grid className="align-left pt-20">
                    <Typography className="h4-text heading-text-color pb-10 border-bottom-gray4">
                      Workplace
                    </Typography>
                    <Typography className="f-14 font-weight-500 gray7 pt-10">
                      Please select your preferred workplace from below options
                      <span className="text-black">*</span>
                    </Typography>
                    <Typography>
                      <Grid container lg={12} md={12} xs={12}>
                        <Grid
                          item
                          lg={2}
                          md={2}
                          xs={12}
                          className="mt-10 display-flex"
                        >
                          <Typography>
                            <Checkbox
                              {...label}
                              checked={workPlace?.includes("Remote")}
                              sx={{
                                color: "#667085",
                                padding: "0px",
                                "&.Mui-checked": {
                                  color: getComputedStyle(
                                    document.documentElement
                                  ).getPropertyValue("--primary-color"),
                                  padding: "0px",
                                  "&.hoveer": {
                                    background: "none",
                                    padding: "0px",
                                  },
                                },
                              }}
                              onClick={() => handleWorkPlaceChange("Remote")}
                              onKeyPress={() => handleWorkPlaceChange("Remote")}
                            />
                          </Typography>
                          <Typography
                            className="small-text-font p-l-5 cursor-hover"
                            onClick={() => handleWorkPlaceChange("Remote")}
                            style={{ marginTop: "3px" }}
                            onKeyPress={() => handleWorkPlaceChange("Remote")}
                          >
                            Remote
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          lg={2}
                          md={2}
                          xs={12}
                          className="mt-10 display-flex"
                        >
                          <Typography>
                            <Checkbox
                              {...label}
                              checked={workPlace?.includes("Onsite")}
                              sx={{
                                color: "#667085",
                                padding: "0px",
                                "&.Mui-checked": {
                                  color: getComputedStyle(
                                    document.documentElement
                                  ).getPropertyValue("--primary-color"),
                                  padding: "0px",
                                  "&.hoveer": {
                                    background: "none",
                                    padding: "0px",
                                  },
                                },
                              }}
                              onClick={() => handleWorkPlaceChange("Onsite")}
                              onKeyPress={() => handleWorkPlaceChange("Onsite")}
                            />
                          </Typography>
                          <Typography
                            className="small-text-font p-l-5 cursor-hover"
                            onClick={() => handleWorkPlaceChange("Onsite")}
                            style={{ marginTop: "3px" }}
                            onKeyPress={() => handleWorkPlaceChange("Onsite")}
                          >
                            Onsite
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          lg={2}
                          md={2}
                          xs={12}
                          className="mt-10 display-flex"
                        >
                          <Typography>
                            <Checkbox
                              {...label}
                              checked={workPlace?.includes("Hybrid")}
                              sx={{
                                color: "#667085",
                                padding: "0px",
                                "&.Mui-checked": {
                                  color: getComputedStyle(
                                    document.documentElement
                                  ).getPropertyValue("--primary-color"),
                                  padding: "0px",
                                  "&.hoveer": {
                                    background: "none",
                                    padding: "0px",
                                  },
                                },
                              }}
                              onClick={() => handleWorkPlaceChange("Hybrid")}
                              onKeyPress={() => handleWorkPlaceChange("Hybrid")}
                            />
                          </Typography>
                          <Typography
                            className="small-text-font p-l-5 cursor-hover"
                            onClick={() => handleWorkPlaceChange("Hybrid")}
                            style={{ marginTop: "3px" }}
                            onKeyPress={() => handleWorkPlaceChange("Hybrid")}
                          >
                            Hybrid
                          </Typography>
                        </Grid>
                      </Grid>
                    </Typography>
                  </Grid>
                ) : missing === "What shift are you willing to work?" ? (
                  <>
                    <Typography className="h4-text heading-text-color pt-20 pb-10 border-bottom-gray4">
                      What shift are you willing to work?
                      <span className="text-black">*</span>
                    </Typography>
                    <Grid
                      container
                      spacing={1}
                      lg={12}
                      md={12}
                      xs={12}
                      className="display-flex pt-10"
                    >
                      <Grid item lg={4} md={4} xs={4}>
                        <Typography className="">
                          <Box style={{ padding: "0px 0px" }}>
                            <Select
                              onMouseDown={props?.handleDropdownClick}
                              className={`m-b-0 w-100 inputcontrasts form-control input-group discipline-input pay-rate-input`}
                              name="shift"
                              value={currentShift ? currentShift : "default"}
                              onChange={(event) => {
                                handleSelectChange(event);
                              }}
                              displayEmpty
                            >
                              <MenuItem value="default" disabled>
                                --Select--
                              </MenuItem>
                              <MenuItem value="1st">6:00 AM - 2:00 PM</MenuItem>
                              <MenuItem value="2nd">2:00 PM - 10:00 PM</MenuItem>
                              <MenuItem value="3rd">10:00 PM - 6:00 AM</MenuItem>
                            </Select>
                          </Box>
                        </Typography>
                      </Grid>
                      <Grid item lg={2} md={2} xs={2}>
                        <Typography className="">
                          <Box style={{ padding: "0px 0px" }}>
                            <Select
                              onMouseDown={props?.handleDropdownClick}
                              className="m-b-0 w-100 inputcontrasts form-control input-group discipline-input pay-rate-input"
                              name="shift"
                              value={currentZone ? currentZone : "default"}
                              onChange={(event) => {
                                setCurrentZone(event.target.value);
                              }}
                            >
                              <MenuItem value="EST">EST</MenuItem>
                            </Select>
                          </Box>
                        </Typography>
                      </Grid>
                    </Grid>
                  </>
                  ) : missing === "Resume" ? (
                    <Grid item lg={12} md={12} xs={12} className="pt-10">
                      <Box>
                        <Typography className="m-b-0 f-14 font-weight-400 gray7">
                            Resume
                          <span className="text-black">*</span>
                        </Typography>
                        <Box className="display-flex align-center file-upload create-file galaxy-block ipad-flex">
                              <Typography className="form-control input-group m-b-0 inputcontrasts p-r-5 policy-responsive display-flex justify-between align-center choose-file galaxy-width resume-section">
                                <input
                                  id="contained-button-file"
                                  type="file"
                                  onChange={ValidateSingleInput}
                                  className="w-100 resume-input"
                                  style={{
                                    opacity: "0",
                                    position: "absolute",
                                    left: "0",
                                    top: "0",
                                  }}
                                  aria-label="button-file"
                                />
                                <input
                                  type="text"
                                  id="fakeInput"
                                  placeholder="Select Resume"
                                  className="resume-input"
                                  value={resumeDocuments?.length > 0 ? resumeDocuments[resumeDocuments.length - 1].documentTitle : ""}
                                  style={{
                                    width: "100%",
                                    borderRadius: "8px",
                                    display: "block",
                                    padding: "11px 0",
                                    boxSizing: "border-box",
                                    border: "initial",
                                    height: "3em",
                                  }}
                                />
                                <a
                                  href="javascript:void(0)"
                                  onClick={addNewResumeDocument}
                                  className={isLoadingResume ? "cursor-default text-decoration-none" : "text-decoration-none"}
                                  aria-label="link"
                                >
                                  <Button className="btn secondary-button p-t-5 p-b-5" aria-label="button"
                                    disabled={isLoadingResume}
                                  >
                                    Browse
                                  </Button>
                                </a>
                              </Typography>
                            </Box>
                      </Box>
                    </Grid>
                ) : null;
              })
            : ""}
        </Grid>
        <UploadResumeModal open={openAddResumeModal} setOpenAddResumeModal={setOpenAddResumeModal} jobId={props.jobId ? props.jobId : undefined} candidateId={props.candidateId} setIsLoading={props.setIsLoading} setResumeDocuments={setResumeDocuments} resumeDocuments={resumeDocuments}/>
      </>
    );
  };
  export default MissingDetails;
