import axios from "axios";
import { useNavigate } from "react-router-dom";
import { includes } from "lodash";
import { authHeader } from "../helpers/auth-header";
import { logout, isSessionValid } from "../helpers/helper";
import { danger } from "../helpers/message-box";
import { getPageNotFoundRecode } from "../redux/pageNotFoundReducer";
import store from "../redux/store";

let baseURL = process.env.REACT_APP_API_URL;

const nonDefaultToastApis = ["payrate/reports", "/pool/shortlist"];

const api = axios.create({
  baseURL,
  timeout: 600000,
});

// Add a request interceptor
api.interceptors.request.use(
  async (config) => {
    if (includes(config.url, "public")) {
      return config;
    }
    // Do something before request is sent
    let userToken = authHeader();
    let x_enterprise_id = localStorage.getItem("childEnterpriseId");
    await userToken
      .then((res) => {
        config.headers.common["Authorization"] = res.Authorization;
        config.headers.common["x-access-token"] = res["x-access-token"];
        config.headers.common["enterpriseId"] = localStorage.getItem("enterpriseId") == localStorage.getItem("childEnterpriseId") ? localStorage.getItem("mspEnterpriseId") : localStorage.getItem("enterpriseId");
        if (x_enterprise_id) {
          config.headers.common["x-enterprise-id"] = x_enterprise_id;
        }
        if (localStorage.getItem("synonyms")) {
          config.headers.common["x-synonyms-active"] = "T";
        }
      })
      .catch((e) => {
        // signOut();
      });

    return config;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
api.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    const status = error.response ? error.response.status : null;
    if (status === 401) {
      isSessionValid().then((res) => {
        if (res === true) {
          let navigate = useNavigate();
          navigate("/enterprise/admin-access");
        } else {
          if (window.location.pathname.includes("enterprise")) {
            signOut();
          }
        }
      });
    } else if (status === 404) {
      store.dispatch(getPageNotFoundRecode(true));
    }
    return Promise.reject(error);
  }
);

const signOut = async () => {
  await logout('UNAUTHORIZED_ACCESS');
  window.location.href = "/signin";
};
export default class ApiCalls {
  static get(url, config = null) {
    return new Promise((resolve, reject) => {
      return (
        api
          .get(url, config)
          .then((res) => resolve(res.data.responsePayload))
          // .catch((err) => reject(err?.response?.data));
          .catch((err) => {
              if (
                err?.response &&
                err?.response?.data?.statusCode === 500 &&
                !nonDefaultToastApis.some((api) =>
                  err?.response?.config?.url.includes(api)
                )
              ) {
                danger("Oops! Something went wrong. Please try again later.");
              }
            if (config && axios.isCancel(err)) {
              reject(err);
            } else {
              reject(err?.response?.data);
            }
          })
          );
    });
  }
  static getCSV(url, filename = 'data.csv') {
    return new Promise((resolve, reject) => {
      return (
        api
        .get(url, { responseType: 'text' }) // Since your API returns CSV in text format
        .then((res) => resolve(res.data))
        .catch((err) => {
          if (
            err?.data &&
            err?.data?.statusCode === 500 &&
            !nonDefaultToastApis.some((api) =>
              err?.response?.config?.url.includes(api)
            )
          ) {
            danger("Oops! Something went wrong. Please try again later.");
          }
        reject(err?.data);
          })
          );
    });
  }

  static getEntireResponseData(url) {
    return new Promise((resolve, reject) => {
      return (
        api
          .get(url)
          .then((res) => resolve(res.data))
          // .catch((err) => reject(err?.response?.data));
          .catch((err) => {
            if (
              err?.response &&
              err?.response?.data?.statusCode === 500 &&
              !nonDefaultToastApis.some((api) =>
                err?.response?.config?.url.includes(api)
              )
            ) {
              danger("Oops! Something went wrong. Please try again later.");
            }
            reject(err?.response?.data);
          })
          );
    });
  }
  static postEntireResponseData(url, payload) {
    return new Promise((resolve, reject) => {
      return (
        api
          .post(url, payload)
          .then((res) => resolve(res.data))
          // .catch((err) => reject(err?.response?.data));
          .catch((err) => {
            if (
              err?.response &&
              err?.response?.data?.statusCode === 500 &&
              !nonDefaultToastApis.some((api) =>
                err?.response?.config?.url.includes(api)
              )
            ) {
              danger("Oops! Something went wrong. Please try again later.");
            }
            reject(err?.response?.data);
          })
          );
    });
  }
  static getDetailedResponse(url, payload) {
    return new Promise((resolve, reject) => {
      return (
        api
          .put(url, payload)
          .then((res) => resolve(res?.data))
          // .catch((err) => reject(err?.response?.data));
          .catch((err) => {
            if (
              err?.response &&
              err?.response?.data?.statusCode === 500 &&
              !nonDefaultToastApis.some((api) =>
                err?.response?.config?.url.includes(api)
              )
            ) {
              danger("Oops! Something went wrong. Please try again later.");
            }
            reject(err?.response?.data);
          })
          );
    });
  }
  static post(url, payload, showError = false) {
    return new Promise((resolve, reject) => {
      return (
        api
          .post(url, payload)
          .then((res) => resolve(res.data.responsePayload))
          // .catch((err) => reject(err?.response?.data));
          .catch((err) => {
            if (
              err?.response &&
              err?.response?.data?.statusCode === 500 &&
              !showError &&
              !nonDefaultToastApis.some((api) =>
                err?.response?.config?.url.includes(api)
              )
            ) {
              danger("Oops! Something went wrong. Please try again later.");
            }
            reject(err?.response?.data);
          })
          );
    });
  }

  static put(url, payload) {
    return new Promise((resolve, reject) => {
      return (
        api
          .put(url, payload)
          .then((res) => resolve(res.data.responsePayload))
          // .catch((err) => reject(err?.response?.data));
          .catch((err) => {
            if (
              err?.response &&
              err?.response?.data?.statusCode === 500 &&
              !nonDefaultToastApis.some((api) =>
                err?.response?.config?.url.includes(api)
              )
            ) {
              danger("Oops! Something went wrong. Please try again later.");
            }
            reject(err?.response?.data);
          })
          );
    });
  }

  static patch(url, payload) {
    return new Promise((resolve, reject) => {
      return (
        api
          .patch(url, payload)
          .then((res) => resolve(res.data.responsePayload))
          // .catch((err) => reject(err?.response?.data));
          .catch((err) => {
              if (
                err?.response &&
                err?.response?.data?.statusCode === 500 &&
                !nonDefaultToastApis.some((api) =>
                  err?.response?.config?.url.includes(api)
                )
              ) {
                danger("Oops! Something went wrong. Please try again later.");
              }
            reject(err?.response?.data);
          })
          );
    });
  }

  static delete(url, payload) {
    return new Promise((resolve, reject) => {
      return (
        api
          .delete(url, payload)
          .then((res) => resolve(res.data.responsePayload))
          // .catch((err) => reject(err?.response?.data));
          .catch((err) => {
              if (
                err?.response &&
                err?.response?.data?.statusCode === 500 &&
                !nonDefaultToastApis.some((api) =>
                  err?.response?.config?.url.includes(api)
                )
              ) {
                danger("Oops! Something went wrong. Please try again later.");
              }
            reject(err?.response?.data);
          })
          );
    });
  }
}
