import React, { useState, useEffect, useRef } from "react";
import {
  Grid,
  Typography,
  Box,
  Container,
  TextField,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Modal,
  Tabs,
  Tab,
  useMediaQuery,
  useTheme,
  InputAdornment,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  Checkbox,
  FormControlLabel
} from "@mui/material";
import defaultUser from "../../../assets/images/default-user.svg";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import deleteIcon from "../../../assets/images/deleteIcon.svg";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import attachmentIcon from "../../../assets/images/attachment-icon.svg";
import messageSending from "../../../assets/images/message-sending.svg";
import ApiCalls from "../../../api/customAxios";
import { toast } from "react-toastify";
import moment from "moment";
import CloseIcon from "@mui/icons-material/Close";
import _ from "lodash";
import Resumepdf from "../../../assets/images/extension-icon/pdf-icon.png";
import docIcon from "../../../assets/images/extension-icon/doc-icon.png";
import Loader from "../../Loader";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import closeIcon from "../../../assets/images/close-icon.svg";
import { getDSaaSCandidateIdFromToken } from "../../../helpers/auth-header";
import ConfirmDialog from "../../../Components/shared/ConfirmDialog";
import { success, danger } from "../../../helpers/message-box";
import noJobs from "../../../assets/images/NoJobsFound.svg";
import { checkIsImage, formatDate, handleSpaces, isMinutesOrHours } from "../../../helpers/helper-data";
import { Helmet } from "react-helmet";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import SendIcon from "@mui/icons-material/Send";
import smileIcon from "../../../assets/images/smileIcon.svg";
import Picker from "emoji-picker-react";
import { useSelector } from "react-redux";
import constants from "../../../helpers/constants";
import { ReactComponent as SearchIcon } from "../../../assets/images/new-search-gray-icon.svg";
import ClearIcon from "@mui/icons-material/Clear";
import TooltipShared from "../../../Components/shared/TooltipShared";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { ReactComponent as NewChatIcon } from "../../../assets/images/NewChatIcon.svg";
import noSearchResultsIcon from "../../../assets/images/NoSearchMessagesImage.svg";
import axios from "axios";

const NewChatBox = ({ index, setOpenNewMessageChat, isNewChatMinimized, closeNewChat }) => {
    const [isMinimized, setIsMinimized] = useState(true);
    const hasMounted = useRef(false);
    const appOrigin = useSelector((state) => state.location.origin);
    const enterpriseDetails = useSelector((state) => state.enterprise.enterprise);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openAssessment = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const params = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [openPreviewModal, setOpenPreviewModal] = useState(false);
  const [chats, setChats] = useState([]);
  const [chatsLastMeassage, setChatsLastMessage] = useState("");
  const [messagesJobs, setMessagesJobs] = useState([]);
  const [activeMessageJob, setActiveMessageJob] = useState({});
  const [activeChatIndex, setActiveChatIndex] = useState(0); // by default first chat will be active
  const [activeJobIndex, setActiveJobIndex] = useState(0); // by default first job will be active
  const [currentChat, setCurrentChat] = useState();
  const [currentJob, setCurrentJob] = useState();
  const [messagesGroupedData, setMessagesGroupedData] = useState([]);
  const [messages, setMessages] = useState([]);
  const [currentMessage, setCurrentMessage] = useState("");
  const [fileToSend, setFileToSend] = useState("");
  const [fileName, setFileName] = useState("");
  const [currentImage, setCurrentImage] = useState("");
  const [openDeleteChat, setOpenDeleteChat] = useState(false);
  const [openRemoveAttach, setOpenRemoveAttach] = useState(false);
  const [loaddata, setLoaddata] = useState(false);
  const [inputStr, setInputStr] = useState("");
  const [showPicker, setShowPicker] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [sendOption, setSendOption] = useState(false);
  const [openSendMenu, setOpenSendMenu] = useState(false);
  const inputRef = React.useRef();
  const cancelTokenSource = React.useRef(null);

  const getAllCandidates = () => {
    // Create a new CancelToken source for the current request
    cancelTokenSource.current = axios.CancelToken.source();
    setIsLoading(true);
    ApiCalls.get("enterprises/messages/search-by-name?candidateName=", {
      cancelToken: cancelTokenSource.current.token,
    })
      .then((response) => {
        setChats(response);
        setIsLoading(false);
      })
      .catch(function (error) {
        if (!axios.isCancel(error)) {
          setIsLoading(false);
        }
      });
  };

  useEffect(()=>{
    setIsMinimized(true);
  }, [closeNewChat]);

  useEffect(()=>{
    if (hasMounted.current) {
      setIsMinimized(false);
    } else {
      hasMounted.current = true;
    }
  }, [isNewChatMinimized]);

  useEffect(()=>{
    getAllCandidates();
  }, []);

  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      search(searchKeyword);
    }, constants.DEBOUNCE_TIME);
    return () => clearTimeout(timeoutId);
  }, [searchKeyword]);

  const search = (keyword) => {
    if (cancelTokenSource.current) {
      cancelTokenSource.current.cancel();
    }
    // Create a new CancelToken source for the current request
    cancelTokenSource.current = axios.CancelToken.source();
    setIsLoading(true);
    ApiCalls.get(`enterprises/messages/search-by-name?candidateName=${keyword}`, {
      cancelToken: cancelTokenSource.current.token,
    })
    .then((res)=>{
        setChats(res);
        setIsLoading(false);
    }).catch((err)=>{
      if (!axios.isCancel(err)) {
        setIsLoading(false);
      }
    });
  };

  const handleEnterKey = (e) => {
    setSearchKeyword(e.target.value);
  };
  const clearSearch = async (e) => {
    setSearchKeyword("");
  };
  var onedayBefore = new Date();
  onedayBefore.setDate(onedayBefore.getDate() - 1);
  var currentDateWeekFormat = new Date();
  currentDateWeekFormat.setDate(currentDateWeekFormat.getDate() - 7);

    // useEffect(()=>{
    //     if (isLastChatWindow && openSmsChat) {
    //         setIsMinimized(false);
    //         localStorage.removeItem("OPEN_SMS_CHAT");
    //     }
    // }, [openSmsChat]);

    const toggleMinimize = () => {
        setIsMinimized(!isMinimized);
    };

    const handleCloseChatWindow = (e) => {
        e.stopPropagation();
        let chats = localStorage.getItem("SMS_CHATS");
        if (chats) {
            chats = JSON.parse(chats);
            chats?.splice(index, 1);
            localStorage.setItem("SMS_CHATS", JSON.stringify(chats));
        }
    };

    const handleOpenSMSChat = (chat) => {
        let _chat = {
          id: chat?.id,
          firstName: chat?.firstName,
          lastName: chat?.lastName,
          mediaFile: chat?.mediaFile,
          mediaFileSigned: chat?.mediaFileSigned,
          mobileNumber: (chat?.mobileNumberForAccess === "A" ? chat?.alternateMobileNumber : chat?.mobileNumber),
          profileTitle: chat?.profileTitle,
          isCheckSendSMS: false,
        };
        let chats = localStorage.getItem("SMS_CHATS");
        if (chats) {
          chats = JSON.parse(chats);
          chats = chats?.filter((_chat)=>_chat.id !== chat?.id);
          if (chats?.length === 3) {
            chats = chats?.slice(0, 2);
          }
          chats = [_chat, ...chats];
          localStorage.setItem("SMS_CHATS", JSON.stringify(chats));
          localStorage.setItem("OPEN_SMS_CHAT", true);
        } else {
          localStorage.setItem("SMS_CHATS", JSON.stringify([_chat]));
          localStorage.setItem("OPEN_SMS_CHAT", true);
        }
    };

    return (
        <div className={`chat-window ${isMinimized ? 'minimized' : ''}`}>
            <div className="chat-header" onClick={toggleMinimize}>
                <Typography className='d-flex align-center'>
                <Typography className="gray9 f-16 font-weight-600 chat-title">
                    New Message
                </Typography>
                </Typography>
                <CloseIcon
                    onClick={(e)=>{
                      e.stopPropagation();
                      setOpenNewMessageChat(false);
                      localStorage.removeItem("isNewMessageOpen");
                    }}
                    onKeyPress={(e)=>{
                      e.stopPropagation();
                      setOpenNewMessageChat(false);
                      localStorage.removeItem("isNewMessageOpen");
                    }}
                    tabIndex={0}
                    aria-label="Close icon"
                />
            </div>
            {!isMinimized && (
                <div className="chat-body">
                    {chats?.length >= 0 && (
              <Grid
                container
                item
                lg={12}
                md={12}
                xs={12}
                className="pl-0  pt-0 responsive-chat-section message-height message-listAlign d-block"
              >
                <TextField
                        inputRef={inputRef}
                        name="searchKeyword"
                        type="search"
                        className="inputcontrasts message-search-input position-issue postJob-search search-id new-search mt-1 mb-20 job-search-align"
                        variant="outlined"
                        margin="normal"
                        placeholder="Type a name"
                        sx={{ minWidth: "280px" }}
                        onKeyPress={(e) => {
                          handleSpaces(e);
                        }}
                        onInput={handleEnterKey}
                        // onKeyDown={handleEnterKey}
                        value={searchKeyword}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start" className="mr-0 w-30">
                              <Typography className="search-byId">
                                <Grid
                                  className="border-radius-6 d-flex align-center"
                                  style={{ padding: "12px" }}
                                  aria-label="Search icon"
                                  disableRipple="true"
                                >
                                  <SearchIcon
                                    width="20px"
                                    aria-label="search icon"
                                  />
                                </Grid>
                              </Typography>
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="end">
                              {searchKeyword !== "" && (
                                <ClearIcon
                                  onClick={() => {
                                    inputRef.current.value = "";
                                    clearSearch();
                                  }}
                                  style={{ marginRight: "10px" }}
                                  aria-label="Clear icon"
                                  className="cursor-hover"
                                />
                              )}
                            </InputAdornment>
                          ),
                        }}
                      />
                <Typography style={{ height: "280px", overflowY: "auto" }}>
                {(isLoading ?
                <Grid>
                      {[...Array(12)].map((e, i) => (
                        <Typography className="display-flex align-center" key={i}>
                          <div className="lazy-loader border-none pl-0">
                              <Grid container spacing={1}>
                                <Grid className="shimmerBG img mx-8 px-8 mt-5"></Grid>
                                <Grid item lg={6} md={6} xs={6} className="pl-0">
                                  <Grid className="shimmerBG title-line mx-8 px-8"></Grid>
                                  <Grid className="shimmerBG title-line mx-8 px-8"></Grid>
                                </Grid>
                              </Grid>
                          </div>
                        </Typography>
                      ))}
                  </Grid>
                :
                <>
                {chats.map((chat, index) => {
                  return (
                    <Typography
                      style={{ cursor: "pointer" }}
                      key={index}
                      onClick={() => {
                        handleOpenSMSChat(chat);
                      }}
                      className={`d-flex w-100 justify-between pl-10 py-15 inputcontrasts bg-white`}
                      tabIndex={0}
                      onKeyPress={() => {
                        handleOpenSMSChat(chat);
                      }}
                    >
                      <Grid item lg={12} className={`d-flex ${chat.profileTitle ? "" : "align-center"}`}>
                      <TooltipShared title={(chat?.firstName === "****" && chat?.lastName === "****")
                                                                        ? constants.GDPR_TOOLTIP
                                                                        : ""} className="d-flex">
                        <Typography className="m-r-10">
                          {(chat.mediaFile === "****" ||
                            chat.mediaFileSigned === null ||
                            chat.mediaFileSigned === "null" ||
                            chat.mediaFileSigned === "") && (
                            <Typography
                              className="default-img-border justify-center align-center d-flex primary-shadow-color bg-input f-16 font-weight-700 text-capitalize"
                              width="45px"
                              height="45px"
                              aria-label="Default image"
                            >
                              {chat.firstName.trim().substring(0, 1)}
                            </Typography>
                          )}
                          {chat.mediaFile !== "****" &&
                            chat.mediaFileSigned !== null &&
                            chat.mediaFileSigned !== "null" &&
                            chat.mediaFileSigned !== "" &&
                            !checkIsImage(chat.mediaFileSigned) && (
                              <video
                                width="45px"
                                height="45px"
                                className="default-img-border profile-video-thumbnail cursor-hover"
                                src={chat.mediaFileSigned}
                                onClick={(e) => {
                                  // handleOpenVideoPreview(e,chat.mediaFileSigned)
                                }}
                                muted
                                tabIndex={0}
                                onKeyPress={(e) => {
                                  // handleOpenVideoPreview(e,chat.mediaFileSigned)
                                }}
                              />
                            )}
                          {chat.mediaFile !== "****" &&
                            chat.mediaFileSigned !== null &&
                            chat.mediaFileSigned !== "null" &&
                            chat.mediaFileSigned !== "" &&
                            checkIsImage(chat.mediaFileSigned) && (
                              <img
                                width="45px"
                                height="45px"
                                src={chat.mediaFileSigned}
                                className="default-img-border"
                                alt="img"
                              />
                            )}
                        </Typography>
                        <Typography>
                          <Box className="gray9 f-16 font-weight-600 word-break">
                            {chat.firstName} {chat.lastName}
                          </Box>
                          <Box className="gray7 f-14 font-weight-400 word-break">
                            {chat.profileTitle}
                          </Box>
                        </Typography>
                        </TooltipShared>
                      </Grid>
                    </Typography>
                  );
                })}
                </>
                )}
                {chats?.length === 0 &&
                (isLoading ?
                <Grid>
                      {[...Array(12)].map((e, i) => (
                        <Typography className="display-flex align-center" key={i}>
                          <div className="lazy-loader border-none pl-0">
                              <Grid container spacing={1}>
                                <Grid className="shimmerBG img mx-8 px-8 mt-5"></Grid>
                                <Grid item lg={6} md={6} xs={6} className="pl-0">
                                  <Grid className="shimmerBG title-line mx-8 px-8"></Grid>
                                  <Grid className="shimmerBG title-line mx-8 px-8"></Grid>
                                </Grid>
                              </Grid>
                          </div>
                        </Typography>
                      ))}
                  </Grid>
                :
                <>
                  <img className="launch-videoImage" src={noSearchResultsIcon} alt="img"  width="270px"
                  height="150px"/>
                  <Box className="f-20 mt-10 text-center font-weight-600">
                    No results found
                  </Box>
                  <Box className="f-14 mt-10 text-center font-weight-400 gray9">
                    Unfortunately we could not find any results
                  </Box>
                </>
                )}
                </Typography>
              </Grid>
            )}
                </div>
            )}
        </div>
    );
};

export default NewChatBox;
