import ClearIcon from "@mui/icons-material/Clear";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import {
  Badge,
  Box,
  Button,
  Divider,
  Grid,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
  Tooltip,
  Menu,
  useTheme,
  useMediaQuery,
  Modal,
  Select,
} from "@mui/material";
import moment from "moment";
import React, { useContext, useEffect, useState, useRef, useCallback } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ApiCalls from "../../api/customAxios";
import { ReactComponent as LogoutNavIcon } from "../../assets/images/logout-navIcon.svg";
import { ReactComponent as BeanBagIcon } from "../../assets/images/unFilledBeanBagIcon.svg";
import { ReactComponent as MessageIcon } from "../../assets/images/message-icon.svg";
import { ReactComponent as SupportIcon } from "../../assets/images/Support.svg";
import { AppContext } from "../../contexts/context";
import {
  getEnterpriseLogo,
  getFromLocalStorage,
  isSessionValid,
  logout,
} from "../../helpers/helper";
import { isMinutesOrHours, getUserType } from "../../helpers/helper-data";
import AccessService from "../../Services/Enterprise/Access.service";
import Loader from "../../Views/Loader";
import SearchFilter from "./SearchFilter";
import messageNavIconNew from "../../assets/images/messageNav-icon.svg";
import AnalyticsSideBar from "../EnterprisePortal/AnalyticsSideBar";
import DsaasLogoWhite from "../../assets/images/Dsaas-LOGO-new.svg";
import { ReactComponent as BarsLeftIcon } from "../../assets/images/bars-left.svg";
import { ReactComponent as ChevronUpDown } from "../../assets/images/chevron-up-down.svg";
import { ReactComponent as ChevronDown } from "../../assets/images/chevron-down.svg";
import { ReactComponent as SearchIconNew } from "../../assets/images/search-icon.svg";
import { ReactComponent as MenuIcon } from "../../assets/images/menu-icon.svg";
import SwitchSideBar from "../EnterprisePortal/SwitchSidebar";
import { ReactComponent as SettingsIcon } from "../../assets/images/settings-icon-header.svg";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FeedBackModal from "./FeedBackModal";
import { useSelector } from "react-redux";
import DirectHealthLogo from "../../assets/images/direct-healthcare-logo.svg";
import NeuroDiversityLogo from "../../assets/images/NeuroDiversityLogo.svg";
import { getEnterpriseRecode } from "../../redux/enterpriseDetailReducer";
import { useDispatch } from "react-redux";
const Menus = require("../../menu-control");
import { ReactComponent as JobsWhiteIcon } from "../../assets/images/myJobs-WhiteIcon.svg";
import { ReactComponent as MyCandidatesWhiteIcon } from "../../assets/images/myCandidate-whiteIcon.svg";
import { ReactComponent as TalentPoolActiveIcon } from "../../assets/images/talent-pool-active-icon.svg";
import { ReactComponent as PortalWhiteIcon } from "../../assets/images/candidatePortal-white-Icon.svg";
import { ReactComponent as CampaignActiveIcon } from "../../assets/images/campaign-activeIcon.svg";
import { ReactComponent as AccessActiveIcon } from "../../assets/images/access-activeIcon.svg";
import { ReactComponent as AnalyticsActiveIcon } from "../../assets/images/analytics-active-icon.svg";
import NotificationButton from "../EnterprisePortal/Notification/NotificationButton";
import { ReactComponent as InterviewWhiteIcon } from "../../assets/images/interviewWhiteIcon.svg";
import { ReactComponent as NewCareerPortalIcon } from "../../assets/images/NewCareerPortalIcon.svg";
import { danger, success } from "../../helpers/message-box";
import KeycloakService from "../../keycloak";
import { ReactComponent as ReferCandidate } from "../../assets/images/refer-candidate-icon.svg";
import { ReactComponent as DashboardIcon } from "../../assets/images/dashboard-icon.svg";
import ReferralService from "../../Services/Enterprise/referral.service";
const Constant = require("../../helpers/constants");
import { saveCookie, extractValueFromCookie } from "../../helpers/helper-data";

export default function Header(props) {
  const dispatch = useDispatch();
  const appOrigin = useSelector((state) => state.location.origin);
  const enterpriseDetails = useSelector((state) => state.enterprise.enterprise);
  let navigate = useNavigate();
  const location = useLocation();
  const search = location.search;
  const URLPath = location.pathname;
  const [profilePicture, setProfilePicture] = useState(props.profileImage);
  const [enterpriseName, setEnterpriseName] = useState("");
  const { navbarLogo, setNavbarLogo } = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(true);
  const [childEnterprises, setChildEnterprises] = useState(null);
  const [openfeedback, setOpenFeedback] = useState(false);
  const queryParams = new URLSearchParams(search);
  const keyword = new URLSearchParams(search).get("keyword");
  const type = new URLSearchParams(search).get("type");
  const [isloggedIn, setLoggedIn] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [jobTitleFilterValue, setJobTitleFilterValue] = useState("");
  const [filterValue, setFilterVaue] = useState(keyword ? keyword : "");
  const [showSearchFilter, setShowSearchFilter] = useState(false);
  const [activeUser, setActiveUser] = useState(false);
  const [isWtw, setIsWtw] = useState("");
  const open = Boolean(anchorEl);
  const { setSuperAdminEntityObj } = useContext(AppContext);
  const [enterpriseUser, setEnterpriseUser] = useState(localStorage.getItem("enterpriseId") ? localStorage.getItem("enterpriseId") : null);
  const [enterprisesList, setEnterprisesList] = useState(localStorage.getItem("enterprisesList") ? JSON.parse(localStorage.getItem("enterprisesList")) : []);
  const [openSideAnalytic, setOpenSideAnalytic] = useState(
    localStorage.getItem("sidebarOpened")
      ? localStorage.getItem("sidebarOpened") === "true"
        ? true
        : false
      : true
  );
  const [open1, setOpen] = React.useState(
    localStorage.getItem("sidebarOpened")
      ? localStorage.getItem("sidebarOpened") === "true"
        ? true
        : false
      : true
  );
  const [openAnalytics, setOpenAnalytics] = useState(false);
  const [isEnterprsieMSP, setIsEnterprsieMSP] = useState(false);
  const [anchorEl4, setAnchorEl4] = React.useState(null);
  const [openSwitch, setOpenSwitch] = React.useState(false);
  const [mspReponse, setMspResponse] = useState(null);
  const [userTypes, setUserTypes] = React.useState("");
  const [openNotValidModal, setOpenNotValidModal] = React.useState(false);
  const handleOpenNotValidModal = () => setOpenNotValidModal(true);
  const handleCloseNotValidModal = () => setOpenNotValidModal(false);
  const [debouncedInputValue, setDebouncedInputValue] = React.useState("");
  const indexesToRemove = [0, 1, 2, 3, 4, 5];
  const [totalReferrals, setTotalReferrals] = useState(0);
  const [messagesUnreadCount, setMessagesUnreadCount] = useState(0);
  const [searchTrigger, setSearchTrigger] = useState(false);
  useEffect(() => {
    document.documentElement.style.setProperty("--loader-bg", '#d6d7d9', "important");
    setIsLoading(true);
  }, []);

  const signOut = async (reason = '') => {
    setIsLoading(true);
    await logout(reason);
    setLoggedIn(false);
    setAnchorE2(null);
    navigate("/signin");
    setIsLoading(false);
    document.documentElement.style.setProperty(
      "--primary-color",
      (appOrigin === "N" ? "#055094" : appOrigin === "H" ? "#45AC8B" : "#0071E3"),
      "important"
    );
    document.documentElement.style.setProperty(
      "--secondary-color",
      (appOrigin === "N" ? "#EAF4FF" : appOrigin === "H" ? "#F0FFFA" : "#E5f0FC"),
      "important"
    );
  };
  const isBeanBagUserType = ["Z", "T", "R", "A"];
  const beanBagSSOLogin = () => {
    setAnchorE2(null);
    window.open(
      `${process.env.REACT_APP_BEANBAG_BASE_URL}/sso-login?appOrigin=${appOrigin}&enterpriseId=${localStorage.getItem("enterpriseId")}`,
      "_blank",
      "noopener,noreferrer"
    );
  };
  useEffect(() => {
    if (localStorage.getItem("enterprisesList")) {
      let res = JSON.parse(localStorage.getItem("enterprisesList"));
      setEnterprisesList(res);
      const obj = {};
      res?.map((data) => {
        data?.enterprisesUsers?.forEach((user) => {
          if (user.emailAddress === localStorage.getItem("email")) {
            obj[data.id] = user.userType;
          }
        });
      });
      setSuperAdminEntityObj(obj);
      const lastLoggedInEnterprise = res?.length > 0 ? res?.find((item) => {
        return item.enterpriseId === extractValueFromCookie("enterprise_id", document.cookie);
      }) : [];
      if (lastLoggedInEnterprise) {
        setEnterpriseUser(lastLoggedInEnterprise?.id);
      }
    }
  }, [localStorage.getItem("enterprisesList")]);
  // React.useEffect(() => {
  //   if (KeycloakService.isLoggedIn() && !URLPath?.includes("enterprise/CandidateList") && !URLPath?.includes("enterprise/candidate-detail")) {
  //     if (localStorage.getItem("enterpriseId")) {
  //       const id = localStorage.getItem("enterpriseId");
  //       AccessService.getLoggedInUserDetails(id).then(
  //         (response) => {
  //           if (mounted) {
  //             if (response.userType == "A") {
  //               navigate("/enterprise/access");
  //               }
  //           }
  //         },
  //         (error) => { }
  //       );
  //     } else {
  //       AccessService.getUserList().then(
  //         (response) => {
  //           localStorage.setItem("enterpriseId", response.id);
  //             let arr = response.enterprisesUsers;
  //             for (let i = 0; i < arr.length; i++) {
  //               if (arr[i].emailAddress === localStorage.getItem("email")) {
  //                   if (arr[i].userType == "A") {
  //                   navigate("/enterprise/access");
  //                   }
  //               }
  //             }
  //         },
  //         (error) => { }
  //       );
  //     }
  //   }
  // }, []);
  const EnterpriseMenu = [
    {
      name: "Jobs",
      url: "/enterprise/JobList",
      icon: JobsWhiteIcon,
      role: activeUser ? ["All"] : Menus?.jobs,
      activeUrls: ["JobList", "jobList", "previewJob", "postJob", "screeningQuestions", "jobs", "matching-candidate-description", "admin-access", "schedule-interviews", "jobWizard"]
    },
    {
      name: "Talent Console",
      url: "/enterprise/talent-console",
      icon: DashboardIcon,
      role: (activeUser || (isWtw == "" || isWtw == null)) ? [] : Menus?.dashboard,
      activeUrls: ["talent-console", "talent-console-candidates"]
    },
    {
      name: "Candidates",
      url: "/enterprise/my-candidate",
      icon: MyCandidatesWhiteIcon,
      role: activeUser ? [] : Menus?.candidates,
      activeUrls: ["my-candidate", "my-candidate"]
    },
    {
      name: "Campaign",
      url: "/enterprise/campaignList",
      icon: CampaignActiveIcon,
      role: activeUser ? [] : Menus?.campaign,
      activeUrls: ["campaignList", "createCampaign", "campaignDetail", "edit-campaign"]
    },
    {
      name: "Interviews",
      url: "/enterprise/interviewList",
      icon: InterviewWhiteIcon,
      role: activeUser ? [] : Menus?.interviews,
      activeUrls: ["interviewList", "interview-details", "schedule-interview"]
    },
    {
      name: "Talent Pool",
      url: "/enterprise/talent-pool-management",
      icon: TalentPoolActiveIcon,
      role: activeUser ? [] : Menus?.talentpool,
      activeUrls: ["talent-pool-management", "create-talent-pool", "talent-pool-candidate-list", "talent-pool"]
    },
    {
      name: "Career Portal",
      url: "Career Portal",
      icon: NewCareerPortalIcon,
      role: activeUser ? [] : Menus?.careerportal,
      activeUrls: []
    },
    {
      name: "Configuration Control",
      url: "/enterprise/access",
      icon: AccessActiveIcon,
      role: activeUser ? [] : Menus?.configurationcontrol,
      activeUrls: ["access"]
    },
    {
      name: "Analytics",
      url: "Analytics",
      icon: AnalyticsActiveIcon,
      role: activeUser ? [] : Menus?.analytics,
      activeUrls: ["Analytics"]
    }];

  const open4 = Boolean(anchorEl4);
  const id = open4 ? "simple-popover" : undefined;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const handleMenuBar = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleStateChange = (text) => {
    setFilterVaue(text);
  };
  const handleCloseMenu = (event) => {
    setAnchorEl(null);
  };
  useEffect(() => {
    if (localStorage.getItem("activetStep") && !location.pathname.includes("jobWizard")) {
      localStorage.removeItem("chatHistory");
      localStorage.removeItem("activetStep");
      localStorage.removeItem("activetStepIndex");
      localStorage.removeItem("activetStepResponse");
    }
  }, [location]);
  useEffect(() => {
    isSessionValid().then((res) => {
      if (res) {
        setLoggedIn(res);
      } else {
        navigate("/signin");
      }
    });
    let mspToken = localStorage.getItem("msp-token");
    if (mspToken) {
      const InviteCodeObj = {
        inviteCode: mspToken,
      };
      AccessService.verfiyMspinviteCode(InviteCodeObj)
        .then((res) => {
          localStorage.removeItem("msp-token");
        });
    }
  }, []);
  useEffect(() => {
    if (
      !(
        location.pathname.includes("enterprise/CandidateList") ||
        location.pathname.includes("enterprise/candidate-detail")
      )
    ) {
      localStorage.removeItem("advanceFilterObj");
    }
  }, [location.pathname]);
  useEffect(() => {
    if (keyword === null) {
      setFilterVaue("");
    } else {
      setFilterVaue(keyword);
    }
  }, [keyword]);

  useEffect(() => {
    if (props.profileImage) {
      if (props.profileImage === "deleted") {
        setProfilePicture(null);
      } else {
        setProfilePicture(props.profileImage);
      }
    }
  }, [props]);

  useEffect(() => {
    setNavbarLogo(navbarLogo);
  }, [navbarLogo]);
  const setUserTypeData = async(user) => {
    // for (let i = 0; i < users?.length; i++) {
    //   if (users[i].emailAddress === getFromLocalStorage("email")) {
        let currentDate = new Date();
        if (user.accessValidTill) {
          let accessValidTillDate = new Date(user.accessValidTill);
          if (currentDate > accessValidTillDate) {
            handleOpenNotValidModal();
            // setTimeout(() => {
            //   signOut();
            // }, 2000);
          }
        }
        localStorage.setItem("candidateId", user.candidateId);
        localStorage.setItem(
          "name",
          user?.firstName +
          " " +
          (user?.lastName !== null ? user?.lastName : "")
        );
        setUserTypes(user.userType);
        localStorage.setItem("userType", user.userType);
        localStorage.setItem("activeOrNot", user.activeOrNot);
        localStorage.setItem("enterprise_user_id", user.id);
        localStorage.setItem("phone", user.mobileNumber);
        if (user.availableForInterviewFrom) {
          localStorage.setItem("availableForInterviewFrom", user.availableForInterviewFrom);
        }
        if (user.availableForInterviewTill) {
          localStorage.setItem("availableForInterviewTill", user.availableForInterviewTill);
        }
        if (user.availableWeekdays) {
          localStorage.setItem("availableWeekdays", user.availableWeekdays);
        }
        localStorage.setItem("calenderCredentialExists", user?.calenderCredentialExists);
        if (user.userType !== "A" || user.userType !== "Z") {
          localStorage.setItem("profileTitle", user.profileTitle);
          localStorage.setItem("hideAccessControl", true);
        } else {
          localStorage.setItem("profileTitle", user.profileTitle);
          localStorage.removeItem("hideAccessControl");
        }
  };

  const EnterprisesList = async () => {
    await AccessService.getEnterprises().then((res) => {
      // if (localStorage.getItem("enterpriseId")) {
        AccessService.getLoggedInUserDetails(localStorage.getItem("mspEnterpriseId") ? localStorage.getItem("mspEnterpriseId") : res.id).then(
          async(response) => {
            await setUserTypeData(response);
            if (KeycloakService.isLoggedIn() && !URLPath?.includes("enterprise/CandidateList") && !URLPath?.includes("enterprise/candidate-detail")) {
              if (response.userType == "A") {
                navigate("/enterprise/access");
              }
            }
              // setUserTypes(response.userType);
          },
          (error) => { }
        );
      // }
      setIsWtw(res.customConfigurations);
      dispatch(getEnterpriseRecode(res));
      setMspResponse(res);
      setActiveUser(false);
      let primaryColor = (appOrigin === "N" ?  "#055094" : appOrigin === "H" ? "#45AC8B" : "#0071E3");
      let secondaryColor = (appOrigin === "N" ? "#EAF4FF" : appOrigin === "H" ? "#F0FFFA" : "#E5f0FC");
      let hoverButtonColor = (appOrigin === "N" ?  "#055094" : appOrigin === "H" ? "#45AC8B" : "#0071E3");
      let hoverButtonTextColor = "#FFFFFF";
      let headingTextColor = "#101828";
      let mainTextColor = (appOrigin === "N" ?  "#055094" : appOrigin === "H" ? "#45AC8B" : "#0071E3");
      let listHoverColor = "#F9FAFB";
      try {
        let themes = res.theme ? JSON.parse(res.theme) : [];
        let selectedTheme = themes?.filter((theme)=>(theme.isSelected));
        if (selectedTheme.length) {
          selectedTheme = selectedTheme[0];
          primaryColor = "#" + selectedTheme.primaryColor;
          secondaryColor = "#" + selectedTheme.secondaryColor;
          hoverButtonColor = "#" + selectedTheme.hoverButtonColor;
          hoverButtonTextColor = "#" + selectedTheme.hoverButtonTextColor;
          headingTextColor = "#" + selectedTheme.headingTextColor;
          mainTextColor = "#" + selectedTheme.mainTextColor;
          listHoverColor = "#" + selectedTheme.listHoverColor;
        }
      } catch (err) {}
      localStorage.setItem("primary_color", primaryColor);
      document.documentElement.style.setProperty(
        "--primary-color",
        primaryColor,
        "important"
      );
      document.documentElement.style.setProperty(
        "--secondary-color",
        secondaryColor,
        "important"
      );
      document.documentElement.style.setProperty(
        "--hover-button-color",
        hoverButtonColor,
        "important"
      );
      document.documentElement.style.setProperty(
        "--hover-button-text-color",
        hoverButtonTextColor,
        "important"
      );
      document.documentElement.style.setProperty(
        "--heading-text-color",
        headingTextColor,
        "important"
      );
      document.documentElement.style.setProperty(
        "--main-text-color",
        mainTextColor,
        "important"
      );
      document.documentElement.style.setProperty(
        "--list-hover-color",
        listHoverColor,
        "important"
      );
      // const mspResponse = localStorage.getItem("mspEnterprsieData")
      //   ? JSON.parse(localStorage.getItem("mspEnterprsieData"))
      //   : null;
      // let mspName = mspResponse?.establishmentName;
      // localStorage.setItem("mspEnterpriseName", mspName);
      localStorage.setItem("isBeanBagUser", res.isBeanBagUser);
      localStorage.setItem("isBeanbagJobBoardActive", res.isBeanbagJobBoardActive);
      localStorage.setItem("beanbagMode", res.beanbagMode);
      let currentUser = (localStorage.getItem("enterprisesList") != null ? JSON.parse(localStorage.getItem("enterprisesList")) : [])?.filter((item) => {
        return (localStorage.getItem("childEnterpriseId") ? item.id === localStorage.getItem("childEnterpriseId") : item.id === extractValueFromCookie("enterprise_id", document.cookie));
      });
      if (currentUser?.length > 0) {
        localStorage.setItem("enterpriseId", currentUser[0]?.id);
      } else {
        localStorage.setItem("enterpriseId", localStorage.getItem("mspEnterpriseId") ? localStorage.getItem("mspEnterpriseId") : res.id);
      }
      if (res.establishmentName) {
        localStorage.setItem("enterpriseName", res.establishmentName);
      }
      if (res.logoFileSignedUrl) {
        localStorage.setItem("enterpriseLogo", res.logoFileSignedUrl);
      }
      if (res?.navBarLogoSignedUrl) {
        localStorage.setItem("navbarLogo", res.navBarLogoSignedUrl);
        setNavbarLogo(res.navBarLogoSignedUrl);
      } else {
        setNavbarLogo(null);
      }
      if (res.customWeightage) {
        localStorage.setItem("showMatchingScore", true);
      } else {
        localStorage.setItem("showMatchingScore", false);
      }
      if (res?.isManagedServiceProvider === "Y") {
        setIsEnterprsieMSP(true);
        localStorage.setItem("mspEnterprsieData", JSON.stringify(res));
      }
      if (res.childEnterprises && res.childEnterprises.length > 0) {
        setChildEnterprises(res.childEnterprises);
      }
      if (res.logoFileSignedUrl) setProfilePicture(res.logoFileSignedUrl);
      if (res.establishmentName) {
        setEnterpriseName(res.establishmentName);
      }
      if (res.navBarLogoSignedUrl) {
        localStorage.setItem("navbarLogo", res.navBarLogoSignedUrl);
        setNavbarLogo(res.navBarLogoSignedUrl);
      }
      const mspResponse = localStorage.getItem("mspEnterprsieData")
        ? JSON.parse(localStorage.getItem("mspEnterprsieData"))
        : null;
      let mspName = mspResponse?.establishmentName;
      if (mspName) {
        localStorage.setItem("mspEnterpriseName", mspName);
      }
      let users = res.enterprisesUsers;
      if (users) {
        // for (let i = 0; i < users?.length; i++) {
        //   if (users[i].emailAddress === getFromLocalStorage("email")) {
        //     let currentDate = new Date();
        //     if (users[i].accessValidTill) {
        //       let accessValidTillDate = new Date(users[i].accessValidTill);
        //       if (currentDate > accessValidTillDate) {
        //         handleOpenNotValidModal();
        //         // setTimeout(() => {
        //         //   signOut();
        //         // }, 2000);
        //       }
        //     }
        //     localStorage.setItem("candidateId", users[i].candidateId);
        //     localStorage.setItem(
        //       "name",
        //       users[i]?.firstName +
        //       " " +
        //       (users[i]?.lastName !== null ? users[i]?.lastName : "")
        //     );
        //     console.log(
        //       users[i]?.firstName +
        //       " " +
        //       (users[i]?.lastName !== null ? users[i]?.lastName : "")
        //     );
        //     setUserTypes(users[i].userType);
        //     localStorage.setItem("userType", users[i].userType);
        //     localStorage.setItem("activeOrNot", users[i].activeOrNot);
        //     localStorage.setItem("enterprise_user_id", users[i].id);
        //     localStorage.setItem("phone", users[i].mobileNumber);
        //     if (users[i].availableForInterviewFrom) {
        //       localStorage.setItem("availableForInterviewFrom", users[i].availableForInterviewFrom);
        //     }
        //     if (users[i].availableForInterviewTill) {
        //       localStorage.setItem("availableForInterviewTill", users[i].availableForInterviewTill);
        //     }
        //     if (users[i].availableWeekdays) {
        //       localStorage.setItem("availableWeekdays", users[i].availableWeekdays);
        //     }
        //     localStorage.setItem("calenderCredentialExists", users[i]?.calenderCredentialExists);
        //     if (users[i].userType !== "A" || users[i].userType !== "Z") {
        //       localStorage.setItem("profileTitle", users[i].profileTitle);
        //       localStorage.setItem("hideAccessControl", true);
        //     } else {
        //       localStorage.setItem("profileTitle", users[i].profileTitle);
        //       localStorage.removeItem("hideAccessControl");
        //     }
        //     break;
        //   }
        // }
      } else {
        AccessService.getUsers(0, 10).then((response) => {
          let { responsePayload } = { ...response };
          let currentDate = new Date();
          if (responsePayload[0].accessValidTill) {
            let accessValidTillDate = new Date(
              responsePayload[0].accessValidTill
            );
            if (currentDate > accessValidTillDate) {
              handleOpenNotValidModal();
              // setTimeout(() => {
              //   signOut();
              // }, 2000);
            }
          }
        });
      }
      if (!localStorage.getItem("enterpriseId")) {
        localStorage.setItem("enterpriseName", res.establishmentName);
        const lastLoggedInEnterprise = (localStorage.getItem("enterprisesList") != null ? JSON.parse(localStorage.getItem("enterprisesList")) : [])?.find((item) => {
          return item.id === extractValueFromCookie("enterprise_id", document.cookie);
        });
        localStorage.setItem("enterpriseId", lastLoggedInEnterprise ? lastLoggedInEnterprise?.id : res.id);
        if (res.logoFileSignedUrl) {
          localStorage.setItem("enterpriseLogo", res.logoFileSignedUrl);
        }
        if (res.navBarLogoSignedUrl) {
          localStorage.setItem("navbarLogo", res.navBarLogoSignedUrl);
          setNavbarLogo(res.navBarLogoSignedUrl);
        }
      }
      if (res.domainIdentifierVerificationInitiatedOn != null && res.domainIdentifierVerifiedOn == null && !localStorage.getItem("childEnterpriseId")) {
        ApiCalls.put(`enterprises/verify/domain-status?enterpriseDomain=${res.validDomainNames?.includes(",") ? res?.validDomainNames?.split(",")[0] : res?.validDomainNames}`).then((res) => { });
      };
    }).catch((e) => {
      if (e?.statusCode == 403) {
        setActiveUser(true);
      }
      setIsLoading(false);
      document.documentElement.style.setProperty("--loader-bg", 'rgba(0, 0, 0, 0.2)', "important");
    });
    setIsLoading(false);
    document.documentElement.style.setProperty("--loader-bg", 'rgba(0, 0, 0, 0.2)', "important");
  };

  const getReferralCount = () => {
    ReferralService.referralCount()
  .then((res) => {
    setTotalReferrals(res.referralCount);
    localStorage.removeItem("referral-success");
  })
  .catch(function (error) {
  });
};

  useEffect(() => {
    EnterprisesList();
    ApiCalls.get("candidates/messages/unread-count?enterpriseId="+ (localStorage.getItem("childEnterpriseId") ? localStorage.getItem("childEnterpriseId") : localStorage.getItem("enterpriseId")))
    .then((res)=>{
      setMessagesUnreadCount(res);
    }).catch((err)=>{});
    const interval = setInterval(() => {
      ApiCalls.get("candidates/messages/unread-count?enterpriseId="+ (localStorage.getItem("childEnterpriseId") ? localStorage.getItem("childEnterpriseId") : localStorage.getItem("enterpriseId")))
      .then((res)=>{
        setMessagesUnreadCount(res);
      }).catch((err)=>{});
    }, 10000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (queryParams.get("kc_client_msg") && queryParams.get("kc_client_msg") === 'UPDATE_PASSWORD_SUCCESS') {
      success("Password is changed successfully.");
    }
  }, []);
  useEffect(() => {
    getReferralCount();
  }, [localStorage.getItem("referral-success") != null]);

  useEffect(() => {
    const handleInvalidToken = (e) => {
      isSessionValid().then((res) => {
        if (res === false) {
          window.location.href = "/signin";
        }
      });
    };

    window.addEventListener("storage", handleInvalidToken);

    return function cleanup() {
      window.removeEventListener("storage", handleInvalidToken);
    };
  }, []);

  const [anchorE2, setAnchorE2] = useState(null);
  const open2 = Boolean(anchorE2);

  const handleClick2 = (event) => {
    setAnchorE2(event.currentTarget);
    setAnchorE3(null);
  };

  const handleClose2 = () => {
    setAnchorE2(null);
  };

  const [anchorE3, setAnchorE3] = useState(null);
  const viewAllNotification = () => {
    setAnchorEl(null);
    setAnchorE2(null);
  };
  const handleOpenFeedBack = () => {
    setOpenFeedback(true);
  };
  const handleFeebackModalClose = useCallback(() => {
    setOpenFeedback(false);
  }, []);
  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShowSearchFilter(true);
      setJobTitleFilterValue(debouncedInputValue);
    }, Constant.DEBOUNCE_TIME);
    return () => clearTimeout(timeoutId);
  }, [debouncedInputValue]);

  const handleJobTitleSearch = (e) => {
    setFilterVaue(e.target.value);
    setDebouncedInputValue(e.target.value);
    // if (location.pathname.includes('enterprise/CandidateList')) {
    //     navigate('/enterprise/CandidateList?keyword=' + e.target.value.split(','));
    // } else {
    // setShowSearchFilter(true);
    // setJobTitleFilterValue(e.target.value);
    if (
      e.target.value === "" &&
      location.pathname.includes("enterprise/CandidateList")
    ) {
      window.location.href = `/enterprise/CandidateList`;
    }
    // }
  };
  const handleEnterKey = (e) => {
    if (e.key === "Enter") {
      setSearchTrigger((prev) => !prev);
      // if (location.pathname.includes("enterprise/CandidateList")) {
      navigate(
        "/enterprise/CandidateList?keyword=" +
          encodeURIComponent(filterValue.split(",")) +
          `&type=K&triggerSearch=${searchTrigger}`
      );
      // navigate(
      //   "/enterprise/CandidateList?keyword=" +
      //   encodeURIComponent(filterValue.split(",")) +
      //   "&type=K"
      // );
      localStorage.removeItem("searchId");
      setShowSearchFilter(false);
      // } else {
      //   setShowSearchFilter(true);
      //   setJobTitleFilterValue(filterValue);
      // }
    }
  };
  function goToMessages() {
    navigate("/enterprise/message-list");
    setAnchorE2(null);
  }

  const selectText = (event) => {
    event.currentTarget?.select();
  };

  const handleMenu = () => {
    setOpenAnalytics(true);
  };

  const [anchorEl5, setAnchorEl5] = useState(null);
  const menuRef = useRef(null);

  const handleClick5 = (event) => {
    setAnchorEl5(event.currentTarget);
  };

  const handleClose5 = () => {
    setAnchorEl5(null);
  };

  useEffect(() => {
    const handleMouseDown = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        handleClose();
      }
    };
    document.addEventListener("mousedown", handleMouseDown);
    return () => {
      document.removeEventListener("mousedown", handleMouseDown);
    };
  }, []);

  const getFormattedFilterValue = (name, type) => {
    if (type === "M") {
      if (name === "F") {
        return "full time";
      } else if (name === "P") {
        return "part time";
      } else if (name === "D") {
        return "distance education";
      }
    } else if (type === "L") {
      if (name === "B") {
        return "bachelors";
      } else if (name === "P") {
        return "doctorate";
      } else if (name === "M") {
        return "masters";
      } else if (name === "D") {
        return "diploma";
      } else if (name === "S") {
        return "school";
      }
    }
    return name;
  };
  function goToMyReferrals() {
    setAnchorE2(null);
    navigate("/enterprise/referrals");
  }
  const goToSettingPage = () => {
    setAnchorE2(null);
    navigate("/enterprise/settings");
  };
  useEffect(() => {
    if (appOrigin === "H") {
      document.querySelector("link[title=icon]").setAttribute("href", "/healthcare-favicon.png");
    } else if (appOrigin === "N") {
      document.querySelector("link[title=icon]").setAttribute("href", "/neuro-favicon.png");
    }
  }, []);
  const handleNotValidEnterprise = () => {
    handleCloseNotValidModal();
    signOut('ACCESS_EXPIRED');
  };
  // useEffect(() => {
  //   let mounted = true;
  //   if (localStorage.getItem("enterpriseId")) {
  //     const id = localStorage.getItem("enterpriseId");
  //     AccessService.getLoggedInUserDetails(id).then(
  //       (response) => {
  //         if (mounted) {
  //           setUserTypes(response.userType);
  //         }
  //       },
  //       (error) => { }
  //     );
  //   } else {
  //     AccessService.getUserList().then(
  //       (response) => {
  //       localStorage.setItem("enterpriseId", response.id);
  //         if (mounted) {
  //           alert("awepqqq");
  //           setMspResponse(response);
  //           let arr = response.enterprisesUsers;
  //           for (let i = 0; i < arr.length; i++) {
  //             if (arr[i].emailAddress === localStorage.getItem("email")) {
  //               setUserTypes(arr[i].userType);
  //             }
  //           }
  //           // setEnterpriseAccess(arr);
  //         };
  //       },
  //       (error) => { }
  //     );
  //   }
  //   return () => (mounted = false);
  // }, []);
  const navigateTourl = (url) => {
    handleCloseMenu();
    navigate(url);
  };
  function removeMultipleIndexes(arr, indexesToRemove) {
    let myArray = arr.filter((_, index) => !indexesToRemove.includes(index));
    return [myArray[0], myArray[1], myArray[2]] = [myArray[1], myArray[0], myArray[2]];
  };
  const handleSelectEnterprise = (event) => {
    setIsLoading(true);
    let currentUser = enterprisesList?.find((item) => {
      return item.id === event.target.value;
    });
      setEnterpriseUser(event.target.value);
      //Added REACT_APP_KEYCLOAK_REALM condition to execute the code only in prod because custom urls not working in other environment's
      if (currentUser?.customBaseUrl != null && localStorage.getItem("enterpriseId") != event.target.value && window.location.origin != currentUser?.customBaseUrl && process.env.REACT_APP_KEYCLOAK_REALM == "dsaas") {
        let id = btoa(event.target.value);
        window.location.href = `${currentUser?.customBaseUrl}/enterprise?enterpriseId=${id}`;
      } else if (localStorage.getItem("enterpriseId") != event.target.value) {
        saveCookie("enterprise_id", event.target.value, 365);
        localStorage.setItem("enterpriseId", event.target.value);
        localStorage.setItem("enterpriseName", currentUser?.establishmentName);
        localStorage.setItem("enterpriseLogo", currentUser?.logoFileSignedUrl);
        localStorage.setItem("navbarLogo", currentUser?.navBarLogoSignedUrl);
        localStorage.removeItem("userType");
        localStorage.removeItem("mspEnterprsieData");
        localStorage.removeItem("mspEnterpriseName");
        localStorage.removeItem("availableForInterviewFrom");
        localStorage.removeItem("availableForInterviewTill");
        localStorage.removeItem("availableWeekdays");
        localStorage.removeItem("childEnterpriseId");
        localStorage.removeItem("mspEnterpriseId");
        navigate("/enterprise/jobList");
        window.location.reload(true);
      } else {
        danger(`Already on ${currentUser?.establishmentName}.`);
      }
  };
  return (
    <>
      {isLoading && <Loader />}
      {isMobile ? (
        <>
          <Grid item lg={12} md={12} xs={12}>
            <Grid item className="navbar-fixed resolution-issues ipad-nav">
              <Grid item>
                <Grid
                  container
                  item
                  lg={12}
                  md={12}
                  xs={12}
                  className="justify-between"
                >
                  <Grid
                    item
                    lg={7}
                    md={7}
                    xs={7}
                    className="align-center d-flex"
                  >
                    <Box className="d-flex align-center">
                      <Link
                        className="d-flex tab-logo-align"
                        to={
                          location.pathname !== "/enterprise/admin-access"
                            ? "/enterprise/jobList"
                            : location.pathname
                        }
                      >
                        {navbarLogo && (
                          <img
                            className="logo-responsive contrasts"
                            src={navbarLogo}
                            alt="Logo"
                            tabIndex={-1}
                          />
                        )}
                        {!navbarLogo && !props.isLoading && (
                          <img
                            className="logo-responsive contrasts"
                            src={appOrigin === "H" ? Constant.APP_LOGO_HEALTHCARE : appOrigin === "N" ? Constant.APP_LOGO_NEURODIVERSIFY : Constant.APP_LOGO_GENERAL}
                            alt="Logo"
                            tabIndex={-1}
                          />
                        )}
                      </Link>
                      {/* {((childEnterprises && isEnterprsieMSP) ||
                        localStorage.getItem("childEnterpriseId")) && ( */}
                      {(isEnterprsieMSP ||
                        localStorage.getItem("childEnterpriseId")) && (
                          <>
                            {props.openSideBar === true && (
                              <>
                                <Button
                                  onClick={handleClick5}
                                  disableRipple="true"
                                  className="category-rating"
                                >
                                  {openSwitch == true ? (
                                    <ChevronUpDown
                                      onClick={() => {
                                        setOpenSwitch(false);
                                      }}
                                      className="icon-padding h-24 hide-icon"
                                    />
                                  ) : (
                                    <ChevronUpDown
                                      onClick={() => setOpenSwitch(true)}
                                      className="icon-padding h-24 hide-icon"
                                    />
                                  )}
                                </Button>
                              </>
                            )}
                          </>
                        )}
                      {/* )} */}
                      <Menu
                        anchorEl={anchorEl5}
                        open={Boolean(anchorEl5)}
                        onClose={handleClose5}
                        ref={menuRef}
                        className="msp-drawer"
                        // className={
                        //   open1 ? "msp-drawer" : "analytic-settings-report"
                        // }
                        // id="menu-appbar"
                        // anchorEl={anchorEl}
                        disableScrollLock={true}
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                        KeepMounted
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                      // open={openSwitch}
                      >
                        <SwitchSideBar
                          handleOpenSwitch={setOpenSwitch}
                          setOpen={setOpen}
                          childEnterprises={childEnterprises}
                          mspReponse={mspReponse}
                        />
                      </Menu>
                    </Box>
                  </Grid>
                  <Grid item lg={3} md={3} xs={3} className="align-item-right">
                    <Button
                      id="basic-button4"
                      // aria-controls="basic-menu2"
                      aria-controls={open2 ? "account-menu" : undefined}
                      aria-expanded={open2 ? "true" : undefined}
                      aria-haspopup="true"
                      className="dropdown-arrow text-transform-none cursor-hover position-issue"
                      onClick={handleClick2}
                      role="button"
                      aria-label="Profile"
                      disableRipple="true"
                    >
                      <Grid item className="d-flex align-item-center">
                        <Box className="enterprise-profile cursor-hover">
                          {profilePicture && (
                            <img
                              width="50px"
                              height="50px"
                              style={{
                                objectFit: profilePicture.includes("gif")
                                  ? "cover"
                                  : "",
                              }}
                              className="default-img-border mt-7"
                              src={
                                props.profileImage
                                  ? props.profileImage
                                  : profilePicture
                              }
                              alt="Profile picture"
                            />
                          )}
                          {!profilePicture && (
                            <img
                              width="50px"
                              height="50px"
                              className="default-img-border mt-7"
                              src={Constant.DEFAULT_LOGO}
                              alt="Default image"
                            />
                          )}
                        </Box>
                        <ChevronDown
                          className="f-20 c-white mb-10 mt-7 inputcontrasts chevron-down"
                          aria-label="Dropdown icon"
                          style={{ width: "16px" }}
                        />
                      </Grid>
                    </Button>

                    <Menu
                      id="account-menu"
                      anchorEl={anchorE2}
                      open={open2}
                      onClose={handleClose2}
                      className="cursor-hover ipad-profile notification-hover1"
                      disableScrollLock={true}
                      MenuListProps={{
                        "aria-labelledby": "basic-button4",
                      }}
                      KeepMounted
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                    >
                      <MenuItem>
                        <Grid item className="d-flex">
                          <Box className="enterprise-profile cursor-hover">
                            {profilePicture && (
                              <img
                                width="50px"
                                height="50px"
                                style={{
                                  objectFit: profilePicture.includes("gif")
                                    ? "cover"
                                    : "",
                                }}
                                className="default-img-border"
                                src={
                                  props.profileImage
                                    ? props.profileImage
                                    : profilePicture
                                }
                                alt="Profile picture"
                              />
                            )}
                            {!profilePicture && (
                              <img
                                width="50px"
                                height="50px"
                                className="default-img-border"
                                src={Constant.DEFAULT_LOGO}
                                alt="Default image"
                              />
                            )}
                          </Box>
                        </Grid>
                        <Grid>
                          {localStorage.getItem("childEnterpriseId") && (
                            <Grid className="ml-10 mr-5 align-left">
                              <Box className="gray9 f-14 font-weight-500 textEllipse w-110">
                                {localStorage.getItem("enterpriseName")}
                              </Box>
                              <Box className="gray7 f-12 font-weight-400 textEllipse w-110">
                                <span className="mr-5">via</span>
                                {localStorage.getItem("mspEnterpriseName")}
                              </Box>
                            </Grid>
                          )}
                        </Grid>
                        <Box sx={{ ml: 1 }} className="cursor-normal">
                          {!localStorage.getItem("childEnterpriseId") &&
                            enterpriseName ? (
                            <Typography
                              variant="h5"
                              className="small-text-font font-weight-500 textEllipse enterprise-name-width align-left cursor-hover ipad-width"
                              sx={{ pb: 1 }}
                            >
                              {getFromLocalStorage("enterpriseName")
                                ? getFromLocalStorage("enterpriseName")
                                : enterpriseName}
                            </Typography>
                          ) : (
                            <Typography>
                              {!localStorage.getItem("childEnterpriseId") ? (
                                <Tooltip
                                title={location.pathname ===
                                  "/enterprise/admin-access" &&
                                  getFromLocalStorage("email")}
                                position="top"
                                trigger="mouseenter"
                                animation="fade"
                                arrow
                              >
                                <Typography
                                  variant="span"
                                  className="small-text-font cursor-hover textEllipse w-85 align-left"
                                  style={{ paddingBottom: "8px" }}
                                >
                                  {(location.pathname ===
                                    "/enterprise/admin-access" ||
                                    location.pathname ===
                                    "/enterprise/settings") &&
                                    getFromLocalStorage("email")}
                                </Typography>
                              </Tooltip>
                              ) : (
                                <></>
                              )}
                            </Typography>
                          )}
                        </Box>
                      </MenuItem>
                      <MenuItem onClick={goToMessages}>
                        <Box
                          tabIndex={0}
                          className="cursor-hover d-flex align-center"
                          onClick={goToMessages}
                          onKeyPress={goToMessages}
                        >
                          <Badge
                            badgeContent={messagesUnreadCount}
                            color="secondary"
                            className="notification-icon contrasts icon-button"
                          >
                          <img width="18" src={messageNavIconNew} alt="img"/>
                          </Badge>
                          <span
                            className="f-14 ml-10 gray9"
                            style={{ paddingTop: "0.5px" }}
                          >
                            Messages
                          </span>
                        </Box>
                      </MenuItem>
                      <MenuItem>
                        <Grid item className="d-flex">
                          <NotificationButton
                            isMobile={isMobile}
                            viewAllNotification={viewAllNotification} />
                        </Grid>
                      </MenuItem>
                      <MenuItem
                        onClick={handleOpenFeedBack}
                        className="profile-list-btn scoring-ipad"
                      >
                        <Box
                          tabIndex={0}
                          className="cursor-hover d-flex align-center"
                          onClick={handleOpenFeedBack}
                          onKeyPress={handleOpenFeedBack}
                        >
                          <SupportIcon
                            aria-label="Message icon"
                            className="contrasts"
                            width="18px"
                            height="18px"
                          />
                          <span
                            className="f-14 ml-10 gray9"
                            style={{ paddingTop: "0.5px" }}
                          >
                            Feedback
                          </span>
                        </Box>
                      </MenuItem>
                      {/* commenting for now */}
                      {Menus?.beanbag?.indexOf(userTypes) !== -1 &&
                        localStorage.getItem("isBeanBagUser") === "Y" &&
                        !localStorage.getItem("childEnterpriseId") && (
                          <MenuItem
                            className="icon-button pr-25 pt-10 pb-10 profile-list-btn"
                            onClick={beanBagSSOLogin}
                            onKeyPress={beanBagSSOLogin}
                            aria-label="Beanbag icon"
                            tabIndex={0}
                          >
                            <BeanBagIcon
                              className="icon-button svg-icons1"
                              alt="Beanbag icon"
                              width="18px"
                              aria-label="Beanbag icon"
                              tabIndex={0}
                            />
                            <span className="ml-10 f-14 gray9">Beanbag</span>
                          </MenuItem>
                        )}
                        {location.pathname !== "/enterprise/admin-access" && !activeUser && <MenuItem
                          onClick={goToMyReferrals}
                          tabIndex={0}
                          onKeyPress={goToMyReferrals}
                          onMouseDown={goToMyReferrals}
                          onMouseUp={goToMyReferrals}
                          className="icon-button logout-text pr-25 pt-10 pb-10 profile-list-btn"
                          style={{ paddingLeft: "16px" }}
                        >
                          <ReferCandidate width="22px" height="22px" icon="refer-candidate" style={{ marginLeft: "3px" }}/>
                          <span className="ml-10 f-14 gray9 ipad-ml5">Referrals</span>
                        </MenuItem>}
                        {(location.pathname !== "/enterprise/admin-access" && !activeUser) && (<MenuItem
                        className="icon-button logout-text pr-25 pt-10 pb-10 profile-list-btn"
                        style={{ paddingLeft: "16px" }}
                        onClick={goToSettingPage}
                      >
                        <SettingsIcon width="20px" height="20px" />
                        <span className="ml-10 f-14 gray9">Settings</span>
                      </MenuItem>)}
                      <MenuItem onClick={signOut} className="">
                        {isloggedIn ? (
                          <>
                            <Grid item className="d-flex">
                              <Box
                                className="danger-color small-text-font cursor-hover align-item-center icon-button"
                                variant="span"
                                onClick={signOut}
                                onKeyPress={signOut}
                                tabIndex={0}
                              >
                                <LogoutNavIcon
                                  className="mr-10 icon-button"
                                  alt="img"
                                  width="18px"
                                  aria-label="Logout icon"
                                  tabIndex={0}
                                />
                                <span>Logout</span>
                              </Box>
                            </Grid>
                          </>
                        ) : (
                          <>
                            <Box
                              sx={{ px: 4, pt: 1 }}
                              className="pl-0"
                              style={{ paddingBottom: "15px" }}
                            >
                              <Link
                                className="c-white small-text-font cursor-hover"
                                to="/signin"
                                style={{
                                  textDecoration: "none",
                                  display: "flex",
                                  fontWeight: "600",
                                }}
                              >
                                Sign In
                              </Link>
                            </Box>
                            <Box sx={{ pt: 1 }}>
                              <Button
                                className="btn primary-button"
                                style={{ padding: "4px 16px" }}
                                role="button"
                                aria-label="Signup"
                                disableRipple="true"
                              >
                                <Link
                                  className="c-white small-text-font cursor-hover"
                                  to="/signup"
                                  style={{
                                    textDecoration: "none",
                                    display: "flex",
                                    fontWeight: "600",
                                  }}
                                >
                                  Sign Up
                                </Link>
                              </Button>
                            </Box>
                          </>
                        )}
                      </MenuItem>
                    </Menu>
                  </Grid>
                  <Grid item lg={2} md={2} xs={2} className="align-item-right">
                    <Box
                      className="align-between"
                    // sx={{ px: 2 }}
                    >
                      <Box className="d-flex align-center">
                        {/*  <SearchIcon className="gray7 f-25" />*/}
                        <Button
                          variant="text"
                          onClick={handleMenuBar}
                          role="button"
                          aria-label="Menu icon"
                          disableRipple="true"
                        >
                          {" "}
                          <MenuIcon
                            width="25px"
                            height="25px"
                            aria-label="Menu icon"
                            className="gray9 menu-icon"
                          />
                        </Button>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
                <Menu
                  className="navbar-responsive sidebar-dropdown"
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  disableScrollLock={true}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  KeepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  open={open}
                >
                  <Box className="navbar-responsive">
                    <Box sx={{ textAlign: "right" }} className="icon-button">
                      <CloseIcon
                        className="font-25"
                        onClick={handleCloseMenu}
                        onKeyPress={handleCloseMenu}
                        tabIndex={0}
                        aria-label="Close icon"
                      />
                    </Box>
                    {location.pathname !== "/enterprise/admin-access" && !activeUser && (
                      <>
                        <Box className="enterpriseSearch mt-10">
                          <Tooltip
                            role="tooltip"
                            title={`Search candidates by ${appOrigin === "D" || appOrigin === "N" ? "skill" : "specialization"
                              }, certification, profile title, education, name, email id, checklist or phone number`}
                            position="top"
                            trigger="mouseenter"
                            animation="fade"
                            arrow
                          >
                            <TextField
                              value={
                                location.pathname.includes(
                                  "enterprise/CandidateList"
                                ) ||
                                  location.pathname.includes(
                                    "enterprise/candidate-detail"
                                  ) ||
                                  showSearchFilter ||
                                  filterValue
                                  ? filterValue && type
                                    ? getFormattedFilterValue(filterValue, type)
                                    : filterValue
                                  : ""
                              }
                              type="search"
                              className="position-issue inputcontrasts form-control input-group mb-0 ipad-search-width min-w-100cent"
                              variant="outlined"
                              margin="normal"
                              id="globalSearch"
                              placeholder={`Search candidates by ${appOrigin === "D" || appOrigin === "N" ? "skill" : "specialization"
                                }, certification, profile title...`}
                              onChange={handleJobTitleSearch}
                              onKeyUp={handleEnterKey}
                              inputProps={{
                                onDoubleClick: selectText,
                                role: "",
                              }}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment
                                    position="start"
                                    aria-label="Search icon"
                                  >
                                    <SearchIcon aria-label="Search icon" />
                                  </InputAdornment>
                                ),
                                endAdornment: (
                                  <InputAdornment position="end">
                                    {filterValue && (
                                      <ClearIcon
                                        onClick={() => {
                                          setJobTitleFilterValue("");
                                          setFilterVaue("");
                                          setShowSearchFilter(false);
                                          if (
                                            location.pathname.includes(
                                              "/enterprise/CandidateList"
                                            )
                                          ) {
                                            navigate(
                                              "/enterprise/CandidateList"
                                            );
                                          }
                                        }}
                                        className="cursor-hover f-15"
                                        aria-label="Clear icon"
                                        tabIndex={-1}
                                      />
                                    )}
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Tooltip>
                        </Box>
                        {showSearchFilter && (
                          <SearchFilter
                            selectedjobTitleFilter={jobTitleFilterValue}
                            handleStateChange={handleStateChange}
                            setShowSearchFilter={setShowSearchFilter}
                          />
                        )}
                      </>
                    )}
                  </Box>
                  <Grid item className="add-checklist-align">
                      <>
                        {(localStorage.getItem("userType") == "A" ? removeMultipleIndexes(EnterpriseMenu, indexesToRemove) : EnterpriseMenu).map((list, index) => {
                          var Component = list.icon;
                          return (
                            ((list.role[0] == "All" || list.role.indexOf(userTypes) !== -1 && !localStorage.getItem("childEnterpriseId") != null)) ?
                              (((list.name == "Interviews" && (enterpriseDetails?.interviewConfigurations == null || enterpriseDetails?.interviewConfigurations == "")) ||
                              (list.name == "Configuration Control" && localStorage.getItem("childEnterpriseId") != null) || (list.url == "Career Portal" && window.location.href?.includes("uat")) || (list.name == "Talent Console" && (isWtw == null || isWtw == ""))) ? <></> : <Grid key={index}
                                className="mt-45 sideBar-icon cursor-hover"
                                onClick={() => list.url == "Career Portal" ? careerPortalSSOLogin() : list.url == "Analytics" ? handleMenu() : navigateTourl(list.url)}
                                onKeyPress={() => list.url == "Career Portal" ? careerPortalSSOLogin() : list.url == "Analytics" ? handleMenu() : navigateTourl(list.url)}
                                aria-label={list.name}
                              ><Typography className={`sideIcon-align ${(list?.activeUrls?.includes(URLPath?.split('/')[2]) && !openAnalytics) ? "bg-white" : ""}`}>
                                  <Component
                                    width="16px"
                                    height="16px"
                                    aria-label={list.name}
                                    className={`${(list?.activeUrls?.includes(URLPath?.split('/')[2]) && !openAnalytics) ? "svg-icons" : ""}`}
                                  />
                                  <span className={`f-18 gray7 pl-10 ${(list?.activeUrls?.includes(URLPath?.split('/')[2]) && !openAnalytics) ? "primary-shadow-color" : "gray7"}`}>
                                    {list.name}
                                  </span>
                                </Typography>
                              </Grid>)
                              : <></>
                          );
                        })}
                      </>
                  </Grid>
                  {enterprisesList?.length > 1 && <Grid item className="pt-20 pb-10">
                    <Typography className="gray7 f-14">
                      Select Enterprise
                    </Typography>
                    <Grid container lg={12} md={12} xs={12} className="rating-category select-enterprise">
                    <Select
                      className="w-100 mb-0 job-category rating-select-align form-control input-group"
                      tabIndex={0}
                      value={enterpriseUser ? enterpriseUser : "--Select--"}
                      onChange={(e) => handleSelectEnterprise(e)}
                      displayEmpty
                    >
                      {enterpriseUser == null && <MenuItem value="--Select--">--Select--</MenuItem>}
                      {enterprisesList?.map((e) => {
                        return (
                        <MenuItem onClick={() => {
                          if (localStorage.getItem("enterpriseId") == e.id) {
                            return danger(`Already on ${localStorage.getItem("enterpriseName")}.`);
                          };
                        }
                      } key={e.id} value={e.id}>{e.establishmentName}</MenuItem>
                        );
                      })}
                    </Select>
                  </Grid>
                    </Grid>}
                  <Grid item>
                    <Menu
                      className={
                        openSideAnalytic
                          ? "analytic-view-ipad"
                          : "analytic-settings-report analytic-view-ipad"
                      }
                      id="menu-appbar"
                      anchorEl={anchorEl}
                      disableScrollLock={true}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      KeepMounted
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      open={openAnalytics}
                    >
                      <Grid item className="mt-15">
                        <AnalyticsSideBar
                          setOpenAnalytics={setOpenAnalytics}
                          setOpenSideAnalytic={setOpenSideAnalytic}
                          setAnchorEl={setAnchorEl}
                          anchorEl={anchorEl}
                        />
                      </Grid>
                    </Menu>
                  </Grid>
                </Menu>
              </Grid>
            </Grid>
          </Grid>
        </>
      ) : (
        <Grid item className="navbar-fixed resolution-issues">
          {/*  <Container>*/}
          <Grid item container spacing={1} className="w-100 ml-0">
            <Box
              container
              spacing={1}
              item
              // lg={2}
              // md={3}
              // xs={3}
              style={{ width: props.openSideBar === true ? "18%" : "55px" }}
              className="align-center logoHeader grid1-border-header justify-center d-flex"
            >
              <Box
                className={
                  props.openSideBar
                    ? "align-between align-center w-100"
                    : "align-center justify-center d-flex"
                }
              >
                <Box className="d-flex align-center">
                  {props.openSideBar === true && (
                    <Tooltip
                      title="Collapse"
                      position="bottom"
                      trigger="mouseenter"
                      animation="fade"
                      arrow
                    >
                      <BarsLeftIcon
                        className="p-r-10 pl-25 toggle-icon mt-5 h-24 cursor-hover"
                        onClick={() => {
                          props.handleOpenSideBar();
                        }}
                      />
                    </Tooltip>
                  )}
                  {props.openSideBar === false && (
                    <Tooltip
                      title="Expand"
                      position="bottom"
                      trigger="mouseenter"
                      animation="fade"
                      arrow
                    >
                      <BarsLeftIcon
                        className="p-r-10 p-l-5 toggle-icon mt-5 h-24 cursor-hover"
                        onClick={() => {
                          props.handleCloseSideBar();
                        }}
                      />
                    </Tooltip>
                  )}
                  {props.openSideBar === true && (
                    <Link
                      to={
                        location.pathname !== "/enterprise/admin-access"
                          ? "/enterprise/jobList"
                          : location.pathname
                      }
                    >
                      {navbarLogo && (
                        <img
                          className="logoImg-header contrasts mt-10 w-120"
                          src={navbarLogo}
                          alt="Logo"
                          tabIndex={-1}
                        />
                      )}
                      {!navbarLogo && !props.isLoading && (
                        <>
                          {appOrigin === "D" ? (
                            <img
                              className="logoImg-header contrasts mt-10 w-120"
                              src={Constant.APP_LOGO_GENERAL}
                              alt="Logo"
                              tabIndex={-1}
                            />
                          ) : appOrigin === "N" ? (<img
                            className="logoImg-header contrasts"
                            src={Constant.APP_LOGO_NEURODIVERSIFY}
                            alt="Logo" width="110px"
                            tabIndex={-1}
                          />) : (
                            <img
                              className="logoImg-header contrasts mt-10 w-120"
                              src={Constant.APP_LOGO_HEALTHCARE}
                              alt="Logo"
                              tabIndex={-1}
                            />
                          )}
                        </>
                      )}
                    </Link>
                  )}
                </Box>
                {(isEnterprsieMSP ||
                  localStorage.getItem("childEnterpriseId")) && (
                    <>
                      {props.openSideBar === true && (
                        <>
                          <Button onClick={handleClick5} disableRipple="true"
                            aria-label="toggle-icon" className="pl-0 ipad-flexstart">
                            {openSwitch == true ? (
                              <ChevronUpDown
                                onClick={() => {
                                  setOpenSwitch(false);
                                }}
                                className="pl-10 icon-padding mt-5 h-24 hide-icon"
                              />
                            ) : (
                              <ChevronUpDown
                                onClick={() => setOpenSwitch(true)}
                                className="pl-10 icon-padding mt-5 h-24 hide-icon"
                              />
                            )}
                          </Button>
                        </>
                      )}
                    </>
                  )}
                <Menu
                  anchorEl={anchorEl5}
                  open={Boolean(anchorEl5)}
                  onClose={handleClose5}
                  ref={menuRef}
                  className="msp-drawer"
                  // className={
                  //   open1 ? "msp-drawer" : "analytic-settings-report"
                  // }
                  // id="menu-appbar"
                  // anchorEl={anchorEl}
                  disableScrollLock={true}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  KeepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                // open={openSwitch}
                >
                  <SwitchSideBar
                    handleOpenSwitch={setOpenSwitch}
                    setOpen={setOpen}
                    childEnterprises={childEnterprises}
                    mspReponse={mspReponse}
                  />
                </Menu>
              </Box>
            </Box>
            {location.pathname !== "/enterprise/admin-access" && !activeUser && (
              <Box
                container
                item
                // lg={8} md={5} xs={4}
                style={{ width: "52%" }}
                className="pt-5 ipad-search"
              >
                {/* TODO: removing global search temporarily */}
                <Box className="enterpriseSearch  header-g-placeholder w-100">
                  <Tooltip
                    role="tooltip"
                    title={`Search candidates by ${appOrigin === "D" || appOrigin === "N" ? "skill" : "specialization"
                      }, certification, profile title, education, name, email id, checklist or phone number`}
                    position="top"
                    trigger="mouseenter"
                    animation="fade"
                    arrow
                  >
                    <TextField
                      style={{ width: "100%" }}
                      value={
                        location.pathname.includes(
                          "enterprise/CandidateList"
                        ) ||
                          location.pathname.includes(
                            "enterprise/candidate-detail"
                          ) ||
                          showSearchFilter ||
                          filterValue
                          ? filterValue && type
                            ? getFormattedFilterValue(filterValue, type)
                            : filterValue
                          : ""
                      }
                      type="search"
                      className="position-issue inputcontrasts"
                      variant="outlined"
                      margin="normal"
                      id="globalSearch"
                      placeholder={
                        appOrigin === "D" || appOrigin === "N"
                          ? "Search candidates by skill, certification, profile title, education, name, email id, che..."
                          : "Search candidates by specialization, certification, profile title, education, name..."
                      }
                      onChange={handleJobTitleSearch}
                      onKeyUp={handleEnterKey}
                      inputProps={{ onDoubleClick: selectText, role: "" }}
                      InputProps={{
                        autoComplete: "off",
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            aria-label="Search icon"
                          >
                            <SearchIconNew
                              aria-label="Search icon"
                              style={{ width: "16px" }}
                            />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            {filterValue && (
                              <ClearIcon
                                onClick={() => {
                                  setJobTitleFilterValue("");
                                  setFilterVaue("");
                                  setShowSearchFilter(false);
                                  if (
                                    location.pathname.includes(
                                      "/enterprise/CandidateList"
                                    )
                                  ) {
                                    navigate("/enterprise/CandidateList");
                                  }
                                }}
                                className="cursor-hover f-15"
                              // aria-label="Clear icon"
                              />
                            )}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Tooltip>
                </Box>
                {showSearchFilter && (
                  <SearchFilter
                    selectedjobTitleFilter={jobTitleFilterValue}
                    handleStateChange={handleStateChange}
                    setShowSearchFilter={setShowSearchFilter}
                  />
                )}
              </Box>
            )}
            <Box
              container
              item
              // lg={2}
              // md={4}
              // xs={5}
              className="align-center name-email headerUser d-flex pt-5 pr-30"
              style={{
                justifyContent: "right",
                marginLeft: "auto",
                marginRight: 0,
                width: "30%",
              }}
            >
              {isloggedIn ? (
                <>
                  {location.pathname !== "/enterprise/admin-access" && !activeUser && (
                    <>
                      <Box
                        tabIndex={0}
                        onKeyPress={goToMessages}
                        className="ml-20 cursor-hover headerIcon"
                        onClick={goToMessages}
                      >
                        <Badge
                          badgeContent={messagesUnreadCount}
                          color="secondary"
                          className="notification-icon contrasts icon-button"
                        >
                        <Tooltip
                          title="Messages"
                          position="bottom"
                          trigger="mouseenter"
                          animation="fade"
                          arrow
                        >
                          <MessageIcon
                            style={{ width: "24px" }}
                            aria-label="Message icon"
                            className="contrasts"
                          />
                        </Tooltip>
                        </Badge>
                      </Box>
                      <Grid item sx={{ ml: 3 }} className="d-flex mr-20 mt-10 headerIcon">
                        <NotificationButton
                          isMobile={isMobile}
                          viewAllNotification={viewAllNotification} />
                      </Grid>
                      <Box
                        tabIndex={0}
                        onKeyPress={handleOpenFeedBack}
                        className="ml-10 m-r-10 mt-8 cursor-hover headerIcon"
                        onClick={handleOpenFeedBack}
                      >
                        <Tooltip
                          title="Feedback"
                          position="bottom"
                          trigger="mouseenter"
                          animation="fade"
                          arrow
                        >
                          <SupportIcon
                            // style={{ width: "24px" }}
                            aria-label="Message icon"
                            className="contrasts"
                            width="20px"
                            height="20px"
                          />
                        </Tooltip>
                      </Box>
                    </>
                  )}
                  <Grid
                    item
                    className="d-flex ml-10 header-dropdown cursor-hover"
                    style={{ marginTop: "9px" }}
                  >
                    <Button
                      id="basic-button4"
                      // aria-controls="basic-menu2"
                      aria-controls={open2 ? "account-menu" : undefined}
                      aria-expanded={open2 ? "true" : undefined}
                      aria-haspopup="true"
                      className="dropdown-arrow text-transform-none cursor-hover position-issue"
                      onClick={handleClick2}
                      role="button"
                      aria-label="Profile picture"
                      disableRipple="true"
                    >
                      <Grid item className="d-flex">
                        <Box className="enterprise-profile cursor-hover">
                          {profilePicture && (
                            <img
                              width="40px"
                              height="40px"
                              style={{
                                objectFit: profilePicture.includes("gif")
                                  ? "cover"
                                  : "",
                              }}
                              className="default-img-border"
                              src={
                                props.profileImage
                                  ? props.profileImage
                                  : profilePicture
                              }
                              alt="Profile picture"
                            />
                          )}
                          {!localStorage.getItem("childEnterpriseId") &&
                            !profilePicture && (
                              <img
                                width="40px"
                                height="40px"
                                className="default-img-border"
                                src={Constant.DEFAULT_LOGO}
                                alt="Default image"
                              />
                            )}
                          {!profilePicture &&
                            localStorage.getItem("childEnterpriseId") && (
                              <Typography
                                className="default-img-border justify-center align-center d-flex primary-shadow-color bg-input f-14 font-weight-600 text-capitalize"
                                width="30px"
                                height="30px"
                                aria-label="Default image"
                              >
                                {enterpriseName?.trim().substring(0, 1)}
                              </Typography>
                            )}
                        </Box>
                      </Grid>
                      <Grid>
                        {localStorage.getItem("childEnterpriseId") && (
                          <Tooltip
                            title={
                              <div>
                                {localStorage.getItem("enterpriseName")}
                                <Box>
                                  <span className="mr-5">via</span>
                                  {localStorage.getItem("mspEnterpriseName")}
                                </Box>
                              </div>
                            }
                            position="top"
                            trigger="mouseenter"
                            animation="fade"
                            arrow
                          >
                            <Grid className="ml-10 mr-5 align-left">
                              <Box className="gray9 f-14 font-weight-500 textEllipse w-110">
                                {localStorage.getItem("enterpriseName")}
                              </Box>
                              <Box className="gray7 f-12 font-weight-400 textEllipse w-110">
                                <span className="mr-5">via</span>
                                {localStorage.getItem("mspEnterpriseName")}
                              </Box>
                            </Grid>
                          </Tooltip>
                        )}
                      </Grid>
                      <Box sx={{ ml: 1 }} className="cursor-normal">
                        {!localStorage.getItem("childEnterpriseId") &&
                          enterpriseName ? (
                          <Tooltip
                            title={
                              <div>
                                {getFromLocalStorage("enterpriseName")
                                  ? getFromLocalStorage("enterpriseName")
                                  : enterpriseName}
                                <br />
                                {localStorage.getItem("name")
                                  ? localStorage.getItem("name")
                                  : localStorage
                                    .getItem("email")
                                    ?.split("@")[0]}{" "}
                                <br />
                                {localStorage.getItem("profileTitle") &&
                                  localStorage.getItem("profileTitle") !==
                                  "null" ? (
                                  <>
                                    {localStorage.getItem("profileTitle")}{" "}
                                    <br />
                                  </>
                                ) : (
                                  ""
                                )}
                                {getUserType(localStorage.getItem("userType") ? localStorage.getItem("userType") : userTypes)}
                                <br />
                                {localStorage.getItem("email")}
                              </div>
                            }
                            position="top"
                            trigger="mouseenter"
                            animation="fade"
                            arrow
                          >
                            <Typography
                              variant="h5"
                              className="gray9 small-text-font font-weight-500 textEllipse align-left cursor-hover w-110"
                              sx={{ pb: 1 }}
                            >
                              {getFromLocalStorage("enterpriseName")
                                ? getFromLocalStorage("enterpriseName")
                                : enterpriseName}
                            </Typography>
                          </Tooltip>
                        ) : (
                          <Typography>
                            {!localStorage.getItem("childEnterpriseId") ? (
                              <Tooltip
                                title={
                                  (location.pathname ===
                                  "/enterprise/admin-access" ||
                                  location.pathname ===
                                  "/enterprise/settings") &&
                                  getFromLocalStorage("email")
                                }
                                position="top"
                                trigger="mouseenter"
                                animation="fade"
                                arrow
                              >
                                <Typography
                                  variant="span"
                                  className="small-text-font cursor-hover textEllipse w-85 align-left c-white"
                                  style={{ paddingBottom: "8px" }}
                                >
                                  {(location.pathname ===
                                    "/enterprise/admin-access" ||
                                    location.pathname ===
                                  "/enterprise/settings") &&
                                    getFromLocalStorage("email")}
                                </Typography>
                              </Tooltip>
                            ) : (
                              <></>
                            )}
                          </Typography>
                        )}
                      </Box>
                      <ExpandMoreIcon
                        className="ml-10 cursor-hover inputcontrasts fill-svg-icons gray9"
                        aria-label="Dropdown icon"
                        style={{ marginBottom: "7px" }}
                      />
                    </Button>

                    <Menu
                      id="account-menu"
                      anchorEl={anchorE2}
                      open={open2}
                      onClose={handleClose2}
                      className="cursor-hover"
                      disableScrollLock={true}
                      MenuListProps={{
                        "aria-labelledby": "basic-button4",
                      }}
                      KeepMounted
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                    >
                      <MenuItem
                        className="border-bottom-gray4 pl-25 pr-25 mb-10 cursor-default"
                        disableRipple="true"
                      >
                        <Button
                          id="basic-button4"
                          // aria-controls="basic-menu2"
                          aria-controls={open2 ? "account-menu" : undefined}
                          aria-expanded={open2 ? "true" : undefined}
                          aria-haspopup="true"
                          className="dropdown-arrow text-transform-none cursor-default position-issue mr-10 mt-5"
                          // onClick={handleClick2}
                          role="button"
                          aria-label="Profile picture"
                          disableRipple="true"
                        >
                          <Grid
                            item
                            className="justify-end align-center d-flex cursor-default"
                          >
                            <Box className="enterprise-profile cursor-default align-center d-flex">
                              {profilePicture && (
                                <img
                                  width="42px"
                                  height="42px"
                                  style={{
                                    objectFit: profilePicture.includes("gif")
                                      ? "cover"
                                      : "",
                                  }}
                                  className="default-img-border p-0"
                                  src={
                                    props.profileImage
                                      ? props.profileImage
                                      : profilePicture
                                  }
                                  alt="Profile picture"
                                />
                              )}
                              {!profilePicture && (
                                <img
                                  width="42px"
                                  height="42px"
                                  className="default-img-border p-0"
                                  src={Constant.DEFAULT_LOGO}
                                  alt="Default image"
                                />
                              )}
                            </Box>
                          </Grid>
                        </Button>
                        <Box>
                          {enterpriseName ? (
                            <Grid className="p-r-15 gray9 f-14 mt-5 mb-5">
                              <span className="gray9 f-14">
                                {localStorage.getItem("name")
                                  ? localStorage.getItem("name")
                                  : localStorage
                                    .getItem("email")
                                    ?.split("@")[0]}{" "}
                              </span>
                              <br />
                              {localStorage.getItem("profileTitle") &&
                                localStorage.getItem("profileTitle") !==
                                "null" ? (
                                <>
                                  {localStorage.getItem("profileTitle")} <br />
                                </>
                              ) : (
                                ""
                              )}
                              {getUserType(localStorage.getItem("userType") ? localStorage.getItem("userType") : userTypes)}
                              <br />
                              {localStorage.getItem("email")}
                            </Grid>
                          ) : (
                            <Typography
                              variant="span"
                              className="small-text-font cursor-hover textEllipse w-200 align-left"
                              style={{ paddingBottom: "8px" }}
                            >
                              {(location.pathname ===
                                "/enterprise/admin-access" ||
                                location.pathname ===
                                "/enterprise/settings") &&
                                getFromLocalStorage("email")}
                            </Typography>
                          )}
                        </Box>
                      </MenuItem>
                      {/* commenting for now */}
                      {!activeUser &&
                        Menus?.beanbag?.indexOf(userTypes) !== -1 &&
                        localStorage.getItem("isBeanBagUser") === "Y" &&
                        !localStorage.getItem("childEnterpriseId") && (
                          <MenuItem
                            className="icon-button pr-25 pt-10 pb-10 profile-list-btn"
                            onClick={beanBagSSOLogin}
                            onKeyPress={beanBagSSOLogin}
                            aria-label="Beanbag icon"
                            tabIndex={0}
                            style={{ paddingLeft: "22px" }}
                          >
                            <span style={{ height: "22px" }}>
                              <BeanBagIcon
                                className="icon-button svg-icons1"
                                alt="Beanbag icon"
                                width="20px"
                                aria-label="Beanbag icon"
                                tabIndex={0}
                              />
                            </span>
                            <span className="pl-13">Beanbag</span>
                          </MenuItem>
                        )}
                        {location.pathname !== "/enterprise/admin-access" && !activeUser && <MenuItem
                          onClick={goToMyReferrals}
                          tabIndex={0}
                          onKeyPress={goToMyReferrals}
                          onMouseDown={goToMyReferrals}
                          onMouseUp={goToMyReferrals}
                          className="icon-button logout-text pr-25 pt-10 pb-10 profile-list-btn"
                          style={{ paddingLeft: "22px" }}
                        >
                          <ReferCandidate width="22px" height="22px" id="refer-candidate" />
                          <span className="pl-10">Referrals</span>
                        </MenuItem>}
                        {location.pathname !== "/enterprise/admin-access" && !activeUser && (<MenuItem
                        className="icon-button logout-text pr-25 pt-10 pb-10 profile-list-btn mb-10"
                        style={{ paddingLeft: "22px" }}
                        onClick={goToSettingPage}
                      >
                        <SettingsIcon width="20px" height="20px" />
                        <span className="pl-10">Settings</span>
                      </MenuItem>
                        )}
                      <MenuItem
                        className="danger-color icon-button logout-text pr-25 pt-15 border-top-gray4"
                        onClick={signOut}
                        onKeyPress={signOut}
                        aria-label="Logout icon"
                        tabIndex={0}
                        style={{ paddingLeft: "22px" }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          className="danger-color"
                          width="24px"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9"
                          />
                        </svg>
                        {/* <LogoutNavIcon
                          className="mr-6 icon-button"
                          alt="Logout icon"
                          width="15px"
                          aria-label="Logout icon"
                          tabIndex={0}
                        /> */}
                        <span className="pl-10" style={{ paddingTop: "2px" }}>
                          Logout
                        </span>
                      </MenuItem>
                    </Menu>
                  </Grid>
                </>
              ) : (
                <>
                  <Box sx={{ px: 4, pt: 1 }} className="d-flex">
                    <Link
                      className="c-white small-text-font cursor-hover"
                      to="/signin"
                      style={{
                        textDecoration: "none",
                        display: "flex",
                        fontWeight: "600",
                      }}
                    >
                      Sign In
                    </Link>
                    <Link
                      className="c-white small-text-font cursor-hover"
                      to="/signup"
                      style={{
                        textDecoration: "none",
                        display: "flex",
                        fontWeight: "600",
                      }}
                    >
                      Sign Up
                    </Link>
                  </Box>
                </>
              )}
            </Box>
          </Grid>
          {/*  </Container>*/}
        </Grid>
      )}
      <FeedBackModal open={openfeedback} onClose={handleFeebackModalClose} />
      <Modal
        open={openNotValidModal}
        // onClose={handleCloseNotValidModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modalStyle inputcontrasts">
          <Typography variant="h5" className="font-weight-500 f-20 gray9">
            Your access to this enterprise has expired. Please contact your
            admin
          </Typography>
          {/*  <Typography sx={{ mt: 2 }} className="sub-text-font">
                        Please click on sign up to proceed further.
                    </Typography>*/}
          <Typography id="modal-modal-description" sx={{ mt: 3 }}>
            <Button
              type="button"
              style={{ width: "40%" }}
              onClick={handleNotValidEnterprise}
              className="btn primary-button w-40"
              variant="contained"
              disableRipple="true"
            >
              OK
            </Button>
          </Typography>
        </Box>
      </Modal>
    </>
  );
}
