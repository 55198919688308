import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import countries from "../../../helpers/phone-code.json";
import { makeStyles } from "@material-ui/styles";
import ClearIcon from "@mui/icons-material/Clear";

const useStyles = makeStyles(() => ({
  autoComplete: {
    fontSize: "12px",
  },
}));
export default function AutoCompleteCountryCode(props) {
  const classes = useStyles();
  return (
    <Autocomplete
      className={props.className}
      clearIcon={props.defaultValue != "+1" && (<ClearIcon style={{ fontSize: "5px" }} aria-label="Dropdown icon" />)}
      // defaultValue={props.defaultValue}
      value={props.defaultValue}
      onChange={props.onChange}
      id="country-select-demo"
      ListboxProps={{ style: { maxHeight: "10rem" } }}
      options={countries}
      classes={{
        option: classes.autoComplete,
        loading: classes.autoComplete,
        noOptions: classes.autoComplete,
      }}
      disabled={props.disablePhone}
      disablePortal={true}
      getOptionLabel={(option) =>
        option.dial_code ? option.dial_code : props.defaultValue
      }
      renderOption={(props, option) => (
        <Box
          component="li"
          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
          {...props}
        >
          {option.dial_code}
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label=""
          inputProps={{
            ...params.inputProps,
            autoComplete: "new-password", // disable autocomplete and autofill
          }}
          className="inputcontrasts country-code-border-radius"
        />
      )}
    />
  );
}
