import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Box,
  Container,
  TextField,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Modal,
  Tabs,
  Tab,
  useMediaQuery,
  useTheme,
  InputAdornment,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  Checkbox,
  FormControlLabel
} from "@mui/material";
import defaultUser from "../../../assets/images/default-user.svg";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import deleteIcon from "../../../assets/images/deleteIcon.svg";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import attachmentIcon from "../../../assets/images/attachment-icon.svg";
import messageSending from "../../../assets/images/message-sending.svg";
import ApiCalls from "../../../api/customAxios";
import { toast } from "react-toastify";
import moment from "moment";
import CloseIcon from "@mui/icons-material/Close";
import _ from "lodash";
import Resumepdf from "../../../assets/images/extension-icon/pdf-icon.png";
import docIcon from "../../../assets/images/extension-icon/doc-icon.png";
import Loader from "../../Loader";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import closeIcon from "../../../assets/images/close-icon.svg";
import { getDSaaSCandidateIdFromToken } from "../../../helpers/auth-header";
import ConfirmDialog from "../../../Components/shared/ConfirmDialog";
import { success, danger } from "../../../helpers/message-box";
import noJobs from "../../../assets/images/NoJobsFound.svg";
import { checkIsImage, formatDate, handleSpaces, isMinutesOrHours } from "../../../helpers/helper-data";
import { Helmet } from "react-helmet";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import SendIcon from "@mui/icons-material/Send";
import smileIcon from "../../../assets/images/smileIcon.svg";
import Picker from "emoji-picker-react";
import { useSelector } from "react-redux";
import constants from "../../../helpers/constants";
import { ReactComponent as SearchIcon } from "../../../assets/images/new-search-gray-icon.svg";
import ClearIcon from "@mui/icons-material/Clear";
import TooltipShared from "../../../Components/shared/TooltipShared";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { ReactComponent as NewChatIcon } from "../../../assets/images/NewChatIcon.svg";
import { ReactComponent as ChatUpIcon } from "../../../assets/images/chat-up-icon.svg";
import { ReactComponent as ChatDownIcon } from "../../../assets/images/chat-down-icon.svg";
import noSearchResultsIcon from "../../../assets/images/NoSearchMessagesImage.svg";
import noMessagesIcon from "../../../assets/images/NoMessagesImage.svg";

const MessageChatBox = ({ candidate, index, isLastChatWindow, setOpenNewMessageChat, handleMinimizeNewChat }) => {
    const [isMinimized, setIsMinimized] = useState(true);
    const appOrigin = useSelector((state) => state.location.origin);
    const enterpriseDetails = useSelector((state) => state.enterprise.enterprise);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const params = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [openPreviewModal, setOpenPreviewModal] = useState(false);
  const [chats, setChats] = useState([]);
  const [initialChats, setInitialChats] = useState([]);
  const [chatsLastMeassage, setChatsLastMessage] = useState("");
  const [messagesJobs, setMessagesJobs] = useState([]);
  const [activeMessageJob, setActiveMessageJob] = useState({});
  const [activeChatIndex, setActiveChatIndex] = useState(0); // by default first chat will be active
  const [activeJobIndex, setActiveJobIndex] = useState(0); // by default first job will be active
  const [currentChat, setCurrentChat] = useState();
  const [currentJob, setCurrentJob] = useState();
  const [messagesGroupedData, setMessagesGroupedData] = useState([]);
  const [messages, setMessages] = useState([]);
  const [currentMessage, setCurrentMessage] = useState("");
  const [fileToSend, setFileToSend] = useState("");
  const [fileName, setFileName] = useState("");
  const [currentImage, setCurrentImage] = useState("");
  const [openDeleteChat, setOpenDeleteChat] = useState(false);
  const [openRemoveAttach, setOpenRemoveAttach] = useState(false);
  const [loaddata, setLoaddata] = useState(false);
  const [inputStr, setInputStr] = useState("");
  const [showPicker, setShowPicker] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [sendOption, setSendOption] = useState(false);
  const [openSendMenu, setOpenSendMenu] = useState(false);
  const inputRef = React.useRef();
  const anchorRef = React.useRef(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const getOnlyChats = (keyword = null) => {
    setIsLoading(true);
    ApiCalls.get("enterprises/messages/0/500")
      .then((response) => {
        if (keyword) {
          let result = response?.filter((chat)=>chat.messageFromName?.toLowerCase().startsWith(keyword.toLowerCase()));
          setChats(result);
        } else {
          setChats(response);
        }
        setInitialChats(response);
        setIsLoading(false);
      })
      .catch(function (error) {
        // if (error.statusCode != 500) {
        //   danger(<div>{error.errorMessage}</div>);
        // }
        setIsLoading(false);
      });
  };

  useEffect(() => {
    setSearchKeyword("");
    getOnlyChats();
  }, [loaddata, isMinimized]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isMinimized) {
        if (searchKeyword) {
          getOnlyChats(searchKeyword);
        } else {
          getOnlyChats();
        }
      }
    }, 10000);
    return () => {
      clearInterval(interval);
    };
  }, [isMinimized, searchKeyword]);

  useEffect(() => {
    const clearChatInterval = setInterval(() => {
      if (!isMinimized && localStorage.getItem("isChatHistoryCleared")) {
        if (searchKeyword) {
          getOnlyChats(searchKeyword);
        } else {
          getOnlyChats();
        }
        localStorage.removeItem("isChatHistoryCleared");
      } else if (!isMinimized && localStorage.getItem("clearUnreadCountId")) {
        let result = chats.map((chat)=>{
          if (chat.messageFrom === localStorage.getItem("clearUnreadCountId") &&
          chat.messageTo === localStorage.getItem("candidateId") &&
          chat.enterpriseId === (localStorage.getItem("childEnterpriseId") ? localStorage.getItem("childEnterpriseId") : localStorage.getItem("enterpriseId"))
          ) {
            chat.unreadCount = 0;
            localStorage.removeItem("clearUnreadCountId");
          }
          return chat;
        });
        setChats(result);
        let result2 = initialChats.map((chat)=>{
          if (chat.messageFrom === localStorage.getItem("clearUnreadCountId") &&
          chat.messageTo === localStorage.getItem("candidateId") &&
          chat.enterpriseId === (localStorage.getItem("childEnterpriseId") ? localStorage.getItem("childEnterpriseId") : localStorage.getItem("enterpriseId"))
          ) {
            chat.unreadCount = 0;
            localStorage.removeItem("clearUnreadCountId");
          }
          return chat;
        });
        setInitialChats(result2);
      } else if (isMinimized && localStorage.getItem("clearUnreadCountId")) {
        localStorage.removeItem("clearUnreadCountId");
      }
    }, 1000);

    return () => {
      clearInterval(clearChatInterval);
    };
  }, [isMinimized, searchKeyword, chats, initialChats]);

  const search = (keyword) => {
    if (keyword) {
      let result = initialChats?.filter((chat)=>chat.messageFromName?.toLowerCase().startsWith(keyword.toLowerCase()));
      setChats(result);
    } else {
      getOnlyChats();
    }
  };

  const handleEnterKey = (e) => {
    setSearchKeyword(e.target.value);
    if (
      e.target.value !== ""
    ) {
      search(e.target.value);
    }
    if (
      e.type === "input" &&
      e.target.value === ""
    ) {
      search("");
    }
  };
  const clearSearch = async (e) => {
    setSearchKeyword("");
    search("");
  };
  var onedayBefore = new Date();
  onedayBefore.setDate(onedayBefore.getDate() - 1);
  var currentDateWeekFormat = new Date();
  currentDateWeekFormat.setDate(currentDateWeekFormat.getDate() - 7);

    // useEffect(()=>{
    //     if (isLastChatWindow && openSmsChat) {
    //         setIsMinimized(false);
    //         localStorage.removeItem("OPEN_SMS_CHAT");
    //     }
    // }, [openSmsChat]);

    const toggleMinimize = () => {
        setIsMinimized(!isMinimized);
    };

    const handleCloseChatWindow = (e) => {
        e.stopPropagation();
        let chats = localStorage.getItem("SMS_CHATS");
        if (chats) {
            chats = JSON.parse(chats);
            chats?.splice(index, 1);
            localStorage.setItem("SMS_CHATS", JSON.stringify(chats));
        }
    };

    const handleOpenSMSChat = (chat) => {
        getDSaaSCandidateIdFromToken().then((candidateId) => {
            let id = "";
            if (chat.messageFrom === candidateId) {
              id = chat.messageTo;
            } else {
              id = chat.messageFrom;
            }
        let _chat = {
          id: id,
          firstName: chat?.messageFromName,
          lastName: "",
          mediaFile: chat?.messageFromProfile,
          mediaFileSigned: chat?.messageFromProfileSigned,
          mobileNumber: chat?.mobileNumber,
          profileTitle: chat?.messageFromProfileTitle,
          isCheckSendSMS: false,
        };
        let chats = localStorage.getItem("SMS_CHATS");
        if (chats) {
          chats = JSON.parse(chats);
          chats = chats?.filter((chat)=>chat.id !== id);
          if (chats?.length === 3) {
            chats = chats?.slice(0, 2);
          }
          chats = [_chat, ...chats];
          localStorage.setItem("SMS_CHATS", JSON.stringify(chats));
          localStorage.setItem("OPEN_SMS_CHAT", true);
        } else {
          localStorage.setItem("SMS_CHATS", JSON.stringify([_chat]));
          localStorage.setItem("OPEN_SMS_CHAT", true);
        }
    });
    };

    return (
        <div className={`chat-window ${isMinimized ? 'minimized' : ''}`}>
            <div className="chat-header" onClick={toggleMinimize}>
                <Typography className='d-flex align-center'>
                  <Typography className="enterprise-profile cursor-hover m-r-10 d-flex align-center">
                    {enterpriseDetails?.logoFileSignedUrl && (
                      <img
                        width="40px"
                        height="40px"
                        style={{
                          objectFit: enterpriseDetails?.logoFileSignedUrl.includes("gif")
                            ? "cover"
                            : "",
                        }}
                        className="default-img-border"
                        src={
                          enterpriseDetails?.logoFileSignedUrl
                        }
                        alt="Profile picture"
                      />
                    )}
                    {!localStorage.getItem("childEnterpriseId") &&
                      !enterpriseDetails?.logoFileSignedUrl && (
                        <img
                          width="40px"
                          height="40px"
                          className="default-img-border"
                          src={constants.DEFAULT_LOGO}
                          alt="Default image"
                        />
                      )}
                    {!enterpriseDetails?.logoFileSignedUrl &&
                      localStorage.getItem("childEnterpriseId") && (
                        <Typography
                          className="default-img-border justify-center align-center d-flex primary-shadow-color bg-input f-14 font-weight-600 text-capitalize"
                          width="40px"
                          height="40px"
                          aria-label="Default image"
                        >
                          {enterpriseDetails?.establishmentName?.trim().substring(0, 1)}
                        </Typography>
                      )}
                  </Typography>
                  <Typography className="gray9 f-16 font-weight-600">
                      Messaging
                  </Typography>
                </Typography>
                <Typography>
                  <NewChatIcon
                      width="18px" height="18px"
                      className="mr-15"
                      onClick={(e)=>{
                          e.stopPropagation();
                          setOpenNewMessageChat(true);
                          localStorage.setItem("isNewMessageOpen", true);
                          setTimeout(()=>{
                            handleMinimizeNewChat();
                          }, 100);
                      }}
                  />
                  {!isMinimized ?
                  <span
                    id="messaging-window-minimize-icon"
                    onClick={toggleMinimize}
                    onKeyPress={toggleMinimize}
                  >
                  <ChatUpIcon
                      tabIndex={0}
                      width="16px" height="16px"
                      id="messaging-window-minimize-icon"
                  />
                  </span>
                  :
                  <ChatDownIcon
                      onClick={toggleMinimize}
                      onKeyPress={toggleMinimize}
                      tabIndex={0}
                      width="16px" height="16px"
                  />}
                </Typography>
            </div>
            {!isMinimized && (
                <div className="chat-body">
                    {chats?.length >= 0 && (
              <Grid
                container
                item
                lg={12}
                md={12}
                xs={12}
                className="pl-0  pt-0 responsive-chat-section message-height message-listAlign d-block"
              >
                <TextField
                        inputRef={inputRef}
                        name="searchKeyword"
                        type="search"
                        className="inputcontrasts message-search-input position-issue postJob-search search-id new-search mt-1 mb-20 job-search-align"
                        variant="outlined"
                        margin="normal"
                        placeholder="Search by candidate name"
                        sx={{ minWidth: "280px" }}
                        onKeyPress={(e) => {
                          handleSpaces(e);
                        }}
                        onInput={handleEnterKey}
                        // onKeyDown={handleEnterKey}
                        value={searchKeyword}
                        disabled={initialChats?.length === 0}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start" className="mr-0 w-30">
                              <Typography className="search-byId">
                                <Grid
                                  className="border-radius-6 d-flex align-center"
                                  style={{ padding: "12px" }}
                                  aria-label="Search icon"
                                  disableRipple="true"
                                >
                                  <SearchIcon
                                    width="20px"
                                    aria-label="search icon"
                                  />
                                </Grid>
                              </Typography>
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="end">
                              {searchKeyword !== "" && (
                                <ClearIcon
                                  onClick={() => {
                                    inputRef.current.value = "";
                                    clearSearch();
                                  }}
                                  style={{ marginRight: "10px" }}
                                  aria-label="Clear icon"
                                  className="cursor-hover"
                                />
                              )}
                            </InputAdornment>
                          ),
                        }}
                      />
                <Typography style={{ height: "280px", overflowY: "auto" }}>
                {chats.map((chat, index) => {
                  let messageFrom = chat.messageFrom;
                  // if(activeChatIndex === index && chatsLastMeassage !== ''){
                  //     chat.lastMessage = chatsLastMeassage;
                  //     messageFrom = activeMessageJob.jobCreatedBy;
                  // }
                  return (
                    <Typography
                      style={{ cursor: "pointer" }}
                      key={index}
                      onClick={() => {
                        handleOpenSMSChat(chat);
                        setActiveChatIndex(index);
                        setCurrentChat(chat);
                      }}
                      className={`d-flex w-100 justify-between pl-10 py-15 inputcontrasts bg-white`}
                      tabIndex={0}
                      onKeyPress={() => {
                        handleOpenSMSChat(chat);
                        setActiveChatIndex(index);
                        setCurrentChat(chat);
                      }}
                    >
                      <Grid item lg={7.2} className="d-flex">
                      <TooltipShared title={(currentChat?.messageFromName === "**** ****")
                                                                        ? constants.GDPR_TOOLTIP
                                                                        : ""} className="d-flex">
                        <Typography className="m-r-10">
                          {(chat.messageFromProfile === "****" ||
                            chat.messageFromProfileSigned === null ||
                            chat.messageFromProfileSigned === "null" ||
                            chat.messageFromProfileSigned === "") && (
                            <Typography
                              className="default-img-border justify-center align-center d-flex primary-shadow-color bg-input f-16 font-weight-700 text-capitalize"
                              width="45px"
                              height="45px"
                              aria-label="Default image"
                            >
                              {chat.messageFromName.trim().substring(0, 1)}
                            </Typography>
                            // <img
                            //   width="45px"
                            //   height="45px"
                            //   className="default-img-border"
                            //   src="https://daas-prog-test.s3.amazonaws.com/default-user.png"
                            //   alt="img"
                            // />
                          )}
                          {chat.messageFromProfile !== "****" &&
                            chat.messageFromProfileSigned !== null &&
                            chat.messageFromProfileSigned !== "null" &&
                            chat.messageFromProfileSigned !== "" &&
                            !checkIsImage(chat.messageFromProfileSigned) && (
                              <video
                                width="45px"
                                height="45px"
                                className="default-img-border profile-video-thumbnail cursor-hover"
                                src={chat.messageFromProfileSigned}
                                onClick={(e) => {
                                  // handleOpenVideoPreview(e,chat.messageFromProfileSigned)
                                }}
                                muted
                                tabIndex={0}
                                onKeyPress={(e) => {
                                  // handleOpenVideoPreview(e,chat.messageFromProfileSigned)
                                }}
                              />
                            )}
                          {chat.messageFromProfile !== "****" &&
                            chat.messageFromProfileSigned !== null &&
                            chat.messageFromProfileSigned !== "null" &&
                            chat.messageFromProfileSigned !== "" &&
                            checkIsImage(chat.messageFromProfileSigned) && (
                              <img
                                width="45px"
                                height="45px"
                                src={chat.messageFromProfileSigned}
                                className="default-img-border"
                                alt="img"
                              />
                            )}
                          {/* {chat?.messageFromProfileSigned === null &&
                                                  <img style={{width: '30px'}} className="default-img-border"
                                                      src='https://daas-prog-test.s3.amazonaws.com/default-user.png'/>
                                                  }
                                                  {chat?.messageFromProfileSigned !== null &&
                                                  <img className="default-img-border" style={{width: '30px'}}
                                                      src={chat.messageFromProfileSigned}/>
                                                  } */}
                        </Typography>
                        <Typography>
                          <Box className={`gray9 f-16 font-weight-600 word-break ${chat?.unreadCount ? "unread-message-primary-color" : ""}`}>
                            {chat.messageFromName}
                          </Box>
                          {chat.lastMessageSignedUrl &&
                            (chat.lastMessageSignedUrl.includes(".png") ||
                              chat.lastMessageSignedUrl.includes(".jpg") ||
                              chat.lastMessageSignedUrl.includes(".jpeg")) && (
                              <Box className={`gray9 f-12 font-weight-400 mt-5 ${chat?.unreadCount ? "unread-message-primary-color" : ""}`}>
                                {messageFrom === localStorage.getItem("candidateId")
                                  ? "You: "
                                  : ""}{" "}
                                Sent a file attachment
                              </Box>
                            )}
                          {chat.lastMessageSignedUrl &&
                            chat.lastMessageSignedUrl.includes(".doc") && (
                              <Box className={`gray7 f-14 font-weight-400 mt-5 ${chat?.unreadCount ? "unread-message-primary-color" : ""}`}>
                                {messageFrom === localStorage.getItem("candidateId")
                                  ? "You: "
                                  : ""}{" "}
                                Sent a file attachment
                              </Box>
                            )}
                          {chat.lastMessageSignedUrl &&
                            chat.lastMessageSignedUrl.includes(".pdf") && (
                              <Box className={`gray7 f-14 font-weight-400 mt-5 ${chat?.unreadCount ? "unread-message-primary-color" : ""}`}>
                                {messageFrom === localStorage.getItem("candidateId")
                                  ? "You: "
                                  : ""}{" "}
                                Sent a file attachment
                              </Box>
                            )}
                          {chat.lastMessage &&
                            !chat.lastMessage.includes(".png") &&
                            !chat.lastMessage.includes(".jpg") &&
                            !chat.lastMessage.includes(".jpeg") &&
                            !chat.lastMessage.includes(".doc") &&
                            !chat.lastMessage.includes(".pdf") && (
                              <Box className={`gray7 f-14 font-weight-400 mt-5 word-break ${chat?.unreadCount ? "unread-message-primary-color" : ""}`}>
                                {messageFrom === localStorage.getItem("candidateId")
                                  ? "You: "
                                  : ""}{" "}
                                {chat.lastMessage.length > 40
                                  ? chat.lastMessage.substring(0, 40) + "..."
                                  : chat.lastMessage}
                              </Box>
                            )}
                        </Typography>
                        </TooltipShared>
                      </Grid>
                      <Grid item lg={4.8} style={{ width: "45%" }}>
                        <Typography className="gray9 f-12 font-weight-400 pt-3 mr-10 display-flex justify-end">
                          {/* {isTodayOrYesterdayForChats(
                              activeChatIndex === index &&
                                chatsLastMeassage !== ""
                                ? chat?.lastMessageTimestamp
                                : chat?.lastMessageTimestamp
                            )} */}
                          {formatDate(new Date()) ===
                          formatDate(chat?.lastMessageTimestamp) ? (
                            <span className="gray7 f-12">
                              {isMinutesOrHours(chat?.lastMessageTimestamp)}
                            </span>
                          ) : (
                            <span>
                              {formatDate(onedayBefore) === formatDate(chat?.lastMessageTimestamp) ? (
                                <span className="gray7 f-12">
                                  {chat?.lastMessageTimestamp === null ||
                                  chat?.lastMessageTimestamp === ""
                                    ? "-"
                                    : "Yesterday"}
                                </span>
                              ):
                              <>
                                {formatDate(currentDateWeekFormat) <=
                              formatDate(chat?.lastMessageTimestamp) ? (
                                <span className="gray7 f-12">
                                  {chat?.lastMessageTimestamp === null ||
                                  chat?.lastMessageTimestamp === ""
                                    ? "-"
                                    : moment(chat?.lastMessageTimestamp).format(
                                        "MMM DD, YYYY"
                                      )}
                                </span>
                              ) : (
                                <span className="gray7 f-12">
                                  {chat?.lastMessageTimestamp === null ||
                                  chat?.lastMessageTimestamp === ""
                                    ? "-"
                                    : moment(chat?.lastMessageTimestamp).format(
                                        "MMM DD, YYYY"
                                      )}
                                </span>
                              )}</>}
                            </span>
                          )}
                        </Typography>
                        {chat?.unreadCount ?
                        <Typography className="message-list-unread-count mt-10 mr-10" style={{ float: 'right' }}>
                          {chat?.unreadCount}
                        </Typography>
                        : ""}
                      </Grid>
                    </Typography>
                  );
                })}
                {chats?.length === 0 &&
                (searchKeyword ?
                <>
                  <img className="launch-videoImage" src={noSearchResultsIcon} alt="img"  width="270px"
                  height="150px"/>
                  <Box className="f-20 mt-10 text-center font-weight-600">
                    No results found
                  </Box>
                  <Box className="f-14 mt-10 text-center font-weight-400 gray9">
                    Unfortunately we could not find any results
                  </Box>
                </>
                :
                !initialChats?.length ?
                <>
                  <img className="launch-videoImage" src={noMessagesIcon} alt="img"  width="270px"
                      height="150px"/>
                  <Box className="f-20 mt-10 text-center font-weight-600">
                    No messages found
                  </Box>
                  <Box className="f-14 mt-10 text-center font-weight-400 gray9">
                    You don’t have any messages for now
                  </Box>
                </>
                : ""
                )
                }
                </Typography>
              </Grid>
            )}
                </div>
            )}
        </div>
    );
};

export default MessageChatBox;
